<template>
  <div class="CandidatesList py-3 px-2" v-loading="loading">
    <el-tabs
      v-model="activeTab"
      @tab-click="onTabClick"
      @tab-remove="removeTab"
    >
      <el-tab-pane name="list" class="tab-item">
        <template #label>
          <div class="w-max">
            <div class="flex items-center border-box">
              <i class="vc-icon-logs mx-1 icon-lg"></i>
              <div class="" style="padding-right: 8px">
                Main
              </div>
            </div>
          </div>
        </template>
        <el-table
          ref="baseTable"
          :data="tableData"
          empty-text="No Data!"
          style="width: 100%; margin-bottom: 20px"
          class="candidates-info-table my-3"
        >
          <el-table-column type="index" width="40"/>
          <template v-for="(column, i) in columns" :key="`col-${i}`">
            <el-table-column
              :min-width="column.minWidth || '150px'"
              :width="column.width"
              :column-key="column.prop"
              :type="column.type"
              :sortable="column.sortable"
              :prop="column.prop"
              :sort-method="column.sortMethod"
            >
              <template #header>
                <div v-if="column.searchable" class="search-wrap" @click.stop>
                  <el-input v-model="searchModel[column.prop]" autocomplete="off" :name="`filter-by-${column.prop}`"
                            clearable @input="debounceSearchData(column.prop, $event)"/>
                </div>
                <div v-else-if="column.filter" class="search-wrap" @click.stop>
                  <el-select
                    v-model="searchModel[column.filterKey || column.prop]"
                    :multiple="column.multiple"
                    :collapse-tags="column.multiple"
                    :filterable="!column.multiple"
                    placeholder="All"
                    clearable
                    @change="debounceSearchData(column.filterKey || column.prop, $event)"
                  >
                    <el-option label="All" value=""></el-option>
                    <el-option
                      v-for="option in column.filter"
                      :key="`option-${option.value}`"
                      :label="option.label"
                      :value="option.value"
                    />
                  </el-select>
                </div>
                <div v-else-if="column.datetime" class="search-wrap" @click.stop>
                  <el-date-picker
                    class="date-picker"
                    v-model="searchModel[column.prop]"
                    @change="debounceSearchData(column.prop, $event)"
                    value-format="DD MMM YYYY"
                  />
                </div>
                <div class="inline-flex items-center uppercase pr-1 base-cell min-w-max relative"
                     style="min-height: 20px">
                  <i v-if="column.icon" :class="column.icon" class="icon-md mr-1"/>
                  {{ column.label }}
                </div>
              </template>
              <template v-if="column.prop === 'first_name'" #default="{ row }">
                <div class="user-info-cell flex items-center pointer" @click="openInTab(row)">
                  <el-avatar
                    :src="row.staff_pic_url"
                    shape="square"
                    :size="64"
                    class="mr-2"
                  />
                  <div class="underline text-blue">
                    {{ `${row.full_name} (${row.id})` }}
                  </div>
                </div>
              </template>
              <template v-else-if="column.prop === 'status'" #default="{ row }">
                <div class="flex w-full justify-between status-col">
                  <span class="mx-auto">
                  {{ row.status_name }}
                </span>
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </el-tab-pane>
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.tabKey"
        :name="tab.tabKey"
        class="tab-editable"
        closable
      >
        <template #label>
          <div class="w-max inline-flex">
            <div class="flex items-center border-box">
              <i class="vc-icon-users mx-1 icon-base"></i>
              <div class="" style="padding-right: 8px">
                {{ tab.tabName }}
              </div>
            </div>
          </div>
        </template>
        <CandidatePreviewTab
          :params="candidateForms"
          :tab-data="tab"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import get from "lodash/get";
import listTabsMixin from "@/mixins/listTabsMixin";
import CandidatePreviewTab from "@/views/admin/CandidatePreviewTab";
import CandidatesListMixin from "@/mixins/CandidatesListMixin";

const DEFAULT_STATUS_COLOR = '#309A47'
const DEFAULT_STATUSES = [1,2,5]

export default {
  name: "CandidatesCampList",
  components: {CandidatePreviewTab},
  mixins: [ listTabsMixin, CandidatesListMixin ],
  data () {
    return {
      formsStatus: 5,
      searchModel: {
        phone: '',
        email: '',
        camp_name: '',
        camp_id: '',
        position_name: '',
        status: [...DEFAULT_STATUSES],
        first_name: '',
        positions: ''
      }
    }
  },
  computed: {
    columns () {
      return [
        {
          prop: 'first_name',
          label: 'Name',
          icon: 'vc-icon-users',
          minWidth: '260px',
          sortable: true,
          searchable: true
        },
        {
          prop: 'cell',
          label: 'Phone',
          icon: 'vc-icon-phone',
          sortable: false,
          searchable: true
        },
        {
          prop: 'email',
          label: 'E-Mail',
          icon: 'vc-icon-mail',
          minWidth: '190px',
          sortable: false,
          searchable: true
        },
        {
          prop: 'camp_name',
          label: 'Camp Name',
          icon: 'vc-icon-minimap',
          filterKey: 'camp_id',
          sortable: true,
          filter: this.campFilterList
        },
        {
          prop: 'positions',
          label: 'Position',
          icon: 'vc-icon-customer-experience',
          sortable: true,
          searchable: false,
          filter: this.positions
        },
        {
          prop: 'status',
          label: 'Status',
          icon: 'vc-icon-login-status',
          width: '112px',
          sortable: false,
          searchable: false,
          filter: this.statuses,
          multiple: true
        }
      ]
    }
  },
  methods: {
    onRefreshList () {
      this.resetFilter()
      const filterDefault = this.getFilterData('status', DEFAULT_STATUSES)
      this.getList(filterDefault)
    },
    mapResponse (item) {
      const statusData = [...this.statuses].find(st => st.id === +item.status)
      const position = `${get(item, 'pos_camp_data.camp_name')}_${get(item, 'pos_camp_data.pos')}`

      return {
        ...item,
        full_name: `${item.ename || item.fname} ${item.ename2 || item.lname}`,
        status_name: statusData ? statusData.status_name : '',
        status_color: statusData ? `#${statusData.color}` : DEFAULT_STATUS_COLOR,
        camp_name: item?.pos_camp_data?.camp_name,
        camp_id: item?.pos_camp_data?.camp_id,
        position_name: item?.pos_camp_data?.pos,
        positions: position
      }
    },
    resetFilter() {
      this.searchModel = {
        phone: '',
        email: '',
        id: '',
        interview1_id: '',
        interview2_id: '',
        camp_id: '',
        position: '',
        status: [...DEFAULT_STATUSES],
        first_name: ''
      }
    },
  },
  async created() {
    this.loading = true
    await this.getConfData()
    await this.getForms()
    await this.getList()
    this.loading = false
  }
}
</script>

<style lang="scss">
.candidates-info-table {
  font-size: 16px;

  .cell {
    display: inline-flex !important;
    align-items: center;
  }

  th.el-table-column--selection .cell {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
  }

  th .cell {
    color: #20ACE2;
    font-weight: 700;
    font-size: 14px;
    position: relative;
    padding-bottom: 36px;

    .search-wrap {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0 4px;

      .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
      }
    }
  }

  td .cell {
    width: 100%;
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import BY_DOMAIN_ROUTES from '@/enum/domain'

import { store } from '@/store'

import authEnum from '@/util/authEnum'
/* FirstApplication: */
import FirstApplication from '@/views/admin/FirstApplication'
/* Candidates: */
import CandidateProfile from '@/views/candidate/CandidateProfile'
import CandidateList from '@/views/admin/CandidatesList'
import CandidatesCampList from '@/views/admin/CandidatesCampList'
/* Children */
import ChildrenList from '@/views/admin/children/ChildrenList'
import ChildrenSummary from '@/views/admin/children/ChildrenSummary'
/* Summarize (candidates): */
import Summarize from '@/views/admin/Summarize'
/* Other: */
import LoginPage from '@/views/LoginPage';
import Main from '@/views/Main';

// Reports
import Reports from '@/views/admin/Reports'

// PDF:
import StaffFullInfo from '@/views/admin/Pdf/StaffFullInfo'
import StaffPdfStickers from '@/views/admin/Pdf/StaffPdfStickers'
import StaffPdfInterviewers from '@/views/admin/Pdf/StaffPdfInterviewers'
import PdfPreviewLayout from '@/layout/PdfPreviewLayout'

import AdminLayout from '@/layout/AdminLayout';
import CandidateLayout from "@/layout/CandidateLayout";

const scrollBehavior = () => {
  return { top: 0, behavior: 'smooth', left: 0 }
}

const adminRoutes = [
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminLayout,
    redirect: '/admin/children',
    children: [
      {
        path: 'app',
        name: 'FirstApplication',
        component: FirstApplication,
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'candidates',
        name: 'CandidatesList',
        component: CandidateList,
        meta: {
          isAdmin: true
        },
        children: [
          {
            path: ':candidate',
            name: 'CandidateInfoTab',
            component: CandidateList
          }
        ]
      },
      {
        path: 'summarize',
        name: 'Summarize',
        component: Summarize,
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Reports,
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'children',
        name: 'ChildrenList',
        component: ChildrenList,
        meta: {
          isAdmin: true
        },
        children: [
          {
            path: ':candidate',
            name: 'ChildrenInfoTab',
            component: ChildrenList
          }
        ]
      },
      {
        path: 'child-summary',
        name: 'ChildrenSummary',
        component: ChildrenSummary,
        meta: {
          isAdmin: true
        }
      }
    ],
    meta: {
      isAdmin: true
    }
  },
  {
    path: '/manager',
    name: 'CampManager',
    component: AdminLayout,
    redirect: '/manager/app',
    children: [
      {
        path: 'app',
        name: 'CandidatesCampList',
        component: CandidatesCampList,
        meta: {
          isManager: true
        }
      }
    ],
    meta: {
      isManager: true
    }
  },
  {
    path: '/admin/staff/pdf',
    component: PdfPreviewLayout,
    children: [
      {
        path: 'full-info',
        name: 'PdfPreviewStaffFull',
        component: StaffFullInfo,
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'folder-stickers',
        name: 'PdfPreviewStaffStickers',
        component: StaffPdfStickers,
        meta: {
          isAdmin: true
        }
      },
      {
        path: 'interviewers',
        name: 'PdfPreviewStaffInterviewers',
        component: StaffPdfInterviewers,
        meta: {
          isAdmin: true
        }
      }
    ],
    meta: {
      isAdmin: true
    }
  }
]

const candidateRoutes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/candidate',
    name: 'Candidate',
    component: CandidateLayout,
    redirect: '/candidate/profile',
    beforeEnter(to, from, next) {
      if (!authEnum.getDecodedToken()) {
        next('/login')
      } else {
        next()
      }
    },
    children: [
      {
        path: 'profile',
        name: 'CandidateProfile',
        component: CandidateProfile
      }
    ]
  }
]

const routes = [
  {
    path: '/',
    name: 'Main',
    beforeEnter: redirectToRelevantByDomain
  },
  ...adminRoutes,
  ...candidateRoutes,
  {
    path: '/:catchAll(.*)',
    beforeEnter: redirectToRelevantByDomain
  }
]

async function redirectToRelevantByDomain(to, from, next) {
  const domain = window.location.hostname
  console.log('domain', domain)
  if (domain === BY_DOMAIN_ROUTES.ADMIN) {
    console.log('domain === BY_DOMAIN_ROUTES.ADMIN')
    await fetchUserType()

    if (store.getters.isUserAdmin) {
      next('/admin')
    } else if (store.getters.isUserManager) {
      next('/manager')
    } else {
      console.log('/main')
      next('/main')
    }
  } else if (domain === BY_DOMAIN_ROUTES.STAFF_MY) {
    next('/candidate')
  } else {
    next()
  }
}

async function fetchUserType() {
  if (!store.getters.getUserType) {
    try {
      await store.dispatch('fetchStaffConfigs')
    } catch (e) {
      console.log(e)
    }
  }
}

//console.log(process.env.VUE_APP_BASE_URL)
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
  linkActiveClass: 'active'
})

router.beforeEach(async (to, from, next) => {
  const hasAdmin = to.meta.isAdmin
  const hasManager = to.meta.isManager

  if (hasAdmin || hasManager) {
    await fetchUserType()
    if (
      (hasAdmin && to.path.includes('admin') && store.getters.isUserAdmin)
      ||
      (hasManager && to.path.includes('manager') && store.getters.isUserManager)
    ) {
      next()
    } else {
      next('/main')
    }
  } else {
    next()
  }
})

export default router

<template>
  <template v-for="(column, i) in columns" :key="`col-${i}`">
    <el-table-column
      :min-width="column.minWidth || '150px'"
      :width="column.width"
      :column-key="column.prop"
      :type="column.type"
      :sortable="column.sortable"
      :prop="column.prop"
      :sort-method="column.sortMethod"
      :sort-by="column.sortBy"
      :fixed="column.fixed"
    >
      <template #header>
        <slot name="header" :col="column" />
      </template>
      <template v-if="$slots[column.prop]" v-slot="{row, $index}">
        <div class="base-column w-full">
          <slot :name="[column.prop]" :row="row" :index="$index" :col="column" :dataKey="column.prop"></slot>
        </div>
      </template>
    </el-table-column>
  </template>
</template>

<script>

export default {
  name: "TableContent",
  props: {
    columns: {
      type: Array,
      default: () => []
    }
  }
}
</script>

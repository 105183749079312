<template>
  <section class="w-full h-full report-gr-pdf-table__wrap">
    <div class="flex justify-between items-center" style="margin-bottom: 8px; padding-bottom: 2px; background-color: white; border-bottom: 1px solid #E0E2E6">
      <NavLogoIcon style="height: 48px; width: 80px" />
      <div>
        <span class="semibold">Type:</span> <span class="text-small">{{ category.report_category_name }}</span>
        <span class="semibold">Report:</span> <span class="text-small">{{ reportInfo.report_remarks }}</span>
      </div>
    </div>
    <div class="table-wrap">
      <table id="report-group-pdf-table" class="pdf-item">
        <thead>
        <tr>
          <th
            v-for="(column, i) in columns"
            :key="`col-${i}`"
          >
            {{ column.label }}
          </th>
        </tr>
        </thead>
        <tbody>
        <template
          v-for="(group, gIndex) in groupedByCampData"
          :key="`group-${gIndex}`"
        >
          <tr class="group-row">
            <td :colspan="columns.length">
              Camp {{ group.campName }}
            </td>
          </tr>
          <template
            v-for="(division, dIndex) in group.options"
            :key="`item-${gIndex}-${dIndex}`"
          >
            <tr class="group-row division-row">
              <td :colspan="columns.length">
                {{ division.DivisionName }}
              </td>
            </tr>
            <tr
              v-for="(rItem, rIndex) in division.Data"
              :key="`row-${gIndex}-${dIndex}-${rIndex}`"
            >
              <td
                v-for="(column, index) in columns"
                :key="`td-${gIndex}-${dIndex}-${rIndex}-${index}`"
              >
                {{ rItem[column.prop] || '---' }}
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import NavLogoIcon from "@/components/NavLogoIcon.vue";
import groupBy from "lodash/groupBy";

export default {
  components: {NavLogoIcon},
  props: {
    tableColumns: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      default: () => ({})
    },
    reportInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
      return {
        filteredColumns: ['ID', 'Division']
      }
  },
  computed: {
    columns () {
      return this.tableColumns.filter(item => !this.filteredColumns.includes(item.prop))
    },
    groupedByCampData () {
      if (!this.options.length) {
        return []
      }
      const _groupedData = groupBy(this.options, (item) => item.CampName)
      return Object.entries(_groupedData).map(item => {
        return {
          campName: item[0],
          options: (item[1] || []).sort((a,b) => (a.DivisionOrder ?? 0) - (b.DivisionOrder ?? 0))
        }
      })
    }
  }
}
</script>

<style lang="scss">
.report-gr-pdf-table__wrap {
  -webkit-print-color-adjust: exact !important;
  background-color: white;
  padding: 6px 8px 32px;
  font-size: 14px;
  .text-small {
    font-size: 12px;
    margin-right: 12px;
  }
  .table-wrap {
    padding: 12px 4px 20px;
  }
  thead {
    display: table-row-group;
  }
  table, tr, td, th, tbody, thead  {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
  }
  #report-group-pdf-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    td, th {
      border: 1px solid #ddd;
      text-align: center;
    }
    tr:not(.group-row):nth-child(even){background-color: #f2f2f2;}

    th {
      padding-top: 4px;
      padding-bottom: 2px;
      background-color: #20ACE2;
      color: white;
      font-size: 12px;
      font-weight: 500;
    }
    td {
      font-size: 10px;
      padding: 2px;
    }
    .group-row {
      td {
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        background-color: #d3f2f6;
      }
    }
    .division-row {
     td {
       font-size: 14px;
       font-weight: 500;
       text-align: left;
       background-color: #e4eeef;
     }
    }
  }
}
@media print {
  @page  {
    size: auto; /* auto is the initial value */
    margin: 2mm 4mm 0 0;
  }
  thead
  {
    display: table-header-group;
  }
}
</style>

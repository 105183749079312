<template>
  <div :class="{'is-maximize': maximize}" class="admin-sidebar">
    <nav class="nav__container">
      <div class="nav__menu">
        <template v-for="(link, i) in filteredMenuLinks" :key="`link-${i}`">
          <router-link
            :to="link.link"
            class="nav__item">
           <span class="icon-wrap flex-center">
            <i
              :class="link.icon || 'vc-icon-real-time'"
              class="icon"
            />
          </span>
            <span class="content">
              {{ link.name }}
            </span>
          </router-link>
        </template>
      </div>
    </nav>
    <div class="nav__actions flex items-center px-3 py-3">
      <el-switch v-model="maximize" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Sidebar',
  data () {
    return {
      maximize: false,
      menu: [
        { name: 'Staff', link: '/admin/candidates', icon: 'vc-icon-contact-list', isAdmin: true },
        { name: 'Summarize', link: '/admin/summarize', icon: 'vc-icon-box', isAdmin: true },
        { name: 'First Application', link: '/admin/app', icon: 'vc-icon-real-time', isAdmin: true },
        { name: 'Children', link: '/admin/children', icon: 'vc-icon-queues', isAdmin: true },
        { name: 'Child Summary', link: '/admin/child-summary', icon: 'vc-icon-notes', isAdmin: true },
        { name: 'Reports', link: '/admin/reports', icon: 'vc-icon-other', isAdmin: true },
        { name: 'Test Director', link: '/admin/director', icon: 'vc-icon-name', isManager: true }
      ]
    }
  },
  watch: {
    maximize(val) {
      const main = document.querySelector('.admin-template .main-content')
      if (val) {
        main.style['padding-left'] = 'calc(var(--el-sidebar-hover-width) + 20px)'
      } else {
        main.style['padding-left'] = 'calc(var(--el-sidebar-width) + 20px)'
      }
    }
  },
  computed: {
    ...mapGetters(['isUserAdmin', 'isUserManager']),
    filteredMenuLinks () {
      if (this.isUserAdmin) {
        return this.getAdminLinks()
      } else if (this.isUserManager) {
        return this.getDirectorLinks()
      } else {
        return []
      }
    }
  },
  methods: {
    getAdminLinks() {
      return this.menu.filter(i => i.isAdmin)
    },
    getDirectorLinks() {
      return this.menu.filter(i => i.isManager)
    }
  }
}
</script>

<style lang="scss">
  .admin-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: var(--el-sidebar-width);
    z-index: var(--z-sidebar);
    padding-top: var(--el-header-height);
    background: white;
    transition: all .3s linear;
    display: flex;
    flex-direction: column;

    .nav__container {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100%;
      flex-grow: 1;
    }
    .nav__menu {
      .nav__item {
        display: flex;
        align-items: center;
        color: var(--gray-500);
        position: relative;
        cursor: pointer;
        .icon-wrap {
          width: var(--el-sidebar-width);
          height: 68px;
          flex-shrink: 0;
          transition: all .3s linear;
        }
        .icon {
          font-size: 30px;
          transition: all .3s linear;
        }
        .content {
          flex-shrink: 0;
          flex-grow: 1;
          opacity: 0;
          transition: all .28s linear;
          font-weight: 500;
          font-size: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
        &:hover {
          background: rgba(32, 172, 226, 0.1);
          color: var(--blue);
        }
        &.active {
          color: var(--blue);
          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 5px;
            height: calc(100% - 16px);
            border-radius: 0 4px 4px 0;
            background: currentColor;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    &.is-maximize {
      width: var(--el-sidebar-hover-width);
      box-shadow: 1px 0 2px var(--lines);
      .nav__menu {
        .nav__item {
          .content {
            opacity: 1;
          }
          .icon-wrap {
            width: calc(var(--el-sidebar-width) - 12px);
          }
          .icon {
            font-size: 24px;
          }
        }
      }
    }

    @media only screen and (max-width: 780px) {
      width: 300px;
      opacity: 0;
      transition: all .38s linear;
      transform: translateX(-100%);
      top: 0;
      left: 0;
      box-shadow: 1px 0 20px var(--lines);
      .nav__menu {
        .nav__item {
          .content {
            opacity: 1;
          }
          .icon-wrap {
            width: 54px;
          }
          .icon {
            font-size: 28px;
          }
        }
      }
      .nav__actions {
        display: none;
      }

      &.is_open {
        transform: translateX(0);
        opacity: 1;
      }
    }
    @media print {
      display: none;
    }
  }
</style>

<template>
  <div class="flip-box">
    <div :class="{'flipped': (hasInterview && !isEdit)}" class="flip-card">
      <div v-loading="loading" class="flip-card-front">
        <InterviewCard>
          <template #content>
            <div v-if="isEdit || !hasInterview" class="form-item">
              <div class="label bold py-2">
                {{ label }}
              </div>
              <el-select v-model="model" filterable>
                <el-option
                  v-for="i in options"
                  :key="i.interview_id"
                  :value="i.interview_id"
                  :label="`${i.interview_time}_${i.interviewer_name}_${i.counter}`"
                />
              </el-select>
            </div>
          </template>
          <template #actions>
            <el-button :disabled="!model" type="primary" @click="onSave">
              Save
            </el-button>
          </template>
        </InterviewCard>
      </div>
      <div v-loading="loading" class="flip-card-back">
        <InterviewCard v-if="hasInterview && !isEdit">
          <template #content>
            <div class="text-center" v-html="interviewDateTime" />
          </template>
          <template #actions>
            <el-button type="primary" @click="onSendEmail">
              Email {{ emailCount }}
            </el-button>
            <el-button type="primary" @click="onSendSms">
              SMS {{ smsCount }}
            </el-button>
            <el-button type="primary" @click="isEdit = true">
              Edit
            </el-button>
          </template>
        </InterviewCard>
      </div>
    </div>
  </div>
</template>

<script>
import InterviewCard from "@/components/pages-components/common/Cards/InterviewCard";

import { format } from "date-fns"

export default {
  name: "InterviewFlipCard",
  components: {InterviewCard},
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    options: {
      type: Array,
      default: () => []
    },
    type: {
      type: [String, Number],
      default: ''
    },
    interviews: {
      type: Array,
      default: () => []
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    interviewData: {
      type: Object,
      default: undefined
    }
  },
  emits: ['save', 'send-email', 'send-sms'],
  data () {
    return {
      model: '',
      loading: false,
      isEdit: false
    }
  },
  computed: {
    hasInterview () {
      return !!this.interviewData
    },
    interviewDateTime () {
      if (!this.hasInterview) {
        return ''
      }

      const date = new Date(this.interviewData.interview_time)
      const day = format(date, 'dd MMM yyyy')
      const time = format(date, 'hh:mm a')
      const interviewer = this.interviewData.interviewer_name
      console.log(interviewer)

      return `
              ${this.userInfo.ename || this.userInfo.fname} ${this.userInfo.ename2 || this.userInfo.lname} was invited an interview
              <br>
              <span class="text-gray-700 underline">${day}</span> at
              <span class="text-gray-700 underline">${time}</span>.
              <br>
              <span>Interviewer: ${interviewer}</span>
      `
    },
    smsCount () {
      const key = `sms_count_${this.type}`
      return this.userInfo[key]
    },
    emailCount () {
      const key = `email_count_${this.type}`
      return this.userInfo[key]
    }
  },
  methods: {
    onSave () {
      this.loading = true
      this.$emit('save', {
        interview_id: this.model,
        interview_type: this.type,
        callback: () => {
          this.loading = false
          this.isEdit = false
        }
      })
    },
    onSendEmail () {
      this.loading = true
      this.$emit('send-email', {
        interview_id: this.model,
        interview_type: this.type,
        callback: () => {
          this.loading = false
        }
      })
    },
    onSendSms () {
      this.loading = true
      this.$emit('send-sms', {
        interview_id: this.model,
        interview_type: this.type,
        callback: () => {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flip-box {
  background-color: transparent;
  width: 100%;
  height: 224px;
  perspective: 1000px;

  .flip-card {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    &.flipped {
      transform: rotateY(180deg);
    }

    ::v-deep(.form-item) {
      width: 100%;

      .el-select {
        width: 100%;
      }
    }

    .label {
      text-align: left;
    }

    .flip-card-front, .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 4px;
    }
    .flip-card-back {
      transform: rotateY(180deg);
    }
  }
}
</style>

<template>
  <el-card class="form-item candidatePreviewCard no-border" shadow="never">
    <template v-for="group in tabs" :key="group.key">
      <div class="form-item-groups px-8">
        <div
          v-for="(category, iCategory) in group.CategoriesList"
          :key="`category-${iCategory}`"
          class="form-item-category">
          <div class="card-title text-blue">
            {{ category.FormCategoryName }}
          </div>
          <div
            v-for="(field, iField) in category.PrameterList"
            :key="`field-${iCategory}-${iField}`"
            class="form-item-field flex justify-between px-2"
          >
            <div class="semibold">
              {{ field.parameterPrettyName }}
            </div>
            <div v-if="field.FormParameterTypeName === 'Image'">
              <el-avatar
                shape="square"
                :size="64"
                :src="candidate[field.parameterKey]"
                class="mr-2 no-shrink"
              />
            </div>
            <div v-else class="text-dark">
              {{ candidate[field.parameterKey] }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </el-card>
</template>

<script>
export default {
  name: "CandidatePreviewCard",
  props: {
    tabData: {
      type: Object,
      default: () => ({})
    },
    candidate: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  computed: {
    tabs() {
      return this.tabData.StepList || []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.candidatePreviewCard {
  @extend %infoCardStyles;
}
</style>

<template>
  <div class="WordSection1">
    <section style="width: 100%; font-size: 14px;background-color: white;">
      <div style="display: flex; justify-content: flex-end; margin-bottom: 8px; padding-bottom: 2px; background-color: white; border-bottom: 1px solid #E0E2E6">
        <div style="text-align: right">
          <span style="font-weight: 600;">Type:</span> <span class="text-small">{{ category.report_category_name }}</span>
        </div>
        <div style="text-align: right">
          <span style="font-weight: 600; margin-left: 16px">Report:</span> <span class="text-small">{{ reportInfo.report_remarks }}</span>
        </div>
      </div>
    </section>
    <section>
      <table id="report-group-word-table" style="width: 100%;border-collapse: collapse; border: 1px solid #ddd">
        <thead>
        <tr>
          <th
            v-for="(column, i) in columns"
            :key="`col-${i}`"
            style="text-align: center; border: 1px solid #ddd;padding-top: 6px;padding-bottom: 4px;background-color: #20ACE2;color: white;font-size: 14px;font-weight: 500;"
          >
            {{ column.label }}
          </th>
        </tr>
        </thead>
        <tbody>
        <template
          v-for="(group, gIndex) in groupedByCampData"
          :key="`group-${gIndex}`"
        >
          <tr class="group-row">
            <td :colspan="columns.length" style="font-size: 14px;font-weight: 600;text-align: left;background-color: #d3f2f6;">
              Camp {{ group.campName }}
            </td>
          </tr>
          <template
            v-for="(division, dIndex) in group.options"
            :key="`item-${gIndex}-${dIndex}`"
          >
            <tr class="group-row division-row">
              <td :colspan="columns.length" style="font-size: 14px;font-weight: 500;text-align: left; background-color: #e4eeef;">
                {{ division.DivisionName }}
              </td>
            </tr>
            <tr
              v-for="(rItem, rIndex) in division.Data"
              :key="`row-${gIndex}-${dIndex}-${rIndex}`"
            >
              <td
                v-for="(column, index) in columns"
                :key="`td-${gIndex}-${dIndex}-${rIndex}-${index}`"
                style="border: 1px solid #ddd; text-align: center; font-size: 12px"
              >
                {{ rItem[column.prop] || '---' }}
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";

export default {
  name: 'ReportsGroupedWord',
  props: {
    tableColumns: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      default: () => ({})
    },
    reportInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      filteredColumns: ['ID', 'Division']
    }
  },
  computed: {
    columns () {
      return this.tableColumns.filter(item => !this.filteredColumns.includes(item.prop))
    },
    groupedByCampData () {
      if (!this.options.length) {
        return []
      }
      const _groupedData = groupBy(this.options, (item) => item.CampName)
      return Object.entries(_groupedData).map(item => {
        return {
          campName: item[0],
          options: (item[1] || []).sort((a,b) => (a.DivisionOrder ?? 0) - (b.DivisionOrder ?? 0))
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@page WordSection1, @page {
  size: 841.95pt 595.35pt; /* EU A4 */
  /* size:11.0in 8.5in; */ /* US Letter */
  margin: 0.2cm 0 0 0;
  mso-page-orientation: portrait;
  mso-header: none;
  mso-footer: none;
}
div.WordSection1 {
  page: WordSection1;
}
#report-group-word-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  thead {
    display: table-row-group;
  }
  table, tr, td, th, tbody, thead  {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
  }
  td, th {
    border: 1px solid #ddd;
    text-align: center;
  }
  tbody {
    td {
      font-size: 10px;
      padding: 2px;
    }
  }
}
</style>

<template>
  <nav class="navbar flex justify-between items-center">
    <div class="sidebar-logo flex-center">
      <router-link to="/admin">
        <NavLogoIcon class="logo-icon"/>
      </router-link>
    </div>
    <div class="menu-toggle-action">
      <div
        :class="{'close': showMenu}"
        class="toggle-menu"
        @click="toggleMenu"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </nav>
</template>

<script>
import NavLogoIcon from "@/components/NavLogoIcon";
export default {
  name: "Header",
  components: { NavLogoIcon },
  data () {
    return {
      command:{
        Logout: "logout",
        Settings: 'settings'
      },
      showMenu: false
    }
  },
  methods: {
    async handleCommand(command) {
      if(command === this.command.Logout) {
        console.log('Logout')
      }
      if(command === this.command.Settings) {
        console.log('Settings')
      }

    },
    toggleMenu() {
      const sidebar = document.getElementsByClassName('admin-sidebar')[0]
      this.showMenu = !this.showMenu
      this.$emit('toggleMenu', this.showMenu)
      if (this.showMenu) {
        sidebar.classList.add('is_open')
      } else {
        sidebar.classList.remove('is_open')
      }
    }
  }
}
</script>

<style lang="scss">
  .navbar {
    height: 100%;
    .sidebar-logo {
      width: 100px;
      height: 56px;
      svg {
        max-width: 100%;
        max-height: 100%;
        width: 100px;
        height: 56px;
      }
    }
    .navbar-nav {
      .photo {
        margin: 0 8px;
        img {
          object-fit: contain;
          width: 45px;
          height: 45px;
        }
      }
    }
    .toggle-menu {
      width: 24px;
      height: auto;
      cursor: pointer;
      min-height: 24px;
      display: none;
      span {
        transition: all .38s ease;
        width: 24px;
        margin: 4px 0;
        display: block;
        background: #309A47;
        height: 2px;
      }
    }
  }
  .profile-dropdown {
    min-width: 160px;
  }

  @media only screen and (max-width: 780px) {
    .navbar {
      .sidebar-logo {
        display: none;
      }
      .toggle-menu {
        display: block;
        &.close {
          span:first-child {
            transform: rotate(45deg) translate(4px, 4px);
          }
          span:nth-child(2) {
            opacity: 0;
            display: none;
          }
          span:last-child {
            transform: rotate(-45deg) translate(0, 0);
          }
        }
      }
    }
  }
</style>

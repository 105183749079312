<template>
  <div class="html-editor relative w-full">
    <editor
      v-model="model"
      :editor="editorCode"
      :config="configs"
    />
  </div>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import editor from '@/vendor/redactor/ckeditor'

export default {
  name: 'html-editor',
  components: {
    editor: CKEditor.component
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    configs: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editorCode: editor,
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
  },
}
</script>
<style lang="scss">

</style>

<template>
  <el-form-item :label="getParameterPrettyName" :prop="getParameterKey">
    <el-input
      :type="type"
      v-model="parameterValue"
      v-bind="parameter.FormParameterData"
    />
  </el-form-item>
</template>

<script>
import parameterMixin from '@/mixins/parameterMixin';

export default {
  name: 'ParameterText',
  mixins: [parameterMixin],
  computed: {
    type() {
      return this.parameter?.FormParameterData?.isTextarea ? 'textarea' : 'text'
    }
  }
}
</script>

<template>
  <div class="recipients-table-wrap">
    <table v-if="isVisible" class="recipients-table">
      <thead>
      <tr>
        <th class="table-cell">#</th>
        <th class="table-cell">Name</th>
        <th
          v-for="thRecipient in recipients"
          :key="`th-${thRecipient}`"
          class="table-cell"
        >
          {{ thRecipient }}
        </th>
        <th class="table-cell">
          Preview
        </th>
        <th class="table-cell">
          Status
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in dataFields" :key="i">
        <td class="table-cell">{{i + 1}}</td>
        <td class="table-cell">{{ item[nameKey] }}</td>
        <template
          v-for="rField in recipients"
          :key="rField"
        >
          <td class="table-cell">
            <span class="mb-1 mr-1">
              {{ `${item[recipientFields[rField]]}` }}
            </span>
          </td>
        </template>
        <td class="table-cell text-center">
          <el-button size="small" type="primary" plain @click="openPreviewEmail(item)">
            <i class="vc-icon-eye"></i>
          </el-button>
        </td>
        <td v-loading="hasLoading(item)" class="table-cell text-center">
          <i v-if="hasSuccess(item)" class="vc-icon-checkmark text-active"></i>
          <i v-else class="vc-icon-cross text-destroy"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "RecipientsTable",
  props: {
   isVisible: {
     type: Boolean,
     default: false
   },
    recipients: {
     type: Array,
      default: () => []
    },
    recipientFields: {
     type: Object,
      default: () => ({})
    },
    dataFields: {
     type: Array,
      default: () => []
    },
    nameKey: {
     type: String,
     default: 'name'
    },
    processData: {
      type: Object,
      default: () => ({})
    },
  },
  emits: ['preview'],
  methods: {
    openPreviewEmail (data) {
      this.$emit('preview', data)
    },
    hasLoading (item) {
      return (this.processData[item.id]?.status === 'sending')
    },
    hasSuccess (item) {
      return (this.processData[item.id]?.status === 'done')
    }
  }
}
</script>

<style lang="scss" scoped>
  .recipients-table-wrap {
    .recipients-table {
      margin-top: 12px;
      border-collapse: collapse;
      color: black;
      font-size: 16px;
      th {
        font-size: 18px;
        font-weight: 600;
      }
      .table-cell {
        padding: 8px 16px;
        border-bottom: 1px solid #BEC2C9;
      }
    }

    @media only screen and (max-width: 680px) {
      max-width: 100%;
      overflow: auto;

      .recipients-table {
        width: max-content;
        .table-cell {
          padding: 4px 6px;
        }
      }
    }
  }
</style>

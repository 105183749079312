import {instanceCookie} from '@/api/axios'

const prefix = '/FirstApplication'

export default {
  async List(parameters) {
    const parametersCopy = Object.fromEntries(Object.entries(parameters).filter(parameter => !!parameter[1]))

    const { data } = await instanceCookie.post(`${prefix}/List/`, parametersCopy)
    return data
  },

  async ChangeStatus(id, status) {
    return await instanceCookie.post(`${prefix}/SetFirstApplicationStatus/${status}/${id}`)
  }
}

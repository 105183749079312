import {instance} from '@/api/axios'
import removeUnneededStaffParams from "@/util/removeUnneededStaffParams";

const prefix = '/StaffMy'

export default {
  async Info(id) {
    const {data} = await instance.get(`${prefix}/Info/?StaffID=${id}`)

    return data
  },

  Update(staff) {
    const {id} = staff

    return instance.post(`${prefix}/Update/?StaffID=${id}`, removeUnneededStaffParams(staff))
  },

  async Login(credentials) {
    const {data} = await instance.post(`${prefix}/LogIn/`, credentials)

    return data
  },

  ResetPassword(email) {
    return instance.get(`${prefix}/ResetPassword/?EmailAddress=${email}`)
  }
}

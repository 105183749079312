import {instance} from '@/api/axios'

const STORAGE_DOMAIN = 'storage.cloud.google.com'

export default {
  async UploadPic(file) {
    const bodyFormData = new FormData()

    bodyFormData.append('Staff', file)

    const {data} = await instance({
      method: 'post',
      url: '/UploadStaffPic',
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    return data
  },

  async UploadDoc(file, url = '/UploadStaffDoc') {
    const bodyFormData = new FormData()

    bodyFormData.append('Staff', file)

    const {data} = await instance({
      method: 'post',
      url,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    url = new URL(data)
    url.host = STORAGE_DOMAIN
    return url.toString()
  }
}

import {instanceCookie} from '@/api/axios'

import removeUnneededStaffParams from '@/util/removeUnneededStaffParams'

const prefix = '/Staff'

export default {
  async List(filter = {}) {
    const { data } = await instanceCookie.post(`${prefix}/List/`, filter)

    return data.Data || {}
  },

  async Info(id) {
    const { data } = await instanceCookie.get(`${prefix}/Info/?StaffID=${id}`)
    return data.Data || {}
  },

  Upsert(staff) {
    return instanceCookie.post(`${prefix}/Upsert/`, removeUnneededStaffParams(staff))
  },

  BulkSendSMS(data) {
    return instanceCookie.post(`${prefix}/BulkSendSMS/`, data)
  },

  BulkSendEmail(staff) {
    return instanceCookie.post(`${prefix}/BulkSendEmail/`, staff)
  },

  SendFirstLogin(StaffID) {
    return instanceCookie.get(`${prefix}/SendFirstLogin/${StaffID}`)
  },

  InterviewList() {
    return instanceCookie.get(`${prefix}/InterviewList/`)
  },

  async ConfData() {
    const { data } = await instanceCookie.post(`${prefix}/ConfData/`);

    return data
  },

  async SummarizeBox() {
    const { data } = await instanceCookie.get(`${prefix}/SummarizeBox/`);

    return data
  }
}

import candidateFormsDefault, {mapForms} from '@/util/getCandidateFormsParsed'
import debounce from "lodash/debounce";
import FormsController from 'controllers/Forms'
import Staff from "controllers/Staff";

const BE_COLUMNS_FILTER = ['status', 'first_name']
const SORT_NAME_COLUMN = 'full_name'

export default {
  data () {
    return {
      singleSearchColumnName: 'first_name',
      viewObserver: null,
      formsStatus: 3,
      step: 1,
      visibleCount: 20,
      loading: true,
      tableLoading: false,
      tableData: [],
      // visibleTableData: [],
      tableDataInit: [],
      searchModel: {},
      candidateForms: candidateFormsDefault,
      setDefault: false,
      defaultFilterValues: {
        status: []
      }
    }
  },
  computed: {
    columns() {
      return []
    },
    statuses() {
      return (this.$store.getters.getStaffStatuses || []).sort((a,b) => a.status_name.localeCompare(b.status_name))
    },
    positions() {
      return this.$store.getters.getStaffPositions || []
    },
    isConfigData() {
      return this.$store.getters.isFetchConfigData || false
    },
    campFilterList() {
      return this.$store.getters.getStaffCamps || []
    },
    visibleTableData () {
      return this.tableData.slice(0, this.step * this.visibleCount)
    }
  },
  watch: {
    tableData () {
      this.step = 1
    }
  },
  methods: {
    getChunkTableData () {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true)
        }, 300)
      })
    },

    async loadMoreFn () {
      if (this.visibleTableData.length < this.tableData.length) {
        this.tableLoading = true
        await this.getChunkTableData()
        this.tableLoading = false
        this.step++
      }

    },
    getFilterData (column, value) {
      let filter = {}
      BE_COLUMNS_FILTER.forEach(item => {
        if (this.searchModel[item]) {
          filter[item] = this.searchModel[item]
        }
      })
      if (filter[this.singleSearchColumnName] && column === this.singleSearchColumnName && value) {
        filter = {
          [this.singleSearchColumnName]: filter[this.singleSearchColumnName]
        }
        BE_COLUMNS_FILTER.filter(i => i !== this.singleSearchColumnName).forEach(_key => {
          this.searchModel[_key] = Array.isArray(this.searchModel[_key])  ? [] : ''
        })
      } else if (this.setDefault && column === this.singleSearchColumnName && !value) {
        filter = this.defaultFilterValues
        Object.keys(this.defaultFilterValues).forEach(i => {
          this.searchModel[i] = this.defaultFilterValues[i]
        })
      }
      // if (val) {
      //   filter[col] = val
      // }

      this.searchModel[column] = value
      return filter
    },
    debounceSearchData: debounce(async function () {
      const [col, val] = arguments

      if (Object.values(this.searchModel).every(el => !el)) {
        await this.getList({})
        return
      }

      this.tableData = this.tableDataInit

      if (BE_COLUMNS_FILTER.includes(col)) {
        let filter = this.getFilterData(col, val)
        await this.getList(filter)
        this.searchModel[col] = val
      }

      const entriesToSearch = Object.entries(this.searchModel).filter(([col]) => !BE_COLUMNS_FILTER.includes(col))
      entriesToSearch.forEach(([col, val]) => {
        if (val === '') return
        if (typeof val === 'string') {
          const _value = val.trim()
          this.tableData = this.tableData.filter(i => {
            if (col === 'cell') {
              return `${i[col]} ${i.phone}`.toLowerCase().search(_value.toLowerCase()) >= 0
            }
            return i[col].toString().toLowerCase().search(_value.toLowerCase()) >= 0
          })
        } else if (typeof val === 'number') {
          this.tableData = this.tableData.filter(i => +i[col] === +val)
        }
      })
    }, 500),
    async getConfData() {
      try {
        if (!this.isConfigData) {
          await this.$store.dispatch('fetchStaffConfigs')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getForms() {
      const candidateForms = await FormsController.ParametersList(this.formsStatus)

      if (candidateForms.length > 0) {
        this.candidateForms = mapForms(candidateForms)
      }
    },
    mapResponse(item) {
      return {
        ...item
      }
    },
    sortData(a, b) {
      return ('' + a[SORT_NAME_COLUMN]).localeCompare(b[SORT_NAME_COLUMN]);
    },
    async getList(filter) {
      this.loading = true
      try {
        const {staff} = await Staff.List(filter)
        let finalData = staff.map(this.mapResponse)
        finalData = finalData.sort(this.sortData)
        this.tableDataInit = [...finalData]
        this.tableData = [...finalData]
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    if (this.$refs.loadMoreTrigger) {
      this.viewObserver = new IntersectionObserver(async () => {
        await this.loadMoreFn()
      })
      this.viewObserver.observe(this.$refs.loadMoreTrigger)
    }
  },
  beforeUnmount () {
    if (this.viewObserver) {
      this.viewObserver.disconnect()
    }
  }
}

<template>
  <el-form-item :label="getParameterPrettyName" :prop="getParameterKey">
    <el-date-picker
      v-model="parameterValue"
      v-bind="parameter.FormParameterData"
      :disabled-date="disabledDate"
      value-format="DD MMM YYYY"
      :type="type"
    />
  </el-form-item>
</template>

<script>
import parameterMixin from '@/mixins/parameterMixin';

export default {
  name: 'ParameterDate',
  mixins: [parameterMixin],
  computed: {
    type() {
      return this.parameter?.FormParameterData?.isDateTime ? 'datetime' : 'date'
    }
  },
  methods: {
    disabledDate (date) {
      return date > new Date(this.parameter?.FormParameterData?.maxDate || '2100')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-form-item__content) {
  .el-input {
    width: 100%;
  }
}
</style>

<template>
  <div class="container">
    <div class="flex justify-between items-end mb-10 text-md">
      <span class="total-counter">
        total
        <span class="number">
          {{candidateList.length}}
        </span>
        applications
      </span>
      <Filter
        v-model="filter"
        @submit="onFilterSubmit"
      />
    </div>
    <div v-loading.fullscreen.lock="loading">
      <CandidateCard
        v-for="(candidateInfo, index) in candidateList"
        :class="{'mb-10': index !== candidateList.length - 1}"
        :key="candidateInfo.id"
        :candidate-info="candidateInfo"
        @delete="onDelete"
        @interview="onInterview"
        @send-login-details="onSendLoginDetails"
      />
    </div>
  </div>
</template>

<script>
import FirstApplicationController from 'controllers/FirstApplication'
import StaffController from 'controllers/Staff'
import STATUS from '@/enum/status'
import Filter from '@/components/pages-components/admin/FirstApplication/Filter'
import CandidateCard from '@/components/pages-components/admin/FirstApplication/CandidateCard'

export default {
  name: 'FirstApplication',
  components: {
    Filter,
    CandidateCard
  },
  data() {
    return {
      filter: {
        firstapplication_status_id: STATUS.FIRST_APPLICATION_STATUS.Enabled
      },
      candidateList: [],
      loading: false
    }
  },
  methods: {
    openNewStaffInfo (id) {
      this.$router.push({name: 'CandidateInfoTab', params: {candidate: id}})
    },
    async getCandidates() {
      this.loading = true

      try {
        let {data} = await FirstApplicationController.List(this.filter)

        this.candidateList = data.sort((a, b) => b.id - a.id)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

      this.loading = false
    },
    onFilterSubmit(filter) {
      this.filter = filter

      this.getCandidates()
    },
    async onDelete({id}) {
      this.loading = true

      try {
        await FirstApplicationController.ChangeStatus(id, STATUS.FIRST_APPLICATION_STATUS.Erased)
        await this.getCandidates()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onInterview({id}) {
      this.loading = true

      try {
        const {data} = await FirstApplicationController.ChangeStatus(id, STATUS.FIRST_APPLICATION_STATUS.Interview)
        await StaffController.SendFirstLogin(data.StaffID)
        await this.getCandidates()
        this.openNewStaffInfo(data.StaffID)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async onSendLoginDetails({staff_id}) {
      this.loading = true

      try {
        await StaffController.SendFirstLogin(staff_id)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  created() {
    this.getCandidates()
  }
}
</script>

<style lang="scss" scoped>
  .container {
    margin: 25px 130px;
    position: relative;

    .logo {
      margin-right: 84px;
    }
  }

  @media screen and (max-width: 960px) {
    .container {
      margin: 0;
    }
  }
</style>

<template>
  <el-card class="positions-block mb-8">
    <div class="positions-block-content">
      <div class="positions-cards">
        <position-flip-card
          :current="userInfo.pos1"
          label="First Position"
          @set-position="onSetPosition('pos1', $event)"
        />
        <position-flip-card
          :current="userInfo.pos2"
          label="Second Position"
          @set-position="onSetPosition('pos2', $event)"
        />
        <position-flip-card
          :current="userInfo.pos3"
          label="Third Position"
          @set-position="onSetPosition('pos3', $event)"
        />
        <position-flip-card
          :current="userInfo.pos_camp"
          label="Final Position"
          final
          @set-position="onSetPosition('pos_camp', $event)"
        />
      </div>
      <div class="positions-remarks">
        <div class="remark-card">
          <slot name="remark"></slot>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import PositionFlipCard from "@/components/pages-components/common/Cards/PositionFlipCard";
import Staff from "controllers/Staff";
import STATUS from "@/enum/status";
export default {
  name: "PositionsBlock",
  components: {PositionFlipCard},
  props:{
    userInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    onSetPosition(keyPos, event) {
      const data = {
        id: this.userInfo.id,
        [keyPos]: event.id,
        callback: event.callback
      }

      if (keyPos === 'pos_camp') {
        data.status = STATUS.STAFF_STATUS.ok
      }

      this.upsertData(data)
    },
    async upsertData(data) {
      try {
        await Staff.Upsert(data)
        this.$emit('refresh-data')
      } finally {
        data.callback()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .positions-block {
    .positions-cards {
      display: grid;
      gap: 36px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .positions-remarks {
      display: grid;
      gap: 36px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (max-width: 1600px) {
      .positions-cards {
        gap: 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
    @media only screen and (max-width: 1460px) {
      .positions-cards {
        gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    @media only screen and (max-width: 1120px) {
      .positions-cards {
        gap: 20px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .positions-remarks {
        gap: 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
    @media only screen and (max-width: 800px) {
      .positions-cards {
        gap: 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
</style>

<template>
  <div class="stepper-wrapper">
    <el-steps
      :active="activeIndex"
      :space="200"
      align-center
    >
      <el-step
        v-for="step in steps"
        :key="step.key"
        :title="step.prettyName"
      />
    </el-steps>
  </div>
</template>

<script>
export default {
  name: 'WizardStepper',
  props: {
    activeIndex: {
      type: Number,
      default: -1
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.stepper-wrapper {
  &::v-deep(.el-steps) {
    justify-content: center;
  }
}

@media screen and (max-width: 960px) {
  .stepper-wrapper {
    ::v-deep(.el-step__title) {
      font-size: 8px;
      line-height: 10px;
    }
  }
}
</style>

import {instanceCookie} from '@/api/axios'

const EMAIL_TEMPLATES = '/EmailTemplate'

export default {
  async getEmailTemplateList(formSystem) {
    const {data} = await instanceCookie.get(`${EMAIL_TEMPLATES}/List/${formSystem}`)
    return data?.Data || []
  },
  async EmailTemplateAdd(formData) {
    const {data} = await instanceCookie.post(`${EMAIL_TEMPLATES}/Add/`, formData)
    return data?.Data || []
  },
  async EmailTemplateUpdate(formData) {
    const {data} = await instanceCookie.post(`${EMAIL_TEMPLATES}/Update/`, formData)
    return data?.Data || []
  },
  async EmailTemplateDelete(templateId) {
    const {data} = await instanceCookie.post(`${EMAIL_TEMPLATES}/Delete/`, {
      "EmailTemplateID": templateId
    })
    return data?.Data || []
  },

  /**
  * New Send Email Method
  *
  * @param formData:
  * {
  *   "EmailBody": "Body of email",
  *   "EmaiilSender": "info@camp.co.il",
  *   "EmaiilSubject": "Subject of the email",
  *   "EmailRecipientList": [
  *     "test@test.com",
  *     ""
  *   ]
  * }
  * @returns response
  * */
  async sendEmail(formData) {
    return await instanceCookie.post('/Send/Email/', formData)
  },

  /**
   * New Send SMS method
   *
   *
   * @param formData:
   * {
   *   "SmsBody": "This is a test SMS",
   *   "SmsSender": "033030303",
   *   "SmsRecipientList": [
   *     "0522238758",
   *     ""
   *   ]
   * }
   * @returns response
   */
  async sendSMS(formData) {
    return await instanceCookie.post('/Send/Sms/', formData)
  }
}

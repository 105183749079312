<template>
  <div class="sidebar-fields">
    <div class="mb-3">
      <span class="header-6 text-black">{{ blockTitle }}</span>
      <span class="text-black">
        (click to copy)
      </span>
    </div>
    <div class="fields-list-wrap">
      <ul class="fields-list">
        <li
          v-for="item in fields"
          :key="item"
          class="pointer"
          @click="copyText(item)"
        >
          <span>{{ item }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "SidebarList",
  props: {
    blockTitle: {
      type: String,
      default: 'Fields:'
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    copyText (text) {
      if (!navigator || !text) {
        return
      }
      const tagText = `{{${text}}}`
      navigator.clipboard.writeText(tagText)
      ElMessage({
        showClose: true,
        message: `${tagText} was copied`,
        type: 'success'
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.sidebar-fields {
  border-left: 2px solid #BEC2C9;
  flex: 0 0 360px;
  padding-left: 24px;
  .fields-list {
    color: black;
    padding-left: 32px;
    max-height: 720px;
    overflow-y: auto;
    li {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 500;
      &:hover {
        font-weight: 600;
      }
    }
  }
}
</style>

<template>
  <div class="candidate-preview-page px-1 pb-8" v-loading="loading">
    <div class="top-row flex justify-between items-center py-3 mb-8">
      <div class="user-avatar-block flex no-shrink items-center">
        <el-image
          v-if="staffInfoData.staff_pic_url"
          fit="cover"
          :src="staffInfoData.staff_pic_url"
          :preview-src-list="[staffInfoData.staff_pic_url]"
          class="mr-2 no-shrink user-avatar-image"
        >
        </el-image>
        <el-avatar v-else :size="140" class="mr-2 no-shrink" shape="square"></el-avatar>
        <div class="user-status-block flex-center">
          <div class="medium">
            {{ staffInfoData.name }}
          </div>
          <div class="text-md medium text-gray-700 flex items-center">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="'Last form update: ' + getFormLastSubmitDate(staffInfoData)"
              placement="top"
            >
              <div
                class="user-status mr-3"
                :style="{ backgroundColor: getColorByFormProgress(staffInfoData)}"
              />
            </el-tooltip>
            {{ staffStatus.status }}
          </div>
        </div>
      </div>
      <div class="user-name">
        {{ `${staffInfoData.ename || staffInfoData.fname} ${staffInfoData.ename2 || staffInfoData.lname}` }}
      </div>
      <div class="actions-block flex h-full w-20" style="align-self: flex-start">
      </div>
    </div>
    <div class="forms-info-wrapper">
      <el-tabs v-model="activeTab" type="border-card">
        <el-tab-pane
          v-for="(form, i) in params"
          :key="`form-${i}`"
          :name="i"
          :label="form.FormName"
        >
          <CandidatePreviewCard
            :tab-data="form"
            :candidate="staffInfoData"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {getFormLastSubmitDate, getColorByFormProgress} from '@/enum/formOrderHelper'
import Staff from "controllers/Staff";
import CandidatePreviewCard from "@/components/pages-components/admin/Staff/CandidatePreviewCard";

export default {
  name: "CandidatePreviewTab",
  components: {CandidatePreviewCard},
  props: {
    params: {
      type: [Array, Object],
      default: () => ({})
    },
    tabData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: true,
      activeTab: 0,
      staffInfoData: {},
      staffStatus: {},
      staffPositions: [],
      staffInterviews: []
    }
  },
  methods: {
    getFormLastSubmitDate,
    getColorByFormProgress,
    async fetchInfo () {
      try {
        // { info, interviews, pos, skills, staff_status }
        const { info, staff_status, interviews, pos } = await Staff.Info(this.tabData.staffId)
        this.staffInfoData = info
        this.staffStatus = staff_status
        this.staffInterviews = interviews
        this.staffPositions = pos
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchInfo()
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.candidate-preview-page {
  font-size: 16px;
.user-name {
  font-size: 36px;
  font-weight: 600;
}
.user-avatar-image {
  width: 140px;
  height: 140px;
  border-radius: 4px;
  background: #EBEBEB;
}
@media only screen and (max-width: 800px) {
  .user-name {
    margin: 12px 0;
  }
  .top-row {
    flex-wrap: wrap;
  .user-avatar-block {
    margin-bottom: 20px;
  }
}
}
}
</style>

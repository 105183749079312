import candidateWizard from '@/enum/ParametersList.json'

const ENUM = {
  FORM_KEY_KEY: 'FormTag',
  FORM_NAME_KEY: 'FormName',
  FORM_ORDER_KEY: 'FormOrder',
  STEP_KEY_KEY: 'StepTag',
  STEP_NAME_KEY: 'StepName',
  STEP_ORDER_KEY: 'StepOrder',
  CATEGORY_ORDER_KEY: 'FormCategoryOrder',
  PARAMETER_ORDER_KEY: 'parameterOrder'
}

function sortByKey(arr, key) {
  return arr.sort((a, b) => a[key] - b[key])
}

export function mapForms(forms) {
  const mapForm = (form) => {
    return {
      ...form,
      prettyName: form[ENUM.FORM_NAME_KEY],
      key: form[ENUM.FORM_KEY_KEY],
      StepList: form.StepList.length ? mapSteps(form.StepList) : []
    }
  }

  return sortByKey(forms, ENUM.FORM_ORDER_KEY).map(mapForm)
}

function mapSteps(steps) {
  const mapStep = (step) => {
    return {
      ...step,
      prettyName: step[ENUM.STEP_NAME_KEY],
      key: step[ENUM.STEP_KEY_KEY],
      CategoriesList: step.CategoriesList.length ? mapCategories(step.CategoriesList) : []
    }
  }

  return sortByKey(steps, ENUM.STEP_ORDER_KEY).map(mapStep)
}

function mapCategories(categories) {
  const mapCategory = (category) => {
    return {
      ...category,
      PrameterList: category.PrameterList.length ? mapParameters(category.PrameterList) : []
    }
  }

  return sortByKey(categories, ENUM.CATEGORY_ORDER_KEY).map(mapCategory)
}

function mapParameters(parameters) {
  return sortByKey(parameters, ENUM.PARAMETER_ORDER_KEY)
}

export default mapForms(candidateWizard)

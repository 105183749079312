<template>
  <div class="children-list pb-10 px-2" v-loading.fullscreen.lock="loading">
    <el-tabs
      v-model="activeTab"
      @tab-click="onTabClick"
      @tab-remove="removeTab"
    >
      <el-tab-pane name="list" class="tab-item">
        <div class="actions flex justify-start pb-3">
          <el-button type="success" @click="openAddModal">
            <i class="icon-base vc-icon-add-user mr-2"/>
            Add Child
          </el-button>
          <div class="px-5">
            <el-button type="primary" @click="onRefreshList">
              <i class="icon-base vc-icon-reset mr-2"/>
              Refresh List
            </el-button>
          </div>
        </div>

        <send-actions-block
          :visible="childrenSelected"
          :loading="sendBulkLoading"
          :data-fields="selectionChildren"
          :selected-items="selectionChildren.length"
          @sendSms="onBulkSendSms"
          @sendEmail="onBulkSendEmail"
        />

        <template #label>
          <div class="w-max">
            <div class="flex items-center border-box">
              <i class="vc-icon-logs mx-1 icon-lg"></i>
              <div class="" style="padding-right: 8px">
                Children
              </div>
            </div>
          </div>
        </template>
        <div class="children-all-table_wrap w-max">
          <div style="width: auto">
            <el-table
              ref="baseTable"
              :data="tableDataPaginated"
              size="small"
              empty-text="No Data!"
              :fit="false"
              :max-height="720"
              :indent="20"
              v-loading="tableLoading"
              class="children-all-table my-3 mr-2"
              @selection-change="handleSelectionChange"
              @sort-change="handleSortChange"
            >
              <el-table-column type="selection" width="36"/>
              <el-table-column type="index" width="40" class-name="index-cell"/>
              <TableContent
                :columns="columns"
              >
                <template #header="{col}">
                  <table-header
                    :column="col"
                    v-model="searchModel"
                    @onSearch="debounceSearchData"
                  />
                </template>
                <template #full_name="{dataKey, row}">
                  <span class="text-md medium user-name-cell" @click="openChildInfo(row)">
                    <span v-if="row[dataKey].trim()">
                      {{ substring(row[dataKey], 'full_name') }}
                    </span>
                    <span v-else>
                      ---
                    </span>
                  </span>
                </template>
                <template #phone="{dataKey, row}">
                  <span>
                    <a :href="`tel:${row[dataKey]}`" class="text-black">
                      {{ substring(row[dataKey], 'phone') }}
                    </a>
                  </span>
                </template>
                <template #email="{row}">
                  <div>
                    {{ substring(row.email_mother || row.email_father || row.email, 'email') }}
                  </div>
                </template>
                <template #division="{dataKey, row}">
                  <span>
                    {{ substring(row[dataKey], 'division') }}
                  </span>
                </template>
                <template #round="{dataKey, row}">
                   <span>
                    {{ substring(row[dataKey], 'round') }}
                  </span>
                </template>
                <template #status="{dataKey, row}">
                  <span>
                    {{ substring(row[dataKey], 'status') }}
                  </span>
                </template>
                <template #gender="{dataKey, row}">
                  <span class="uppercase">
                    {{ row[dataKey] }}
                  </span>
                </template>
                <template #billing_info="{row}">
                  <div>
                    <div class="w-full h-full flex items-center justify-end pr-3">
                      <el-popover :width="200" trigger="hover" placement="left">
                        <template #reference>
                          <div class="semibold pointer w-max" :class="row.billing_info?.Balance ? 'text-destroy' : 'text-active'">
                            {{ substring(row.billing_info?.Balance, 'billing_info') }}
                          </div>
                        </template>
                        <div class="balance-calc-block">
                          <div class="calc-item">
                            <span>CampBasePrice</span>
                            <span>
                        {{ row.billing_info?.CampBasePrice }}
                      </span>
                          </div>
                          <div class="op-item">+</div>
                          <div class="calc-item">
                            <span>DivisionExtraPrice</span>
                            <span>{{ row.billing_info?.DivisionExtraPrice }}</span>
                          </div>
                          <div class="op-item">+</div>
                          <div class="calc-item">
                            <span>ExtraSum</span>
                            <span>{{ row.billing_info?.ExtraSum }}</span>
                          </div>
                          <div class="op-item">-</div>
                          <div class="calc-item">
                            <span>ReceiptSum</span>
                            <span>{{ row.billing_info?.ReceiptSum }}</span>
                          </div>
                          <div class="op-item">=</div>
                          <div class="sum-item">
                            <span></span>
                            <span>{{ row.billing_info?.Balance }}</span>
                          </div>
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </template>
                <template #passport="{dataKey, row}">
                  <div class="w-full h-full flex-center">
                    <span v-if="row[dataKey]" class="flex-center">
                      <i class="vc-icon-checkmark text-active icon-lg mr-2"></i>
                      <el-image
                        style="width: 24px; height: 24px; display: inline-flex"
                        src="/images/eye.svg"
                        :preview-src-list="[row[dataKey]]"
                        :preview-teleported="false"
                        :z-index="5000"
                        fit="cover"
                      />
                    </span>
                    <i v-else class="vc-icon-cross icon-lg text-destroy"></i>
                  </div>
                </template>
              </TableContent>
            </el-table>

            <el-pagination
              v-model:current-page="pagination.currentPage"
              v-model:page-size="pagination.pageSize"
              :page-sizes="[150, 200, 300, 400]"
              layout="total, sizes, prev, pager, next"
              :total="tableData.length"
            />
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane
        v-for="(tab, i) in tabs"
        :key="`tab-${i}`"
        :name="tab.tabKey"
        class="tab-editable"
        closable
      >
        <template #label>
          <div class="w-max inline-flex">
            <div class="flex items-center border-box">
              <i class="vc-icon-edit-pencil mx-1 icon-base"></i>
              <div class="" style="padding-right: 8px">
                {{ tab.tabName }}
              </div>
            </div>
          </div>
        </template>
        <child-tab-component
          :tab-data="tab"
          :child-data="getChildTabInfo(tab.staffId)"
          :params="candidateForms"
          :configs="{...confData, rounds: rounds}"
          :data-fields="selectionChildren"
          @list-refresh="refreshChildren"
        />
      </el-tab-pane>
    </el-tabs>

    <create-child-form
      v-model="addModalVisible"
      :loading="formLoading"
      @onSubmit="addNewChild"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import get from "lodash/get";
import listTabsMixin from "@/mixins/listTabsMixin";
import Children from "controllers/Children";
import TableHeader from "@/components/pages-components/common/Table/TableHeader";
import TableContent from "@/components/pages-components/common/Table/TableContent";
import CreateChildForm from "@/components/pages-components/admin/Children/CreateChildForm";
import { ElMessage } from "element-plus";
import ChildTabComponent from "@/components/pages-components/admin/Children/ChildTabComponent";
import FormsController from 'controllers/Forms'
import {mapForms} from '@/util/getCandidateFormsParsed'
import SendActionsBlock from "@/components/pages-components/admin/Children/SendActionsBlock";
import { format } from 'date-fns'
import {
  FORM_STATUS,
  ROUNDS,
  DEFAULT_SORT,
  SEARCH_MODEL,
  getBaseColumns,
  RULES_FIELDS,
  STORAGE_DOMAIN,
  PASSPORT_CHECKED,
  PASSPORT_UNCHECKED,
  BE_COLUMNS_FILTER,
  SORTING_METHODS
} from '@/enum/childrenListColumns'

export default {
  name: "ChildrenList",
  components: {
    SendActionsBlock,
    ChildTabComponent,
    CreateChildForm,
    TableContent,
    [ElMessage.name]: ElMessage,
    TableHeader
  },
  mixins: [listTabsMixin],
  data() {
    return {
      formsStatus: FORM_STATUS,
      candidateForms: {},
      addModalVisible: false,
      formLoading: false,
      loading: false,
      sendBulkLoading: false,
      tableDataInit: [],
      tableDataInitMap: {},
      tableLoading: false,
      tableData: [],
      confData: null,
      searchModel: {...SEARCH_MODEL},
      years: [],
      routeListName: 'ChildrenList',
      routeTabName: 'ChildrenInfoTab',
      selectionChildren: [],
      rounds: ROUNDS,
      pagination: {
        pageSize: 150,
        currentPage: 1
      },
      sortModel: DEFAULT_SORT
    }
  },
  computed: {
    cities () {
      const list = this.confData?.CityList || []
      return list.map(i => ({
        ...i,
        label: i.city_name_heb,
        value: i.city_id
      }))
    },

    tableDataSorted () {
      const { prop, order } = this.sortModel

      if (!prop || !order) {
        return this.tableData
      }

      const sortFunction = prop in SORTING_METHODS
        ? SORTING_METHODS[prop]
        : SORTING_METHODS.default_sort(prop)

      return [ ...this.tableData ].sort((a, b) => {
        if (order === 'ascending') {
          return sortFunction(a, b)
        } else {
          return sortFunction(b, a)
        }
      })
    },

    tableDataPaginated () {
      const start = (this.pagination.currentPage - 1) * this.pagination.pageSize
      const end = start + this.pagination.pageSize

      return this.tableDataSorted.slice(start, end)
    },

    columns () {
      return getBaseColumns(this.statuses, this.divisionList, this.cities)
    },

    /* NO NEED TO CHANGE */
    divisionList () {
      const list = this.confData?.DvisionList || []
      return list.map(i => ({label: i.name, value: i.id}))
    },
    childrenSelected () {
      return !!this.selectionChildren.length
    },

    /* OPTIMIZED: */
    gradeMap () {
      return (this.confData.GradeList || []).reduce(
        (acc, el) => {
          acc[String(el.id)] = el

          return acc
        },
        {}
      )
    },
    roundsMap () {
      return this.rounds.reduce(
        (acc, el) => {
          acc[String(el.id)] = el

          return acc
        },
        {}
      )
    },
    citiesMap () {
      return this.cities.reduce(
        (acc, el) => {
          acc[String(el.city_id)] = el

          return acc
        },
        {}
      )
    },
    statuses () {
      const list = this.confData?.StatusList || []

      return list
        .reduce(
          (acc, el) => {
            if (el.is_show) {
              acc.push({
                label: el.name,
                value: el.id,
                status_order: el.status_order
              })
            }

            return acc
          },
          []
        )
        .sort((a,b) => a.status_order - b.status_order)
    },
  },
  methods: {
    handleSortChange ({ prop, order }) {
      this.sortModel = { prop, order }
    },
    onRefreshList () {
      this.selectionChildren = []
      this.getList()
      this.resetFilter()
    },
    resetFilter () {
      this.searchModel = { ...SEARCH_MODEL }
    },
    async refreshChildren (id) {
      try {
        const response = await Children.Info(id)

        if (!response) {
           return
        }

        const mappedResponse = this.mapChildren(response)

        this.tableDataInitMap[String(id)] = mappedResponse
        this.tableDataInit = this.tableDataInit.map(i => i.id === id ? mappedResponse : i)
        this.tableData = this.tableData.map(i => i.id === id ? mappedResponse : i)
      } catch (e) {
        console.error('refreshChildren', e)
      }
    },
    filterStringColumns (col, val) {
      const valLower = val.toLowerCase()
      let result = []

      switch (col) {
        case 'full_name':
          result = this.tableData.filter(i => i.search.full_name.search(valLower) >= 0)

          break
        case 'email':
          result = this.tableData.filter(i => i.search.email.search(valLower) >= 0)

          break
        case 'phone':
          result = this.tableData.filter(i => i.search.cell.search(valLower) >= 0)

          break
        case 'passport':
          if (val === '') {
            return this.tableData
          } else if (val === PASSPORT_CHECKED) {
            result = this.tableData.filter(i => i.has_passport)
          } else if (val === PASSPORT_UNCHECKED) {
            result = this.tableData.filter(i => !i.passport)
          }

          break
        default:
          result = this.tableData.filter(i => {
            return i[col].toString().toLowerCase().search(val.toLowerCase()) >= 0
          })

          break
      }

      return result
    },
    debounceSearchData: debounce(
      function () {
        const searchEmpty = Object.values(this.searchModel)
          .every(el => {
            if (Array.isArray(el)) {
              return !el.length
            } else {
              return !el
            }
          }
        )

        if (searchEmpty) {
          this.tableData = this.tableDataInit

          return
        }

        this.tableData = this.tableDataInit

        const entriesToSearch = Object.entries(this.searchModel).filter(([col]) => !BE_COLUMNS_FILTER.includes(col))

        entriesToSearch.forEach(
          ([col, val]) => {
            if (typeof val === 'string') {
              this.tableData = this.filterStringColumns(col, val)
            } else if (typeof val === 'number') {
              this.tableData = this.tableData.filter(i => +i[col] === +val)
            } else if (Array.isArray(val) && val.length) {
              if (!val.includes('')) {
                this.tableData = this.tableData.filter(i => val.includes(i[col]))
              }
            }
          }
        )
      },
      500
    ),
    async getConfData () {
      if (!this.confData) {
        try {
          const { Data } = await Children.GetConfData()
          this.confData = Data || {}
          this.$store.dispatch('addGlobalOption', { CityList: Data.CityList })
          this.$store.dispatch('addGlobalOption', { FlightMeelTypeList: Data.FlightMeelTypeList })
          this.$store.dispatch('addGlobalOption', { DvisionList: Data.DvisionList })
        } catch (e) {
          console.log('getConfData', e)
        }
      }
    },
    async getForms() {
      try {
        const candidateForms = await FormsController.ParametersList(this.formsStatus)

        if (candidateForms.length > 0) {
          this.candidateForms = mapForms(candidateForms)
        }
      } catch (e) {
        console.log('getForms', e)
      }
    },
    replaceUrlStorageDomain (url) {
      if (!url) return ''

      url = new URL(url)
      url.host = STORAGE_DOMAIN

      return url.toString()
    },
    handleSelectionChange(val) {
      this.selectionChildren = val
    },
    checkTab() {
      const param = this.$route.params.candidate
      this.openChildById(param)
    },
    openChildInfo (childData) {
      this.openInTab(childData, true)
    },
    async addNewChild (childData) {
      this.formLoading = true

      try {
        const { ChildID } = await Children.Upsert(childData)

        ElMessage({
          showClose: true,
          message: 'Child was added!',
          type: 'success',
        })

        this.addModalVisible = false

        await this.getList()

        if (ChildID) {
          this.openChildById(ChildID)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.formLoading = false
      }
    },
    async onBulkSendSms (data) {
      const list = this.selectionChildren
      const { message } = data
      if (!list.length || !message) {
        return
      }
      try {
        this.sendBulkLoading = true
        const body = {
          id_list: [...list],
          messege: message
        }
        await Children.BulkSendSMS(body)
        ElMessage({
          showClose: true,
          message: 'SMS List successfully sent',
          type: 'success',
        })
        this.onRefreshList()
      } catch (e) {
        console.log(e)
      } finally {
        this.sendBulkLoading = false
      }
    },
    async onBulkSendEmail (data) {
      const list = this.selectionChildren
      const { message, subject } = data
      if (!list.length || !message) {
        return
      }
      try {
        this.sendBulkLoading = true

        const body = {
          id_list: [...list],
          messege: message,
          subject
        }
        await Children.BulkSendEmail(body)

        ElMessage({
          showClose: true,
          message: 'Email List successfully sent',
          type: 'success',
        })
        this.onRefreshList()
      } catch (e) {
        console.log(e)
      } finally {
        this.sendBulkLoading = false
      }
    },
    openAddModal () {
      this.addModalVisible = true
    },
    substring (str, rule) {
      if (!rule || !str) {
        return str
      }
      return str.toString().slice(0, RULES_FIELDS[rule])
    },
    async searchDataFn () {
      if (Object.values(this.searchModel).every(el => {
        if (Array.isArray(el)) {
          return !el.length
        } else {
          return !el
        }
      })) {
        this.tableData = this.tableDataInit
        return
      }

      this.tableData = this.tableDataInit
      const entriesToSearch = Object.entries(this.searchModel).filter(([col]) => !BE_COLUMNS_FILTER.includes(col))

      entriesToSearch.forEach(([col, val]) => {
        if (typeof val === 'string') {
          this.tableData = this.filterStringColumns(col, val.trim())
        } else if (typeof val === 'number') {
          this.tableData = this.tableData.filter(i => +i[col] === +val)
        } else if (Array.isArray(val) && val.length) {
          if (!val.includes('')) {
            this.tableData = this.tableData.filter(i => val.includes(i[col]))
          }
        }
      })
    },
    mapChildren (item) {
      const fName = item.fname_heb
      const lName = item.lname_heb
      const fNameEn = item.fname_eng
      const lNameEn = item.lname_eng
      const status = get(item, 'status_info.name')
      const passport_image = this.replaceUrlStorageDomain(get(item, 'data.passport', ''))
      const has_passport = !!passport_image
      const reg_date_formatted = item.reg_date
        ? format(new Date(item.reg_date), 'dd-MM-yy')
        : ''

      const city_name = this.getCityName(item)
      let division = ''
      if (item.division_id) {
        division = `${get(item, 'division_info.division_name', '')} (${get(item, 'division_info.camp_name', '')})`
      }
      const grade = this.getGradeName(item.grade_id)

      const full_name = `${fName} ${lName}`
      const full_name_eng = `${fNameEn} ${lNameEn}`

      return {
        ...item,
        full_name,
        full_name_eng,
        phone: item.cell_mother || item.cell_father || item.cell,
        round: this.getRound(item.round_id),
        passport: passport_image,
        camp: get(item, 'division_info.camp_name', ''),
        division,
        city_name,
        grade,
        status,
        has_passport,
        reg_date_formatted,
        search: {
          full_name: `${full_name.toString().toLowerCase()}${full_name_eng.toString().toLowerCase()}`,
          email: `${item.email_mother.toString().toLowerCase()}${item.email_father.toString().toLowerCase()}${item.email.toString().toLowerCase()}`,
          cell: `${item.cell_mother.toString().toLowerCase()}${item.cell_father.toString().toLowerCase()}${item.cell.toString().toLowerCase()}`
        }
      }
    },
    async getList () {
      this.loading = true

      try {
        const dataList = await Children.List()

        const years = new Set([])

        const mappedList = []

        dataList
          .forEach(item => {
            if (item.year) {
              years.add(item.year)
            }

            const result = this.mapChildren(item)

            mappedList.push(result)

            this.tableDataInitMap[String(item.id)] = result
          }
        )

        this.years = [ ...years.values() ].sort().map(i => ({label: i, value: i}))
        this.tableDataInit = [ ...mappedList ]
        this.tableData = [ ...mappedList ].sort((fieldA,fieldB) => {
          const dateA = fieldA.reg_date ? new Date(fieldA.reg_date).getTime() : 0
          const dateB = fieldB.reg_date ? new Date(fieldB.reg_date).getTime() : 0

          return dateB - dateA
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getRound(roundId) {
      return this.roundsMap[String(roundId)]?.label
    },
    getGradeName (gradeId) {
      return this.gradeMap[String(gradeId)]?.name
    },
    getCityName (data) {
      if (!data?.city_id) {
        return ''
      }

      const city = this.citiesMap[String(data.city_id)]

      return city?.city_name_heb || city?.city_name_eng || ''
    },
    async setDefaultStatus (configs) {
      const statuses = configs.StatusList || []
      const _defaultStatus = statuses.find(i => i.status_is_default)
      if (_defaultStatus) {
        console.log('IS DEFAULT STTUS:', _defaultStatus)
        this.searchModel.status_id = _defaultStatus.id
        await this.searchDataFn()
      }
    },
    openChildById(id) {
      if (id) {
        const userData = this.tableDataInitMap[String(id)]

        if (userData) {
          this.openInTab(userData, true)
        }
      }
    },
    getChildTabInfo(childId) {
      return this.tableDataInitMap[String(childId)]
    },
  },
  async mounted() {
    this.loading = true

    await this.getConfData()
    await this.getForms()
    await this.getList()
    await this.setDefaultStatus(this.confData)
    this.checkTab()
  }
}
</script>

<style lang="scss">
@import '@/styles/mixins';
  .children-all-table_wrap {
    width: max-content;
    max-width: 100%;
    padding-bottom: 32px;
  }
  .children-list {
    .children-all-table {
      @extend %tableBaseStyle;
      th .cell {
        padding-bottom: 36px;
      }
    }
    .user-name-cell {
      transition: all 0.38s ease;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
    .el-select .el-select__tags-text {
      justify-content: flex-start;
    }
  }
.balance-calc-block {
  width: 100%;
  box-sizing: border-box;
  .calc-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    line-height: 1;
    span:first-child {
      font-size: 14px;
      padding-right: 12px;
    }
    span:last-child {
      font-weight: 600;
      color: var(--black);
    }
  }
  .sum-item {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    color: black;
    font-weight: 600;
  }
  .op-item {
    position: relative;
    text-align: right;
    font-size: 20px;
    line-height: 1;
    font-weight: 200;
    //border-bottom: 1px dashed var(--gray-500);
  }
}
</style>

import {instance} from '@/api/axios'

const prefix = '/Forms'

export default {
  async ParametersList(FormSystemID = 1) {
    const { data } = await instance.get(`${prefix}/ParametersList/${FormSystemID}`)

    return data.Data || []
  }
}

<template>
  <el-card
    :body-style="{ padding: '0px' }"
    :class="{'collapsed': collapsed}"
    class="collapse-card"
  >
    <template #header>
      <div @click="toggleCollapse" class="w-full flex justify-between items-center pointer">
        <div class="header-content grow">
          <slot name="header"></slot>
        </div>
        <div class="header-action flex-center no-shrink pl-3 pr-1">
          <span v-show="expandable" class="vc-icon-down text-blue icon-xl pointer"></span>
        </div>
      </div>
    </template>
    <template v-if="expandable">
      <el-collapse-transition>
        <div v-show="collapsed">
          <div class="p-4">
            <slot></slot>
          </div>
        </div>
      </el-collapse-transition>
    </template>
  </el-card>
</template>

<script>
export default {
  name: "ExpandCard",
  props: {
    expandable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    collapsed: false
  }),
  methods: {
    toggleCollapse () {
      if (!this.expandable) {
        return
      }
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse-card {
  .vc-icon-down {
    transition: all .3s ease;
  }
  .header-action {
    min-width: 40px;
  }
  &.collapsed {
    .vc-icon-down {
      transform: rotate(180deg);
    }
  }
}
</style>

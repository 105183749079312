import {instanceCookie} from '@/api/axios'

const PREFIX = '/Report'

export default {
  async List() {
    const { data } = await instanceCookie.get(`${PREFIX}/List/`)

    return data.Data || []
  },

  async Data (reportID) {
    const { data } = await instanceCookie.get(`${PREFIX}/Data/${reportID}`)

    return data.Data || {}
  },

  async groupedData (reportID) {
    const { data } = await instanceCookie.get(`${PREFIX}/DivisionData/${reportID}`)

    return data.Data || []
  }
}

<template>
  <el-form-item :label="getParameterPrettyName" :prop="getParameterKey">
    <el-select
      v-model="parameterValue"
      v-bind="parameter.FormParameterData"
      :filterable="hasFilterable"
    >
      <el-option
        v-for="item in parameterOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import parameterMixin from '@/mixins/parameterMixin';

export default {
  name: 'ParameterSelectbox',
  mixins: [parameterMixin],
  computed: {
    hasFilterable () {
      return this.parameterOptions.length > 20
    },
    parameterOptions() {
      const formParameterData = this.parameter?.FormParameterData || {}
      if (formParameterData.confDataKey) {
        const labelKey = formParameterData.labelKey || 'id'
        const valueKey = formParameterData.valueKey || 'name'
        const options = this.$store.getters.getGlobalOptions || {}
        const optionsData = options[formParameterData.confDataKey] || []
        return optionsData.map((i) => ({label: i[labelKey], value: i[valueKey]}))
      }
      return this.parameter.FormParameterData.options || []
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.el-form-item__content) {
    .el-select {
      width: 100%;
    }
  }
</style>

<template>
  <el-dropdown
    split-button
    @click="handleClick"
    @command="handleCommand"
    class="dropdown-pdf-button"
    popper-class="dropdown-pdf-options"
  >
    {{ selectedValue.label }}
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          v-for="itemAction in actions"
          :key="itemAction.id"
          :command="itemAction"
        >
          {{ itemAction.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
export default {
  name: "DropdownPdfButton",
  emits: ['on-click', 'on-change'],
  data () {
    return {
      selectedValue: {},
      actions: [
        {

          id: 1,
          label: 'Create PDF (Full Info)',
          action: 'full-info'
        },
        {
          id: 2,
          label: 'Create PDF (Folder Sticker)',
          action: 'folder-stickers'
        },
        {
          id: 3,
          label: 'Create PDF (Interviewers)',
          action: 'interviewers'
        }
      ]
    }
  },
  methods: {
    handleClick () {
      this.$emit('on-click',  this.selectedValue)
    },
    handleCommand (command) {
      this.selectedValue = command;
      this.$emit('on-change', this.selectedValue)
    }
  },
  mounted() {
    this.selectedValue = this.actions[0]
  }
}
</script>

<style lang="scss" scoped>
</style>

import parseJwt from '@/util/parseJwt'

export default {
  AUTH_TOKEN_LOCAL_STORAGE_KEY: 'auth_token',
  INVALID_TOKEN_CODE: 401,
  getAccessToken: function () {
    return localStorage.getItem(this.AUTH_TOKEN_LOCAL_STORAGE_KEY);
  },
  setAccessToken: function (token) {
    localStorage.setItem(this.AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
  },
  deleteAccessToken: function () {
    localStorage.removeItem(this.AUTH_TOKEN_LOCAL_STORAGE_KEY)
  },
  getDefaultRequestConfig: function (axiosConfig = {}) {
    const token = this.getAccessToken()
    let config = {}
    const bearerToken = 'Bearer ' + token

    if (token) {
      config = {
        headers: {
          Authorization: bearerToken
        }
      }
    }

    return Object.assign(axiosConfig, config)
  },
  getDecodedToken: function () {
    const token = this.getAccessToken()

    if (!token) {
      return false
    }

    return parseJwt(this.getAccessToken())
  }
}

<template>
  <div class="candidate-info-page px-1 pb-8" v-loading="loading">
    <div class="top-row flex justify-between items-center py-3">
      <div :class="{'not-active': !isActive }" class="user-avatar-block flex no-shrink items-center">
        <el-image
          v-if="staffInfoData.staff_pic_url"
          fit="cover"
          :src="staffInfoData.staff_pic_url"
          :preview-src-list="[staffInfoData.staff_pic_url]"
          class="mr-2 no-shrink user-avatar-image"
        >
        </el-image>
        <el-avatar v-else :size="140" class="mr-2 no-shrink" shape="square"></el-avatar>
        <div class="user-status-block flex-center">
          <template v-if="!editableStatus">
            <div class="text-md medium">
              {{ staffInfoData.name }}
            </div>
            <div class="text-md medium text-gray-700 flex items-center">
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="'Last form update: ' + getFormLastSubmitDate(staffInfoData)"
                placement="top"
              >
                <div
                  class="user-status mr-3"
                  :style="{ backgroundColor: getColorByFormProgress(staffInfoData)}"
                />
              </el-tooltip>
              {{ staffStatus.status }}
            </div>
            <div class="actions ml-4">
              <el-button circle @click="startStatusEdit">
                <i class="vc-icon-edit-pencil"></i>
              </el-button>
            </div>
          </template>
          <template v-else>
            <div v-loading="statusUpdating" class="status-select">
              <el-select v-model="newStatus" class="m-2" placeholder="Select" filterable>
                <el-option
                  v-for="item in statuses"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                >
                  <div class="flex w-full items-center">
                    {{ item.label }}
                  </div>
                </el-option>
              </el-select>
              <el-button circle type="info" @click="cancelStatusEdit">
                <i class="vc-icon-cancel"></i>
              </el-button>
              <el-button circle type="success" @click="saveNewStatus">
                <i class="vc-icon-Save"></i>
              </el-button>
            </div>
          </template>
        </div>
      </div>
      <div class="user-name">
        {{ `${staffInfoData.ename || staffInfoData.fname} ${staffInfoData.ename2 || staffInfoData.lname}` }}
      </div>
      <div class="actions-block flex h-full" style="align-self: flex-start">
       <template v-if="isActive">
         <el-button type="primary" plain @click="sendLoginData">
           Send Login Data ({{staffInfoData.sms_login_count}})
         </el-button>
         <el-popconfirm
           title="Are you sure to delete this?"
           @confirm="onDelete"
         >
           <template #reference>
               <el-button type="danger" plain>Delete</el-button>
           </template>
         </el-popconfirm>
       </template>
        <template v-else>
          <el-button type="danger" @click="onRestore">
            <i class="vc-icon-Restore icon-base mr-2"></i>
            Restore
          </el-button>
        </template>
      </div>
    </div>
    <interview-block
      :user-info="staffInfoData"
      :first-interview="firstInterview"
      :second-interview="secondInterview"
      @refresh-data="refreshData"
    >
      <template #remark>
        <div class="grow p-3 editable-block">
          <div class="label bold py-2">
            Interviews Remark
          </div>
          <el-input
            type="textarea"
            :rows="4"
            v-model="staffInfoData.remark"
          />
        </div>
        <div class="flex justify-center p-3">
          <el-button
            type="primary"
            @click="onSaveRemark"
          >
            Save
          </el-button>
        </div>
      </template>
    </interview-block>
    <positions-block
      :user-info="staffInfoData"
      @refresh-data="refreshData"
    >
      <template #remark>
        <div class=" my-4 editable-block">
          <div class="label bold py-2">
            Interviews Remark
          </div>
          <el-input
            v-model="staffInfoData.camp_remarks"
            type="textarea"
            :rows="4"
          />
        </div>
        <div class="flex justify-center">
          <el-button
            type="primary"
            @click="onSavePositionsRemark"
          >
            Save
          </el-button>
        </div>
      </template>
    </positions-block>
    <div class="forms-info-wrapper">
      <el-tabs v-model="activeTab" type="border-card">
        <el-tab-pane
          v-for="(form, i) in params"
          :key="`form-${i}`"
          :name="i"
          :label="form.FormName"
        >
          <CandidateInfoCard
            :tab-data="form"
            :candidate="staffInfoData"
            @refresh-data="refreshData"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import STATUS from '@/enum/status'
import {getFormLastSubmitDate, getColorByFormProgress} from '@/enum/formOrderHelper'
import CandidateInfoCard from "@/components/pages-components/admin/Staff/CandidateInfoCard";
import Staff from "controllers/Staff";
import InterviewBlock from "@/components/pages-components/admin/Staff/InterviewBlock";
import PositionsBlock from "@/components/pages-components/admin/Staff/PositionsBlock";
import {ElMessage} from "element-plus";

export default {
  name: "CandidateInfoTab",
  components: {PositionsBlock, InterviewBlock, CandidateInfoCard},
  props: {
    params: {
      type: [Array],
      default: () => ([])
    },
    tabData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: true,
      remark: '',
      activeTab: 0,
      staffInfoData: {},
      staffStatus: {},
      staffPositions: [],
      staffInterviews: [],
      editableStatus: false,
      newStatus: '',
      statusUpdating: false
    }
  },
  computed: {
    isActive () {
      return this.staffInfoData && +this.staffInfoData.status !== +STATUS.STAFF_STATUS.no
    },
    firstInterview () {
      const key = 'interview1_id'
      if(!this.staffInfoData) {
        return null
      }

      return this.staffInfoData[key] ? this.staffInterviews.find(i => i.interview_id === this.staffInfoData[key]) : null
    },
    secondInterview () {
      const key = 'interview2_id'
      if(!this.staffInfoData) {
        return null
      }

      return this.staffInfoData[key] ? this.staffInterviews.find(i => i.interview_id === this.staffInfoData[key]) : null
    },
    statuses () {
      return this.$store.getters.getStaffStatuses
    }
  },
  methods: {
    getFormLastSubmitDate,
    getColorByFormProgress,
    async saveNewStatus () {
     try {
       this.statusUpdating = true
       const data = {
         id: this.staffInfoData.id,
         status: this.newStatus
       }
       await this.upsertStaffData(data)
       this.staffInfoData.status = this.newStatus
       this.editableStatus = false
       this.newStatus = ''

     } catch (e) {
       console.log(e)
     } finally {
       this.statusUpdating = false
     }
    },
    cancelStatusEdit () {
      this.editableStatus = false
      this.newStatus = ''
    },
    startStatusEdit () {
      this.newStatus = +this.staffInfoData.status
      this.editableStatus = true
    },
    refreshData () {
      this.fetchInfo()
    },
    async onSavePositionsRemark () {
      const { camp_remarks, id } = this.staffInfoData
      const data = {
        camp_remarks, id
      }
      await this.upsertStaffData(data)
    },
    async onSaveRemark () {
      const { remark, id } = this.staffInfoData
      const data = {
        remark, id
      }
      await this.upsertStaffData(data)
    },
    async sendLoginData () {
      try {
        this.loading = true
        await Staff.SendFirstLogin(this.staffInfoData.id)
        const text = 'Login Data was sent!'
        ElMessage({
          showClose: true,
          message: text,
          type: 'success',
        })
        await this.fetchInfo()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async fetchInfo () {
      try {
        // { info, interviews, pos, skills, staff_status }
        const { info, staff_status, interviews, pos } = await Staff.Info(this.tabData.staffId)
        this.staffInfoData = info
        this.staffStatus = staff_status
        this.staffInterviews = interviews
        this.staffPositions = pos
      } catch (e) {
        console.log(e)
      }
    },
    async onRestore () {
        const status = STATUS.STAFF_STATUS.hold
        const data = {
          id: this.staffInfoData.id,
          status
        }
      this.staffInfoData.status = status
      await this.upsertStaffData(data)
    },
    async onDelete () {
      const status = STATUS.STAFF_STATUS.no
      const data = {
        id: this.staffInfoData.id,
        status
      }
      this.staffInfoData.status = status
      await this.upsertStaffData(data)
    },
    async upsertStaffData(formData) {
      try {
        this.loading = true
        await Staff.Upsert(formData)
        await this.refreshData()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchInfo()
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
  .candidate-info-page {
    font-size: 16px;
    .user-name {
      font-size: 36px;
      font-weight: 600;
    }
    .user-avatar-image {
      width: 140px;
      height: 140px;
      border-radius: 4px;
      background: #EBEBEB;
    }
    @media only screen and (max-width: 800px) {
      .user-name {
        margin: 12px 0;
      }
      .top-row {
        flex-wrap: wrap;
        .user-avatar-block {
          margin-bottom: 20px;
        }
      }
    }
  }
</style>

<template>
  <el-dialog
    v-model="emailModal"
    append-to-body
    fullscreen
    class="ChildEmailModal"
  >
    <template #header>
      <div class="text-center underline header-6 text-black">
        {{ childData.full_name }}
      </div>
    </template>
    <div class="flex justify-between w-full">
      <div class="form-wrapper w-full">
        <div class="email-template-form py-5">
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Select Template:
              </div>
              <el-select
                v-model="template"
                filterable
                value-key="email_template_id"
              >
                <el-option
                  v-for="(template, i) in emailTemplates"
                  :key="`t-email-${i}`"
                  :value="template"
                  :label="template.template_name || `Template ID:${template.email_template_id}`"
                ></el-option>
              </el-select>
            </div>
            <div class="form-item recipients-actions-block">
              <div class="form-label">
                Recipient:
              </div>
              <el-checkbox-group
                v-model="recipients"
              >
                <el-checkbox label="Mother" border />
                <el-checkbox label="Father" border />
                <el-checkbox label="Child" border />
              </el-checkbox-group>
            </div>
          </div>
          <div class="form-row">
            <div class="form-item mb-2">
              <div class="form-label">
                Sender:
              </div>
              <el-input
                v-model="sender"
                autocomplete="off"
              />
            </div>
            <div class="form-item mb-2">
              <div class="form-label">
                Sender Name:
              </div>
              <el-input
                v-model="senderName"
                autocomplete="off"
              />
            </div>
            <div class="form-item mb-2">
              <div class="form-label">
                Subject:
              </div>
              <el-input
                v-model="subject"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Body
              </div>
              <div>
                <html-editor
                  v-model="contentMessage"
                  :configs="{
                    language: {
                      ui: 'en',
                      content: 'he',
                      direction: 'rtl',
                    },
                    htmlSupport: {
                      allow: [
                        // Enables all HTML features, just copied from the docs
                        {
                          name: /.*/,
                          attributes: true,
                          classes: true,
                          styles: true,
                        },
                      ],
                    },
                  }"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Template Name:
              </div>
              <el-input
                v-model="template_name"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="text-black flex items-center text-md" >
            <i class="vc-icon-info text-blue icon-lg mr-2"></i>
            <span v-html="textInfo"></span>
          </div>
        </div>
        <div class="w-full">

          <div class="py-2 actions-block">
            <el-button @click="openPreviewEmail" type="success">
              <i class="vc-icon-template-list mr-2 icon-lg"></i>
              Show Preview
            </el-button>
            <el-button
              :loading="saveLoading"
              @click="saveTemplate"
              type="primary"
            >
              <i class="vc-icon-Save mr-2 icon-lg"></i>
              Save Template
            </el-button>
            <el-popconfirm
              title="Are you sure to delete this?"
              @confirm="deleteTemplate"
            >
              <template #reference>
                <el-button
                  :loading="deleteLoading"
                  :disabled="!template"
                  type="danger"
                >
                  <i class="vc-icon-recycle-bin mr-2 icon-lg"></i>
                  Delete Template
                </el-button>
              </template>
            </el-popconfirm>
            <el-button
              :loading="sendLoading"
              :disabled="!recipients.length || !contentMessage"
              @click="startEmailSending"
              type="primary"
            >
              <i class="vc-icon-skip-arrow mr-2 icon-lg"></i>
              Send Email
            </el-button>
            <el-button @click="emailModal = false" type="info">
              <i class="vc-icon-close mr-2 icon-base"></i>
              Close
            </el-button>
            <hr class="my-5 w-full">
            <div class="w-full text-black">
              <div class="bold mb-2">Selected Recipients:</div>
              <div
                v-for="item in recipients"
                :key="item"
                class="mb-1"
              >
                {{ `${item}: ${childData[EMAIL_FIELDS[item]] || 'Email field is empty'}` }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SidebarList
        :fields="dataFields"
        class="email-sidebar-fields"
      />
    </div>
  </el-dialog>
  <PreviewEmailModal
    v-model="previewVisible"
    :sender="sender"
    :subject="subject"
    :message="contentMessage"
    :object-data="previewModel"
  />
</template>

<script>
import HtmlEditor from "@/components/HtmlEditor";
import SidebarList from "@/components/pages-components/admin/Children/SidebarList";
import Messages from "controllers/Messages";
import {ElMessage} from "element-plus";
import PreviewEmailModal from "@/components/pages-components/admin/Children/PreviewEmailModal";
import mustache from "mustache/mustache";

const EMAIL_TEMPLATE = 1
const EMAIL_FIELDS = {
  Mother: 'email_mother',
  Father: 'email_father',
  Child: 'email'
}
const DEFAULT_SENDER = 'office@camp.co.il'

export default {
  name: "ChildEmailDialog",
  components: {PreviewEmailModal, SidebarList, HtmlEditor},
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    templateList: {
      type: Array,
      default: () => []
    },
    dataFields: {
      type: Array,
      default: () => []
    },
    childData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      saveLoading: false,
      template: null,
      recipients: [
        'Mother'
      ],
      EMAIL_FIELDS,
      sender: DEFAULT_SENDER,
      senderName: '',
      subject: '',
      contentMessage: '',
      template_name: '',
      previewModel: null,
      previewVisible: false,
      sendLoading: false,
      deleteLoading: false
    }
  },
  watch: {
    template (val) {
      if (val) {
        this.contentMessage = val.body || '<div>Content</div>'
        this.subject = val.email_subject || ''
        this.template_name = val.template_name
        this.senderName = val.sender_name || ''
        this.sender = val?.sender || DEFAULT_SENDER
      }
    },
    emailModal (val) {
      if (!val) {
        this.resetData()
      }
    },
  },
  computed: {
    emailTemplates () {
      return this.templateList.filter(i => i.template_type === EMAIL_TEMPLATE)
    },
    textInfo () {
      return 'Put <b>{{field_name}}</b> to body or/and subject for dynamic replace'
    },
    emailModal: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    getRecipients (childData) {
      return this.recipients.map(item => childData[EMAIL_FIELDS[item]].toString().trim()).filter(i => i)
    },
    getContentRender (template, data) {
      return mustache.render(template, data)
    },
    emailDataItem (childData) {
      const recipients = this.getRecipients(childData)
      return {
        EmailBody: this.getContentRender(this.contentMessage, childData).replaceAll('"', '\''),
        EmailSubject: this.getContentRender(this.subject, childData),
        EmailSender: this.sender,
        EmailSenderName: this.senderName,
        EmailRecipientList: recipients
      }
    },
    async startEmailSending () {
      try {
        this.sendLoading = true
        const sendData = this.emailDataItem(this.childData)
        await Messages.sendEmail(sendData)
        ElMessage({
          showClose: true,
          message: `Email to ${sendData.EmailRecipientList.join(',')} sent successful`,
          type: 'success'
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          showClose: true,
          message: `Error: ${e.message}`,
          type: 'warning'
        })
      } finally {
        this.sendLoading = false
      }
    },
    openPreviewEmail () {
      this.previewModel = this.childData
      this.previewVisible = true
    },
    async saveTemplate () {
      this.saveLoading = true
      const data = {
        body: this.contentMessage,
        form_system: 2,
        sender: this.sender,
        sender_email: this.senderName,
        sender_name: this.senderName,
        email_subject: this.subject,
        template_name: this.template_name || 'Template ' + new Date().getTime(),
        template_type: EMAIL_TEMPLATE
      }
      try {
        let message = ''
        if (this.template) {
          data.email_template_id = this.template.email_template_id
          await Messages.EmailTemplateUpdate(data)
          message = 'Email Template was updated!'
        } else {
          await Messages.EmailTemplateAdd(data)
          message = 'Email Template was added!'
        }
        ElMessage({
          showClose: true,
          message: message,
          type: 'success'
        })
        this.$emit('update:template')
      } catch (e) {
        console.error(e.message)
        ElMessage({
          showClose: true,
          message: e.message,
          type: 'warning'
        })
      } finally {
        this.saveLoading = false
      }
    },
    async deleteTemplate () {
      if (!this.template) {
        return
      }
      try {
        this.deleteLoading = true
        const { email_template_id } = this.template
        await Messages.EmailTemplateDelete(email_template_id)
        ElMessage({
          showClose: true,
          message: 'Email Template was deleted!',
          type: 'success'
        })
        this.resetData()
        this.$emit('update:template')
      } catch (e) {
        console.log(e.message)
      } finally {
        this.deleteLoading = false
      }
    },
    resetData () {
      this.subject = ''
      this.contentMessage = ''
      this.sender = DEFAULT_SENDER
      this.senderName = ''
      this.template = null
      this.template_name = ''
      this.recipients = [
        'Mother'
      ]
    }
  }
}
</script>

<style lang="scss">
@mixin formStyles {
  .form-label {
    font-size: 16px;
    color: black;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .form-row {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    flex-wrap: wrap;
    .form-item {
      flex: 1 0 20rem;
      padding: 0 8px;
      max-width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
.ChildEmailModal {
  //width: 90vw;
  //max-width: 1300px;
  .email-template-form, .actions-block {
    max-width: 960px;
    margin: 0 auto;
  }
  .actions-block {
    display: flex;
    gap: 12px 0;
    flex-wrap: wrap;
  }
  .form-wrapper {
    margin: 0 auto;
    flex: 1 0 auto;
    max-width: calc(100% - 360px);
    padding-right: 12px;
  }
  @include formStyles;
  .ck-editor__editable {
    min-height: 220px;
    max-height: 420px;
    color: black;
    font-size: 16px;
  }
}

@media only screen and (max-width: 680px) {
  .ChildEmailModal {
    width: 98vw;
    max-width: 98vw;
    .form-wrapper {
      max-width: 100%;
    }
    .recipients-actions-block {
      margin-top: 12px;
      .el-checkbox-group {
        display: flex;
        flex-direction: column;
        .el-checkbox {
          margin: 0 0 12px;
        }
      }
    }
    .actions-block {
      flex-direction: column;
      .el-button {
        width: 100%;
        margin: 0 0 8px;
      }
    }
    .email-sidebar-fields {
      display: none;
    }
  }
}
</style>

<template>
  <div class="staff-interviewers-card w-full">
    <div class="left-block relative">
      <div class="number-block">
        #{{ orderNumber }}
      </div>
      <div class="avatar-wrap flex-center">
        <el-avatar
          :src="candidate.staff_pic_url"
          :size="140"
          shape="square"
        />
      </div>
      <div class="pt-3 uppercase text-center semibold text-md">
        {{ candidateName }}
      </div>
      <div class="left-block-details">
        <div class="w-full flex items-center pt-1">
          <div class="label">Interview:</div>
          <div class="info-text">{{ `${getInterviewDate(candidate)} by ${candidate.interview2_data?.interviewer_name}` }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Cell:</div>
          <div class="info-text">{{ candidate.cell }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Email:</div>
          <div class="info-text">{{ candidate.email }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">City:</div>
          <div class="info-text">{{ candidate.city }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Birthday:</div>
          <div class="info-text">{{ `${candidate.dob}/${candidate.mob}/${candidate.yob}` }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Gender:</div>
          <div class="info-text">{{ candidate.sex }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Position:</div>
          <div class="info-text">{{ getPosition(candidate, 'pos1_data') }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Position:</div>
          <div class="info-text">{{ getPosition(candidate, 'pos2_data') }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Position:</div>
          <div class="info-text">{{ getPosition(candidate, 'pos3_data') }}</div>
        </div>
        <div class="w-full flex items-center pt-1">
          <div class="label">Remarks:</div>
          <div class="info-text remarks">{{ candidate.remark  }}</div>
        </div>
      </div>
    </div>
    <div class="right-block">
      <div class="block-title">Skills:</div>
      <div class="comments-block skills">
        <div></div>
        <div></div>
        <div></div>
        <div class="land-hide"></div>
        <div class="land-hide"></div>
      </div>
      <div class="block-title">Experience with Children:</div>
      <div class="comments-block">
        <div></div>
        <div></div>
        <div></div>
        <div class="land-hide"></div>
      </div>
      <div class="block-title">Notes:</div>
      <div class="comments-block">
        <div></div>
        <div></div>
        <div></div>
        <div class="land-hide"></div>
      </div>
      <div class="bottom-rating-block">
        <div class="w-full landscape">
          <table class="w-full">
            <tbody>
            <tr>
              <td colspan="4" class="pb-2">
                <div class="block-title">
                  Preferred Age Group
                </div>
              </td>
            </tr>
            <tr>
              <td class="w-25">6-11</td>
              <td class="w-25">12-14</td>
              <td class="w-25">14-16</td>
              <td class="w-25">Special needs</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full pb-2 landscape">
          <table class="w-full">
            <tbody>
            <tr>
              <td colspan="4" class="pb-2">
                <div class="block-title">
                  English Conversation Skills
                </div>
              </td>
            </tr>
            <tr>
              <td class="w-25">Excellent</td>
              <td class="w-25">Good</td>
              <td class="w-25">Fair</td>
              <td class="w-25">Poor</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full">
          <table class="w-full">
            <tbody>
            <tr>
              <td colspan="4" class="pb-2">
                <div class="block-title">
                  Total rating (from 1 to 10)
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="w-50">
                <table class="w-full">
                  <tr>
                    <td class="td-label">Personality:</td>
                    <td class="td-placeholder"></td>
                  </tr>
                </table>
              </td>
              <td colspan="2" class="w-50">
                <table class="w-full">
                  <tr>
                    <td class="td-label">
                      <div class="pl-2">
                        Experience:
                      </div>
                    </td>
                    <td class="td-placeholder"></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="w-50">
                <table class="w-full">
                  <tr>
                    <td class="td-label">Qualifications:</td>
                    <td class="td-placeholder"></td>
                  </tr>
                </table>
              </td>
              <td colspan="2" class="w-50">
                <table class="w-full">
                  <tr>
                    <td class="td-label">
                      <div class="pl-2">
                        Total Score:
                      </div>
                    </td>
                    <td class="td-placeholder"></td>
                  </tr>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import get from "lodash/get";
import {format} from "date-fns";
const DATE_FORMAT = 'dd MMM yyyy hh:mm a'
export default {
  name: "StaffInterviewerCard",
  props: {
    candidate: {
      type: Object,
      default: () => ({})
    },
    orderNumber: {
      type: Number,
      default: 0
    }
  },
  computed: {
    candidateName () {
      if (this.candidate.ename) {
        return `${this.candidate.ename} ${this.candidate.ename2}`
      }
      return `${this.candidate.fname} ${this.candidate.lname}`
    }
  },
  methods: {
    get,
    getInterviewDate(data) {
      const key = 'interview2_data.interview_time'
      const time = get(data, key, '')
      if (!time) {
        return '---'
      }
      return format(new Date(time), DATE_FORMAT)
    },
    getPosition (data, key) {
      const campName = get(data, `${key}.camp_name`, '')
      const position = get(data, `${key}.pos`, '')
      return position ? `${campName}_${position}` : `${campName}`
    }
  }
}
</script>
<style lang="scss" scoped>
.staff-interviewers-card {
  font-size: 16px;
  border-bottom: 1px solid #BEC2C9;
  display: flex;
  page-break-inside: avoid;
  align-items: stretch;
  padding-bottom: 1px;

  .left-block {
    background: #F5F6FA;
    padding: 12px;
    width: 42%;
    display: flex;
    flex-direction: column;
    min-width: 240px;

    .number-block {
      position: absolute;
      left: 12px;
      top: 12px;
      font-weight: 600;
      font-size: 16px;
    }

    &-details {
      font-weight: 500;
      color: black;
      font-size: 16px;
      max-width: 100%;
      word-break: break-word;

      .label {
        color: #263148;
        font-size: 14px;
        font-weight: 600;
        padding-right: 4px;
        word-break: unset;
        width: 80px;
        flex-shrink: 0;
      }
      .info-text {
        flex-grow: 1;
      }
    }
  }

  .right-block {
    padding: 12px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .block-title {
      font-weight: 600;
      color: #141a25;
      font-size: 14px;
    }

    .comments-block {
      flex-grow: 1;
      margin-bottom: 8px;

      div {
        border-bottom: 1px solid #BEC2C9;
        height: 20px;
      }
    }

    .bottom-rating-block {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      line-height: 140%;

      .title {
        font-weight: 600;
      }
      .td-label {
        width: 50%;
        padding: 2px 12px 2px 0;
      }
      .td-placeholder {
        background: #F5F6FA;
        width: 50%;
      }
    }
  }
}
@media print {
  .staff-interviewers-card {
    page-break-inside: avoid;
    page-break-after: auto;

    font-size: 12px;

    .left-block {
      padding: 10px;
      width: 320px;

      &-details {
        font-size: 14px;

        .label {
          width: 68px;
          color: black;
          font-size: 12px;
        }
      }
    }

    .right-block {
      padding: 8px;

      .block-title {
        font-size: 12px;
      }

      .comments-block {
        div {
          height: 18px;
        }
      }

      .bottom-rating-block {
        font-size: 12px;
        line-height: 140%;
        .td-label {
          padding: 2px 8px 2px 0;
        }
      }
    }

    .remarks {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
@media print and (orientation: landscape) {
  .staff-interviewers-card {
    page-break-inside: avoid;
    page-break-after: auto;
    font-size: 12px;

    .avatar-wrap .el-avatar {
      width: 100px;
      height: 100px;
    }

    .left-block {
      padding: 12px;
      width: 360px;

      &-details {
        font-size: 14px;

        .label {
          width: 80px;
        }
      }
    }

    .right-block {
      padding: 12px 12px 4px;

      .block-title {
        font-size: 12px;
      }

      .comments-block {
        div {
          height: 16px;
        }
        div.land-hide {
          display: none;
        }
      }

      .bottom-rating-block {
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 12px;
        line-height: 120%;
        .td-label {
          padding: 2px 8px 2px 0;
        }
      }

      .landscape {
        width: 50%;
      }
    }
    .remarks {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>

export default function (staff, withZero = false) {
  const updatedStaffModel = {}

  Object.entries(staff).forEach(([key, value]) => {
    if (withZero) {
      if (value !== '' && value !== undefined && value !== null && key !== 'password') {
        updatedStaffModel[key] = value
      }
    } else {
      if (value !== '' && value !== undefined && value !== null && value !== 0 && key !== 'password') {
        updatedStaffModel[key] = value
      }
    }
  })

  return updatedStaffModel
}

<template>
  <div class="wizard-wrapper">
    <div class="wizard-stepper">
      <WizardStepper
        v-if="steps.length > 1"
        :steps="steps"
        :active-index="activeIndex"
      />
    </div>
    <slot v-if="steps[activeIndex]" :name="steps[activeIndex].key" :step="steps[activeIndex]"/>
    <div class="wizard-navigation">
      <WizardNavigation
        @back="onBack"
        @next="onNext"
        @finish="onFinish"
        :steps="steps"
        :active-index="activeIndex"
      />
    </div>
  </div>
</template>

<script>
import WizardStepper from './WizardStepper'
import WizardNavigation from './WizardNavigation'

export default {
  name: 'Wizard',
  components: {
    WizardStepper,
    WizardNavigation
  },
  props: {
    stepIndex: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    },
    validate: {
      type: Function,
      default: () => new Promise(resolve => resolve())
    }
  },
  data() {
    return {
      activeIndex: -1
    }
  },
  watch: {
    stepIndex: {
      immediate: true,
      handler(newWal) {
        this.activeIndex = newWal
      }
    }
  },
  methods: {
    reset(index = 0) {
      this.activeIndex = index;
    },
    onNext() {
      this.validate()
        .then(() => {
          this.goToStep(this.activeIndex + 1)
        })
    },
    onBack() {
      this.goToStep(this.activeIndex - 1)
    },
    goToStep(index) {
      this.$emit('step-change', index)
      this.activeIndex = index;
    },
    onFinish() {
      this.validate()
        .then(() => {
          this.$emit('finish');
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div class="reports-page pb-3 px-2" v-loading.fullscreen.lock="loading">
    <div class="header-name semibold">
      {{ activeReport?.report_remarks }}
    </div>
    <div class="w-full report-filters mb-8">
      <div class="flex wrap items-center">
        <div class="filter-action pt-3">
          <div class="label-action">Report Category:</div>
          <el-select
            v-model="categoryReport"
            value-key="report_category_id"
          >
            <el-option
              v-for="category in reportList"
              :key="category.report_category_id"
              :label="category.report_category_name"
              :value="category"
            />
          </el-select>
        </div>
        <div class="filter-action pt-3">
          <div class="label-action">Report Type:</div>
          <el-select
            v-model="activeReport"
            :disabled="!categoryReport"
            value-key="report_id"
          >
            <el-option
              v-for="category in activeCategoryReports"
              :key="category.report_id"
              :label="category.report_remarks"
              :value="category"
            />
          </el-select>
        </div>
      </div>
      <div class="flex wrap items-center pt-3">
        <el-button
          v-show="allColumns.length"
          color="#626aef"
          type="primary"
          @click="show = !show"
        >
          <i class="vc-icon-filter mr-2"></i>
          Columns
        </el-button>
        <vue-excel-xlsx
          :data="excelExportData"
          :columns="excelColumns"
          :file-type="'xlsx'"
          :file-name="fileName"
          :sheet-name="sheetName"
        />
        <el-button
          :disabled="!activeReport"
          :loading="pdfProcessing"
          type="primary"
          @click="generateReport"
        >
          <i class="vc-icon-extensions mr-2"></i>
          Export to PDF
        </el-button>
        <el-button
          v-if="isGroupedActiveReport"
          :loading="wordProcessing"
          type="info"
          @click="exportToWordFile"
        >
          <i class="vc-icon-confirm-action mr-2"></i>
          Export to Word
        </el-button>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show" class="filter-columns-view_wrap">
        <div class="filter-columns-view">
          <el-checkbox-group v-model="checkboxGroup" size="small">
            <el-checkbox
              v-for="item in allColumns"
              :key="item"
              :label="item"
              border
            />
          </el-checkbox-group>
        </div>
      </div>
    </el-collapse-transition>
    <div
      v-if="activeReport"
      v-loading="reportDataLoading"
      class="report-table_wrap"
    >
      <el-table
        ref="reportTable"
        :data="reportData"
        :max-height="720"
        stripe
        empty-text="No Data!"
        class="report-table my-3 mr-2"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="36"/>
        <TableContent
          :columns="tableColumns"
        >
          <template #header="{col}">
            <table-header
              :column="col"
            />
          </template>
        </TableContent>
      </el-table>
      <HtmlToPdf
        v-if="activeReport && !isGroupedActiveReport"
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="10"
        :filename="'Camp_Report'"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-content-width="100%"
        pdf-format="a4"
        pdf-orientation="landscape"
        class="w-full"
        @hasDownloaded="hasDownloaded($event)"
      >
        <template  v-slot:pdf-content>
          <ReportsPdf
            ref="testPdfView"
            :category="categoryReport"
            :report="activeReport"
            :table-columns="tableColumns"
            :options="excelExportData"
          />
        </template>
      </HtmlToPdf>
      <HtmlToPdf
        v-if="activeReport && isGroupedActiveReport"
        ref="html2PdfGrouped"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="10"
        :filename="'Camp_Report'"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-content-width="100%"
        pdf-format="a4"
        pdf-orientation="portrait"
        class="w-full"
        @hasDownloaded="hasDownloaded($event)"
      >
        <template  v-slot:pdf-content>
          <ReportsGroupedPdf
            :category="categoryReport"
            :report-info="activeReport"
            :table-columns="tableColumns"
            :options="groupedReportData"
          />
        </template>
      </HtmlToPdf>
    </div>
  </div>
</template>

<script>
import Report from "controllers/Report";
import TableContent from "@/components/pages-components/common/Table/TableContent";
import TableHeader from "@/components/pages-components/common/Table/TableHeader";
import VueExcelXlsx from "@/components/pages-components/VueExcelXlsx";
import ReportsPdf from "@/views/admin/Pdf/ReportsPdf.vue";
import HtmlToPdf from "@/components/pages-components/HtmlToPdf.vue";
import ReportsGroupedPdf from "@/views/admin/Pdf/ReportsGroupedPdf.vue";
import {getReportsWordContent} from "@/views/admin/Pdf/getReportsWordContent";

export default {
  name: "Reports",
  components: {ReportsGroupedPdf, ReportsPdf, VueExcelXlsx, TableHeader, TableContent, HtmlToPdf},
  data: () => ({
    show: false,
    loading: true,
    reportDataLoading: false,
    reportList: [],
    categoryReport: null,
    activeReport: null,
    reportData: [],
    checkboxGroup: [],
    selectionReportData: [],
    pdfProcessing: false,
    wordProcessing: false,
    groupedReportData: []
  }),
  watch: {
    activeReport (val) {
      if (val) {
        this.getReportData()
      }
    },
    categoryReport () {
      this.activeReport = null
      this.show = false
      this.reportData = []
    }
  },
  computed: {
    isGroupedActiveReport () {
      return this.activeReport && this.activeReport.report_config?.groupByDivision
    },
    activeCategoryReports () {
      if (!this.categoryReport) {
        return []
      }
      return this.categoryReport.report_list || []
    },
    allColumns () {
      if (!this.reportData.length) {
        return []
      }
      return Object.keys(this.reportData[0])
    },
    tableColumns () {
      return this.allColumns
        .filter(i => this.checkboxGroup.includes(i))
        .map(item => ({
          prop: item,
          label: item,
          sortable: true
        }))
    },
    excelColumns () {
      return this.tableColumns.map(i => ({
        label: i.prop.toUpperCase(),
        field: i.prop
      }))
    },
    fileName () {
      const categoryName = this.categoryReport?.report_category_name || 'Report File'
      return categoryName.replaceAll(' ', '')
    },
    sheetName () {
      const name = this.activeReport?.report_remarks || 'Report'
      return name.replaceAll(' ', '')
    },
    excelExportData () {
      return this.selectionReportData.length ? this.selectionReportData : this.reportData
    }
  },
  methods: {
    exportToWordFile () {
      if (!this.activeReport) {
        return
      }
      this.wordProcessing = true
      this.getGroupedReportData(this.activeReport.report_id)
        .then((res) => {
          this.groupedReportData = [...res]
          const body = getReportsWordContent(this.categoryReport, this.activeReport, this.tableColumns, this.groupedReportData)
          this.generateWordFile('Report', body)
          this.$nextTick(() => {
            this.wordProcessing = false
          })
        })
        .catch(() => {
          this.wordProcessing = false
        })
    },
    generateWordFile (filename, body) {
      const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Test Report</title></head><body>";
      const postHtml = "</body></html>"
      const html = preHtml + body + postHtml;
      const blob = new Blob(['\ufeff', html], {
        type: 'application/msword'
      });
      const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
      const _filename = filename? filename + '.doc' : 'document.doc';
      const downloadLink = document.createElement("a")

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, _filename);
      } else {
        downloadLink.href = url;
        downloadLink.download = _filename;
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    },
    generateReport () {
      if (!this.activeReport) {
        return
      }
      this.pdfProcessing = true
      if (!this.isGroupedActiveReport) {
        this.generatePdf(this.$refs.html2Pdf)
      } else {
        this.getGroupedReportData(this.activeReport.report_id)
          .then((res) => {
            this.groupedReportData = [...res]
            this.generatePdf(this.$refs.html2PdfGrouped)
          })
          .catch(() => {
            this.pdfProcessing = false
          })
      }
    },
    generatePdf (refInstance) {
      if (!refInstance) {
        this.pdfProcessing = false
        return
      }
      try {
        this.$nextTick(() => {
          refInstance.generatePdf()
        })
      } catch (e) {
        console.error(e)
        this.pdfProcessing = false
      }
    },
    hasDownloaded () {
      this.pdfProcessing = false
    },
    handleSelectionChange(val) {
      this.selectionReportData = val
    },
    async getGroupedReportData (reportId) {
        return await Report.groupedData(reportId)
    },
    async getReportsAll () {
      try {
        this.loading = true
        this.reportList = await Report.List() || []
      } finally {
        this.loading = false
      }
    },
    async getReportData () {
      if (!this.activeReport) {
        this.reportData = []
        return
      }
      try {
        this.reportData = []
        this.show = false
        this.reportDataLoading = true
        this.reportData = await Report.Data(this.activeReport.report_id)
        this.checkboxGroup = this.allColumns
      } finally {
        this.reportDataLoading = false
      }
    },
    print () {
      window.print()
    }
  },
  mounted () {
    this.getReportsAll()
  }
}
</script>

<style lang="scss">
@import './src/styles/mixins';
.reports-page {
  .header-name {
    display: none;
  }
  .report-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .filter-action {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  .label-action {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .report-table {
    @extend %tableBaseStyle;
    th .cell {
      padding-bottom: 0;
      color: black;
    }
  }
  .filter-columns-view {
    border: 1px solid #ebeef5;
    padding: 12px;
    border-radius: 12px;
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 8px 8px 0;
      }
    }
  }
}

@media print {
  .reports-page {
    -webkit-print-color-adjust: exact !important;
    .header-name {
      display: block;
    }
    .report-filters {
      display: none;
    }
    .report-table_wrap {
      width: max-content;
      max-width: max-content;
      overflow: visible;
    }
    .filter-columns-view {
      display: none;
    }
    table.report-table {
      width: 100%;
      font-size: 12px !important;
      page-break-inside: auto;
      table-layout: auto;
      &:not(:first-child) {
        margin-top: 0;
      }

      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      thead {
        display: table-header-group;
      }
      .cell {
        padding: 2px;
        font-size: 12px !important;
        word-break: keep-all;
        white-space: nowrap;
      }
      tr {
        td:first-child, th:first-child {
          display: none;
        }
      }
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}
</style>

<template>
  <div class="flip-box">
    <div
      v-loading="loading"
      :class="{'flipped': hasFlipped}"
      class="flip-card">
      <div class="flip-card-front">
        <InterviewCard :background-color="final ? '#fff': undefined">
          <template #content>
            <div v-if="!hasFlipped" class="form-item">
              <div class="label bold py-2">
                {{ label }}
              </div>
              <el-select v-model="model" filterable>
                <el-option
                  v-for="i in options"
                  :key="i.id"
                  :value="i.id"
                  :label="`${i.camp_name}_${i.pos}`"
                />
              </el-select>
            </div>
          </template>
          <template #actions>
            <el-button type="primary" @click="onSave">
              Save
            </el-button>
          </template>
        </InterviewCard>
      </div>
      <div class="flip-card-back">
        <InterviewCard :background-color="final ? '#fff': undefined">
          <template #content>
            <div class="text-center" v-html="positionContent" />
          </template>
          <template #actions>
            <el-button type="primary" plain @click="onEdit">
              Edit
            </el-button>
          </template>
        </InterviewCard>
      </div>
    </div>
  </div>
</template>

<script>
import InterviewCard from "@/components/pages-components/common/Cards/InterviewCard";
export default {
  name: "PositionFlipCard",
  components: {InterviewCard},
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    final: {
      type: Boolean,
      default: false
    },
    current: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      isEdit: false,
      model: ''
    }
  },
  watch: {
    current(val) {
      this.model = +val
    }
  },
  computed: {
    options () {
      return this.$store.getters.getStaffPositions || []
    },
    currentPosition () {
      if (!this.current || !this.options) {
        return ''
      }
      return this.options.find(i => i.id === +this.current)
    },
    positionContent () {
      if(!this.currentPosition) {
        return ''
      }
      return `${this.currentPosition.camp_name}_${this.currentPosition.pos}`
    },
    hasFlipped () {
      return this.currentPosition && !this.isEdit
    }
  },
  methods: {
    onSave () {
      this.isEdit = false
      this.loading = true
      this.$emit('set-position', {
        id: this.model,
        callback: () => {
          this.loading = false
        }
      })
    },
    onEdit () {
      this.isEdit = true
    }
  },
  created() {
    this.model = +this.current ? +this.current : ''
  }
}
</script>

<style lang="scss" scoped>
.flip-box {
  background-color: transparent;
  width: 100%;
  height: 224px;
  perspective: 1000px;

  .flip-card {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    &.flipped {
      transform: rotateY(180deg);
    }

    ::v-deep(.form-item) {
      width: 100%;

      .el-select {
        width: 100%;
      }
    }

    .label {
      text-align: left;
    }

    .flip-card-front, .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 4px;
    }
    .flip-card-back {
      transform: rotateY(180deg);
    }
  }
}
</style>

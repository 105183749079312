<template>
  <div class="admin-template h-full">
    <el-container class="container-page">
      <el-header height="60px" class="admin-nav-header">
        <Header />
      </el-header>
      <el-container>
        <Sidebar />
        <el-main class="main-content">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from "@/components/layout-components/admin/Header";
import Sidebar from "@/components/layout-components/admin/Sidebar";
export default {
  name: "AdminLayout",
  components: {Sidebar, Header}
}
</script>

<style lang="scss" scoped>
  .admin-template {
    .container-page {
      height: 100%;
    }
    .admin-nav-header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: white;
      padding: 4px 90px;
      box-sizing: border-box;
      border-bottom: 1px solid #E0E2E6;
      z-index: var(--z-navbar);
    }
    .main-content {
      --el-main-padding: 5px;
      margin-top: var(--el-header-height);
      padding-left: calc(var(--el-sidebar-width) + 20px);
      background: #F7FAFC;
      transition: all .3s linear;
    }

    @media print {
      .admin-nav-header {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 780px) {
    ::v-deep(.navbar .sidebar-logo svg) {
      max-height: 40px;
    }
    .admin-template {
      .admin-nav-header {
        padding: 4px 12px;
        height: 62px;
      }
      .main-content {
        padding-left: 12px;
      }
    }
  }
</style>

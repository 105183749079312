<template>
  <div class="navigation-wrapper">
    <el-button
      color="#BEC2C9"
      type="primary"
      :disabled="onFirstStep"
      size="large"
      plain
      @click="onBack"
    >
      Back
    </el-button>

    <el-button
      v-if="!onLastStep"
      color="#20ACE2"
      type="primary"
      size="large"
      @click="onNext"
    >
      Next
    </el-button>
    <el-button
      v-else
      color="#20ACE2"
      type="primary"
      size="large"
      @click="onFinish"
    >
      Finish
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'WizardNavigation',
  props: {
    activeIndex: {
      type: Number,
      default: -1
    },
    steps: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    onFirstStep() {
      return this.activeIndex === 0;
    },
    onLastStep() {
      return this.activeIndex + 1 === this.steps.length
    }
  },
  methods: {
    onBack() {
      this.$emit('back')
    },
    onNext() {
      this.$emit('next')
    },
    onFinish() {
      this.$emit('finish')
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-wrapper {
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 960px) {
  .navigation-wrapper {
    padding: 0;
  }
}
</style>

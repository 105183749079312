<template>
  <el-dialog
    v-model="modalVisible"
    top="4vh"
    width="70vw"
  >
    <template #header>
      <div class="flex w-full">
        <div>New Receipt</div>
      </div>
      <div class="w-full px-3 text-center">
        <div class="header-5 bold">
          {{ infoData.fullName }}
        </div>
        <div class="text-md text-black">
          {{ `Round: ${infoData.round}. Camp: ${infoData.campName || ''}` }}
        </div>
      </div>
    </template>
    <div class="new-receipt-form__wrap">
      <el-form
        v-loading="loading"
        ref="newReceiptForm"
        :model="newReceiptModel"
        label-position="top"
        class="new-receipt-form"
      >
        <div class="flex w-full wrap">
          <div class="form-field">
            <el-form-item
              prop="children_receipt_date"
              label="Receipt Date"
            >
              <el-date-picker
                v-model="newReceiptModel.children_receipt_date"
                type="date"
                placeholder="Pick a day"
                value-format="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD"
              />
            </el-form-item>
          </div>
          <div class="form-field">
            <el-form-item
              prop="children_receipt_type_id"
              label="Receipt Type"
            >
              <el-select
                v-model="newReceiptModel.children_receipt_type_id"
              >
                <el-option
                  v-for="option in receiptTypes"
                  :key="option.children_receipt_type_id"
                  :value="option.children_receipt_type_id"
                  :label="option.children_receipt_type_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-field">
            <el-form-item
              label="Receipt Price"
            >
              <el-input
                v-model="priceFieldValue"
                autocomplete="off"
              >
                <template v-if="isReceiptPriceNegative" #prepend>-</template>
              </el-input>
            </el-form-item>
          </div>
            <div class="form-field">
                <el-form-item
                        prop="children_receipt_currency_id"
                        label="Currency"
                >
                <el-radio-group v-model="newReceiptModel.children_receipt_currency_id" @change="setDefaultRates">
                    <el-radio-button :label="CURRENCY_ILS">ILS</el-radio-button>
                    <el-radio-button :label="CURRENCY_USD">USD</el-radio-button>
                </el-radio-group>
                </el-form-item>
            </div>
          <div class="form-field">
            <el-form-item
              v-if="newReceiptModel.children_receipt_currency_id === CURRENCY_ILS"
              label="Currency Rate ($)"
            >
              <el-input-number
                v-model.number="dollarRateModel"
                :min="0.0"
                :controls="false"
                controls-position="right"
              />
            </el-form-item>
            <el-form-item
              v-if="newReceiptModel.children_receipt_currency_id === CURRENCY_USD"
              label="Currency Rate (₪)"
            >
              <el-input-number
                v-model.number="originalRateModel"
                :min="0.0"
                :controls="false"
                controls-position="right"
              />
            </el-form-item>
          </div>
        </div>
        <div class="flex w-full">
          <div class="form-field">
            <el-form-item
              prop="children_receipt_description"
              label="Receipt Description"
            >
              <el-input
                v-model="newReceiptModel.children_receipt_description"
                autocomplete="off"
              />
            </el-form-item>
          </div>
        </div>
        <div class="flex w-full">
          <div class="form-field">
            <el-form-item
              prop="children_receipt_remarks"
              label="Receipt Remarks"
            >
              <el-input
                v-model="newReceiptModel.children_receipt_remarks"
                autocomplete="off"
              />
            </el-form-item>
          </div>
        </div>
        <el-divider content-position="left">
          <span class="semibold">Payment Data</span>
        </el-divider>
        <div class="payment-data-block">
          <div class="flex w-full wrap">
            <div class="form-field">
              <el-form-item
                prop="children_receipt_payment_type_id"
                label="Payment Type"
              >
                <el-select
                  v-model="newReceiptModel.children_receipt_payment_type_id"
                >
                  <el-option
                    v-for="option in receiptPaymentTypes"
                    :key="option.payment_type_id"
                    :value="option.payment_type_id"
                    :label="option.payment_type_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-field">
              <el-form-item
                prop="payment_cardType"
                label="Card Type"
              >
                <el-select
                  v-model="newReceiptModel.payment_cardType"
                >
                  <el-option
                    v-for="option in paymentCardTypes"
                    :key="option.payment_card_id"
                    :value="option.payment_card_id"
                    :label="option.payment_card_name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="flex w-full wrap">
            <div class="form-field">
              <el-form-item
                prop="payment_cardNum"
                label="Card Number"
              >
                <el-input v-model="newReceiptModel.payment_cardNum" />
              </el-form-item>
            </div>
            <div class="form-field">
              <el-form-item
                prop="payment_bankName"
                label="Bank Name"
              >
                <el-input v-model="newReceiptModel.payment_bankName" />
              </el-form-item>
            </div>
            <div class="form-field">
              <el-form-item
                prop="payment_bankBranch"
                label="Bank Branch"
              >
                <el-input v-model="newReceiptModel.payment_bankBranch" />
              </el-form-item>
            </div>
            <div class="form-field">
              <el-form-item
                prop="payment_bankAccount"
                label="Bank Account"
              >
                <el-input v-model="newReceiptModel.payment_bankAccount" />
              </el-form-item>
            </div>
            <div class="form-field">
              <el-form-item
                prop="payment_chequeNum"
                label="Cheque Number"
              >
                <el-input v-model="newReceiptModel.payment_chequeNum" />
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer flex justify-between">
        <div class="flex-grow">
          Rates: {{ `${nisInfo.currency_original_value} / ${nisInfo.currency_dolar_rate}` }}
        </div>
        <div>
          <el-button @click="onClose">
            <i class="vc-icon-cancel icon-base mr-2"></i>
            Cancel
          </el-button>
          <el-button
            :loading="loading"
            type="primary"
            @click="onSave"
          >
            <i class="vc-icon-Save icon-base mr-2"></i>
            Save
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {format} from "date-fns";
import {
    RECEIPT_MODEL,
    DATE_FORMAT,
    NEGATIVE_RECIPE_TYPE_LIST,
    CURRENCY_USD,
    CURRENCY_ILS
} from "@/enum/bilingTableColumns";

export default {
  name: "NewReceiptModal",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    infoData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    configs: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue', 'close', 'save'],
  data () {
    return {
      CURRENCY_ILS,
      CURRENCY_USD,
      newReceiptModel: {
        ...RECEIPT_MODEL,
        children_receipt_date: format(new Date(), DATE_FORMAT)
      },
      priceFieldValue: 0,
      dollarRateModel: 0,
      originalRateModel: 0
    }
  },
  watch: {
    'newReceiptModel.children_receipt_type_id': function (newValue) {
      if (NEGATIVE_RECIPE_TYPE_LIST.includes(newValue)) {
        this.newReceiptModel.children_receipt_price_nis = (-Math.abs(Number(this.newReceiptModel.children_receipt_price_nis))).toString();
      } else {
        this.newReceiptModel.children_receipt_price_nis = Math.abs(Number(this.newReceiptModel.children_receipt_price_nis)).toString();
      }
    },
    modelValue: {
      immediate: true,
      handler() {
        this.setDefaultRates()
      }
    }
  },
  computed: {
    modalVisible: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    isReceiptPriceNegative () {
      return NEGATIVE_RECIPE_TYPE_LIST.includes(this.newReceiptModel.children_receipt_type_id)
    },
    childrenReceiptPriceModel: {
      get() {
        // always return positive value
        return Math.abs(Number(this.newReceiptModel.children_receipt_price_nis)).toString();
      },
      set(value) {
        // Check if value is a valid number before proceeding
        if (isNaN(Number(value))) return;

        // adjust sign of value based on isReceiptPriceNegative
        this.newReceiptModel.children_receipt_price_nis = this.isReceiptPriceNegative
          ? (-Math.abs(Number(value))).toString()
          : Math.abs(Number(value)).toString();
      },
    },
    receiptTypes () {
      return this.configs.ReceiptTypeList || []
    },
    receiptPaymentTypes () {
      return this.configs.PaymentTypeList || []
    },
    paymentCardTypes () {
      return this.configs.PaymentCardTypeList || []
    },
    nisInfo () {
      return this.configs.NISInfo || {}
    }
  },
  methods: {
    setDefaultRates () {
      this.originalRateModel = this.nisInfo.currency_original_value || 1
      this.dollarRateModel = this.nisInfo.currency_dolar_rate || 1
    },
    onSave () {
      const _originalRate = this.originalRateModel
      const _dollarRate = this.dollarRateModel
      const _price = +this.priceFieldValue || 0
      if (this.newReceiptModel.children_receipt_currency_id === this.CURRENCY_USD) {
        this.newReceiptModel.children_receipt_price_nis = _price * _originalRate
        this.newReceiptModel.children_receipt_price_usd = _price
        this.newReceiptModel.children_receipt_currency_rate = _originalRate
      } else {
        this.newReceiptModel.children_receipt_price_nis = _price
        this.newReceiptModel.children_receipt_price_usd = _price / _dollarRate
        this.newReceiptModel.children_receipt_currency_rate = _dollarRate
      }
      this.$emit('save', {...this.newReceiptModel})
    },
    onClose () {
      this.$emit('close')
    },
    resetReceiptData () {
        this.priceFieldValue = 0
      this.newReceiptModel = {...RECEIPT_MODEL, children_receipt_date: format(new Date(), DATE_FORMAT) }
    }
  }
}
</script>

<template>
  <el-card class="interview-block mb-8">
    <div class="interview-block-content">
      <interview-flip-card
        :user-info="userInfo"
        :interviewData="firstInterview"
        :options="optionsFirst"
        :type="1"
        label="First Interview"
        @save="onSetInterview"
        @send-email="onSendEmail(emailTemplates.first, firstInterview.interview_time, $event)"
        @send-sms="onSendSms(smsTemplates.first, firstInterview.interview_time, $event)"
      />
      <interview-flip-card
        :user-info="userInfo"
        :interviewData="secondInterview"
        :options="optionsSecond"
        :type="2"
        label="Second Interview"
        @save="onSetInterview"
        @send-email="onSendEmail(emailTemplates.second, secondInterview.interview_time, $event)"
        @send-sms="onSendSms(smsTemplates.second, secondInterview.interview_time, $event)"
      />
      <div class="remark-card">
        <slot name="remark"></slot>
      </div>
    </div>
  </el-card>
</template>

<script>
import InterviewFlipCard from "@/components/pages-components/common/Cards/InterviewFlipCard";
import Staff from "controllers/Staff";
import Messages from "controllers/Messages";
import mustache from 'mustache'
import SmsTemplate from '@/enum/SmsTemplate.json'
import { format } from 'date-fns'

const FIRST_INTERVIEW_TEMPLATE_ID = process.env.VUE_APP_FIRST_INTERVIEW_EMAIL_TEMPLATE_ID
const SECOND_INTERVIEW_TEMPLATE_ID = process.env.VUE_APP_SECOND_INTERVIEW_EMAIL_TEMPLATE_ID
const FIRST_INTERVIEW_SMS_TEMPLATE_ID = process.env.VUE_APP_FIRST_INTERVIEW_SMS_TEMPLATE_ID
const SECOND_INTERVIEW_SMS_TEMPLATE_ID = process.env.VUE_APP_SECOND_INTERVIEW_SMS_TEMPLATE_ID
const FORM_SYSTEM = 1

export default {
  name: "InterviewBlock",
  components: {InterviewFlipCard},
  props: {
    interviews: {
      type: Array,
      default: () => []
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    firstInterview: {
      type: Object,
      default: undefined
    },
    secondInterview: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      emailTemplates: {
        first: null,
        second: null
      },
      smsTemplates: {
        first: SmsTemplate,
        second: SmsTemplate
      }
    }
  },
  computed: {
    optionsFirst () {
      return this.options.filter(i => i.interview_type_id === 1)
    },
    optionsSecond () {
      return this.options.filter(i => i.interview_type_id === 2)
    },
    options () {
      return this.$store.getters.getStaffInterviews
    }
  },
  methods: {
    getMustacheRenderData (params) {
      return {
        ...this.userInfo,
        interview_date: format(new Date(params.interview_date), 'dd MMM yyyy hh:mm a'),
        first_interview_date: format(new Date(params.interview_date), 'dd MMM yyyy hh:mm a'),
        interview1_date: format(new Date(params.interview_date), 'dd MMM yyyy hh:mm a'),
        interview2_date: format(new Date(params.interview_date), 'dd MMM yyyy hh:mm a'),
      }
    },
    async onSendSms (smsTemplate, interview_date, data) {
      try {
        const toRender = this.getMustacheRenderData({ interview_date })

        const body = {
          message: mustache.render(smsTemplate.body, toRender),
          sender: mustache.render(smsTemplate.sender_name, toRender),
          staff_id_list: [this.userInfo.id],
          type: `interview_${data.interview_type}`
        }

        await Staff.BulkSendSMS(body)
        this.$emit('refresh-data')
      } catch (e) {
        console.log(e)
      } finally {
        data.callback()
      }
    },
    async onSendEmail (emailTemplate, interview_date, data) {
      try {
        if (!emailTemplate) return

        const toRender = this.getMustacheRenderData({ interview_date })

        const body = {
          text: mustache.render(emailTemplate.body, toRender),
          subject: mustache.render(emailTemplate.email_subject, toRender),
          staff_id_list: [this.userInfo.id],
          sender: emailTemplate.sender,
          type: `interview_${data.interview_type}`
        }

        await Staff.BulkSendEmail(body)
        this.$emit('refresh-data')
      } catch (e) {
        console.log(e)
      } finally {
        data.callback()
      }
    },
    async onSetInterview (interview) {
      const field = `interview${interview.interview_type}_id`
      const data = {
        id: this.userInfo.id,
        [field]: interview.interview_id
      }
      try {
        await Staff.Upsert(data)
        this.$emit('refresh-data')
      } finally {
        interview.callback()
      }
    },
    loadInterviewTemplates () {
      Messages.getEmailTemplateList(FORM_SYSTEM)
        .then(res => {
          res.forEach((template) => {
            switch (String(template.email_template_id)) {
              case FIRST_INTERVIEW_TEMPLATE_ID:
                this.emailTemplates.first = template
                break
              case SECOND_INTERVIEW_TEMPLATE_ID:
                this.emailTemplates.second = template
                break
              case FIRST_INTERVIEW_SMS_TEMPLATE_ID:
                this.smsTemplates.first = template
                break
              case SECOND_INTERVIEW_SMS_TEMPLATE_ID:
                this.smsTemplates.second = template
            }
          })
      })
    }
  },
  created() {
    this.loadInterviewTemplates()
  }
}
</script>

<style lang="scss">
  .interview-block {
    .interview-block-content {
      display: grid;
      gap: 36px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      .remark-card {
        grid-column: span 2 / span 2;
        outline: none;
      }
    }
    @media only screen and (max-width: 1600px) {
      .interview-block-content {
        gap: 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
    @media only screen and (max-width: 1460px) {
      .interview-block-content {
        gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    @media only screen and (max-width: 1120px) {
      .interview-block-content {
        gap: 20px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
    @media only screen and (max-width: 800px) {
      .interview-block-content {
        gap: 20px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        .remark-card {
          grid-column: span 1;
        }
      }
    }
  }
</style>

<template>
  <el-dialog
    v-model="model"
    append-to-body
    class="ChildrenEmailPreviewModal"
  >
    <div class="email-preview-body text-md text-black">
      <div class="text-md pb-3">
        <span class="text-md semibold mr-2">Sender:</span>
        <span>{{ sender }}</span>
      </div>
      <div class="text-md pb-3">
        <span class="text-md semibold mr-2">Subject:</span>
        <span>{{ getSubjectContent }}</span>
      </div>
      <el-divider content-position="left">
        <div class="semibold">Body:</div>
      </el-divider>
      <div class="mt-3" v-html="getBodyContent"></div>
    </div>
  </el-dialog>
</template>

<script>
import mustache from 'mustache'

export default {
  name: "PreviewEmailModal",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    objectData: {
      type: Object,
      default: () => ({})
    },
    subject: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    sender: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    getBodyContent () {
      if (!this.objectData) {
        return ''
      }

      return mustache.render(this.message, this.objectData)
    },
    getSubjectContent () {
      if (!this.objectData) {
        return ''
      }
      return mustache.render(this.subject, this.objectData)
    }
  }
}
</script>

<style>
.ChildrenEmailPreviewModal {
  min-width: 380px;
  width: 60vw;
  max-width: 90vw;
}
</style>

import {instanceCookie} from '@/api/axios'

const prefix = '/Skill'

export default {
  async ConfData() {
    const { data } = await instanceCookie.post(`${prefix}/ConfData/`);

    return data.data
  }
}

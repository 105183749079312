<template>
  <div v-if="column.searchable" class="search-wrap" @click.stop>
    <el-input
      v-model="searchModel[column.prop]"
      autocomplete="off"
      :name="`filter-by-${column.prop}`"
      clearable @input="onSearchData(column.prop, $event)"/>
  </div>
  <div v-else-if="column.filter" class="search-wrap" @click.stop>
    <el-select
      v-model="searchModel[column.filterKey || column.prop]"
      :multiple="column.isMultipleSelect"
      :collapse-tags="column.isMultipleSelect"
      :filterable="!column.isMultipleSelect"
      clearable
      @change="onSearchData(column.filterKey || column.prop, $event)"
    >
      <el-option label="All" value=""></el-option>
      <el-option
        v-for="option in column.filter"
        :key="`option-${option.value}`"
        :label="option.label"
        :value="option.value"
      />
    </el-select>
  </div>
  <div v-else-if="column.datetime" class="search-wrap" @click.stop>
    <el-date-picker
      class="date-picker"
      v-model="searchModel[column.prop]"
      @change="onSearchData(column.prop, $event)"
      :value-format="column.dateFormat || 'DD MMM YYYY'"
    />
  </div>
  <div class="inline-flex items-center uppercase pr-1 base-cell min-w-max relative"
       style="min-height: 20px">
    <i v-if="column.icon" :class="column.icon" class="icon-md mr-1"/>
    {{ column.label }}
  </div>
</template>

<script>
export default {
  name: "TableHeader",
  props: {
    column: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: [Object, Array],
      default: () => ({})
    }
  },
  emits: ['onSearch'],
  computed: {
    searchModel: {
      get () {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    onSearchData(data, event) {
      this.$emit('onSearch', data, event)
    }
  }
}
</script>

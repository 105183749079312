<template>
  <div class="admin-main-page">
    No permission
   <div class="py-3 flex-center w-full">
     <router-link to="/admin" class="text-md">
       Home
     </router-link>
   </div>
  </div>
</template>

<script>
export default {
  name: 'Main',
  computed: {
    userType () {
      return this.$store.getters.getUserType
    },
    isAdmin () {
      return this.$store.getters.isUserAdmin
    },
    isManager () {
      return this.$store.getters.isUserManager
    }
  }
  // async mounted() {
  //   if(!this.userType) {
  //     await this.$store.dispatch('fetchStaffConfigs')
  //   }
  //   if (this.isAdmin) {
  //     await this.$router.push({name: 'Admin'})
  //   } else if (this.isManager) {
  //     await this.$router.push({name: 'CampManager'})
  //   }
  // }
}
</script>

<style scoped>
.admin-main-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-size: 40px;
}
</style>

<template>
  <div class="py-3 px-2 summarize-page" v-loading="loading">
    <h1>
      sum candidate status OK: {{ sum_candidate_status_OK }}
    </h1>
    <div class="tables-wrapper">
      <div class="summarize-table-block">
        <h1>
          sum candidate per interviewer
        </h1>
        <el-table
          ref="baseTable"
          :data="tableData.sum_candidate_per_interviewer"
          empty-text="No Data!"
          class="summarize-table my-3"
        >
          <template v-for="(column, i) in columns.sum_candidate_per_interviewer" :key="`col-${i}`">
            <el-table-column
              :min-width="column.minWidth || '150px'"
              :width="column.width"
              :column-key="column.prop"
              :type="column.type"
              :prop="column.prop"
            >
              <template #header>
                <div class="inline-flex items-center uppercase pr-1 base-cell min-w-max relative">
                  <i v-if="column.icon" :class="column.icon" class="icon-md mr-1"/>
                  {{ column.label }}
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <div class="summarize-table-block">
        <h1>
          sum interviews per day
        </h1>
        <el-table
          ref="baseTable"
          :data="tableData.sum_interviews_per_day"
          empty-text="No Data!"
          class="summarize-table my-3"
        >
          <template v-for="(column, i) in columns.sum_interviews_per_day" :key="`col-${i}`">
            <el-table-column
              :min-width="column.minWidth || '150px'"
              :width="column.width"
              :column-key="column.prop"
              :type="column.type"
              :prop="column.prop"
            >
              <template #header>
                <div class="inline-flex items-center uppercase pr-1 base-cell min-w-max relative">
                  <i v-if="column.icon" :class="column.icon" class="icon-md mr-1"/>
                  {{ column.label }}
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import StaffController from 'controllers/Staff'
import {format} from 'date-fns'

const DATE_FORMAT = 'dd MMM yyyy hh:mm a'

export default {
  name: 'Summarize',
  data() {
    return {
      tableData: {
        sum_candidate_per_interviewer: [],
        sum_interviews_per_day: []
      },
      sum_candidate_status_OK: 0,
      loading: false
    }
  },
  computed: {
    columns() {
      return {
        sum_candidate_per_interviewer: [
          {
            prop: 'interviewer_name',
            label: 'Interviewer Name',
            icon: 'vc-icon-users',
            sortable: true
          },
          {
            prop: 'interviewe_date',
            label: 'Interview Date',
            icon: 'vc-icon-schedule-calendar',
            sortable: true
          },
          {
            prop: 'candidate_number',
            label: 'Candidate Number',
            icon: 'vc-icon-ID_number',
            sortable: true
          }
        ],
        sum_interviews_per_day: [
          {
            prop: 'interviewe_date',
            label: 'Interview Date',
            icon: 'vc-icon-schedule-calendar',
            sortable: true
          },
          {
            prop: 'interviewes_number',
            label: 'Interviews Number',
            icon: 'vc-icon-ID_number',
            sortable: true
          }
        ]
      }
    }
  },
  methods: {
    async getSummarizeData() {
      this.loading = true

      try {
        const {Data} = await StaffController.SummarizeBox()

        this.sum_candidate_status_OK = Data.sum_candidate_status_OK

        this.tableData.sum_candidate_per_interviewer = Data.sum_candidate_per_interviewer.map(el => {
          return {
            ...el,
            interviewe_date: format(new Date(el.interviewe_date), DATE_FORMAT)
          }
        })

        this.tableData.sum_interviews_per_day = Data.sum_interviews_per_day.map(el => {
          return {
            ...el,
            interviewe_date: format(new Date(el.interviewe_date), DATE_FORMAT)
          }
        })

      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  created() {
    this.getSummarizeData()
  }
}
</script>

<style lang="scss">
.summarize-page {
  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .tables-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .summarize-table-block {
    width: 48%;
  }

  .summarize-table {
    font-size: 16px;

    .cell {
      display: inline-flex !important;
      align-items: center;
    }

    th.el-table-column--selection .cell {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 12px;
    }

    th .cell {
      color: #20ACE2;
      font-weight: 700;
      font-size: 14px;
      position: relative;

      .search-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 0 4px;

        .el-date-editor.el-input, .el-date-editor.el-input__inner {
          width: 100%;
        }
      }
    }

    td .cell {
      width: 100%;
    }
  }
}

@media screen and (max-width: 960px) {
  .summarize-page {
    .tables-wrapper {
      display: block;
    }

    .summarize-table-block {
      width: 100%;
    }
  }
}
</style>

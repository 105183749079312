import { createApp } from 'vue'
import { store } from './store'
import './styles/index.scss'
import './styles/icon-vc.css'
// import 'element-plus/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'

createApp(App)
  .use(router)
  .use(ElementPlus)
  .use(store)
  .mount('#app')

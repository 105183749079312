<template>
  <el-card class="candidate-card">
    <template #header>
      <div class="flex justify-between items-center card-navbar">
        <div class="flex">
          <el-popconfirm
            icon-color="#B75151"
            title="You are about to delete this candidate, are you sure?"
            @confirm="onDelete"
          >
            <template #reference>
              <el-button
                color="#B75151"
                type="primary"
                size="large"
              >
                Delete
              </el-button>
            </template>
          </el-popconfirm>
          <el-button
            v-if="candidateInfo.staff_id"
            color="#20ACE2"
            type="primary"
            size="large"
            @click="onSendLoginDetails"
          >
            Send Login Details
          </el-button>
          <el-button
            v-else
            color="#20ACE2"
            type="primary"
            size="large"
            @click="onInterview"
          >
            Interview
          </el-button>
        </div>
        <span class="text-md">
          Applied: {{ format(new Date(candidateInfo.applied), 'dd MMM yyyy hh:mm a') }}
        </span>
        <span class="name">
          {{ candidateInfo.fname + ' ' + candidateInfo.lname }} <b>({{ candidateInfo.id }})</b>
        </span>
      </div>
    </template>
    <div>
      <div class="row flex">
        <div class="column w-25">
          <p class="label">
            מין
          </p>
          <p class="value">
            {{ candidateInfo.sex }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            שם פרטי
          </p>
          <p class="value">
            {{ candidateInfo.fname }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            שם משפחה
          </p>
          <p class="value">
            {{ candidateInfo.lname }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            תארין לידה
          </p>
          <p class="value">
            {{ candidateInfo.birth }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-25">
          <p class="label">
            טלפון
          </p>
          <p class="value">
            {{ candidateInfo.phone }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            פלאפון
          </p>
          <p class="value">
            <a :href="`tel:${candidateInfo.cell}`" class="text-black">
              {{ candidateInfo.cell }}
            </a>
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            אימייל
          </p>
          <p class="value">
            {{ candidateInfo.email }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-25">
          <p class="label">
            רחוב
          </p>
          <p class="value">
            {{ candidateInfo.street_name }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            מספר דירה / בית
          </p>
          <p class="value">
            {{ candidateInfo.num_home }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            עיר מגורים
          </p>
          <p class="value">
            {{ candidateInfo.city_name }}
          </p>
        </div>
      </div>
      <p class="block-heading">Education and military service</p>
      <div class="row flex">
        <div class="column w-25">
          <p class="label">
            תעודת בגרות
          </p>
          <p class="value">
            {{ candidateInfo.tb }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            תאריך שחרור
          </p>
          <p class="value">
            {{ candidateInfo.released }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            שירות
          </p>
          <p class="value">
            {{ candidateInfo.army }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            משך זמן השירות
          </p>
          <p class="value">
            {{ candidateInfo.army_years }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-full">
          <p class="label">
            במידה ולא אנא פרט/י מדוע
          </p>
          <p class="value">
            {{ candidateInfo.army_why_not }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-full">
          <p class="label">
            תיאור התפקיד
          </p>
          <p class="value">
            {{ candidateInfo.army_jobs }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-25">
          <p class="label">
            שירתתי בתור
          </p>
          <p class="value">
            {{ candidateInfo.army_as }}
          </p>
        </div>
        <div class="column w-25">
          <p class="label">
            האם היית מדריך בתנועת נוער
          </p>
          <p class="value">
            {{ candidateInfo.guide }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-full">
          <p class="label">
            האם יש לך תחום שהנך טוב/ה בו ויש לך ניסיון? אנא סמנ/י
          </p>
          <p class="value">
            {{ candidateInfo.specialty }}
          </p>
        </div>
      </div>
      <template v-if="candidateInfo?.skill_list?.length">
        <p class="block-heading">
          Skills
        </p>
        <template
          v-for="(skill, index) in candidateInfo.skill_list"
          :key="index"
        >
          <div class="row flex">
            <div class="column w-25">
              <p class="label">
                בחרו קטגוריה
              </p>
              <p class="value">
                {{ skill.skill_category_eng_name }}
              </p>
            </div>
            <div class="column w-25">
              <p class="label">
                קטגוריית משנה
              </p>
              <p class="value">
                {{ skill.skill_eng_name }}
              </p>
            </div>
            <div class="column w-25">
              <p class="label">
                ציין את הרמה שלך
              </p>
              <p class="value">
                {{ skill.skill_level_eng_name }}
              </p>
            </div>
          </div>
          <div class="row flex">
            <div class="column w-full">
              <p class="label">
                הערות
              </p>
              <p class="value">
                {{ skill.staff_skills_remark }}
              </p>
            </div>
          </div>
        </template>
      </template>
      <p class="block-heading">Information for the camp</p>
      <div class="row flex">
        <div class="column w-33">
          <p class="label">
            מהי רמת האנגלית שלך
          </p>
          <p class="value">
            {{ candidateInfo.english }}
          </p>
        </div>
        <div class="column w-33">
          <p class="label">
            האם חוסנת 3 חיסונים נגד קורונה
          </p>
          <p class="value">
            {{ candidateInfo.corona_vaccinated }}
          </p>
        </div>
        <div class="column w-33">
          <p class="label">
            איך שמעת אלינו
          </p>
          <p class="value">
            {{ candidateInfo.how }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-33">
          <p class="label">
            האם יש לך ויזה לארה'ב
          </p>
          <p class="value">
            {{ candidateInfo.no_for_visa }}
          </p>
        </div>
        <div class="column w-33">
          <p class="label">
            באיזה מחנה תרצה/י להדריך
          </p>
          <p class="value">
            {{ candidateInfo.camp_type }}
          </p>
        </div>
        <div class="column w-33">
          <p class="label">
            האם את/ה פנוי/ה לצאת להדרכה ביוני 2022
          </p>
          <p class="value">
            {{ candidateInfo.date }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-full">
          <p class="label">
            האם תהיי/ה זמין/ה להשתתף ביום סמינר שיתקיים בחודש מאי
          </p>
          <p class="value">
            {{ candidateInfo.present_january }}
          </p>
        </div>
      </div>
      <div class="row flex">
        <div class="column w-full">
          <p class="label">
            האם תהיי/ה זמין/ה להשתתף בראיונות הסופיים אשר יתקיימו ב 31.1-1.2 2022
          </p>
          <p class="value">
            {{ candidateInfo.interview_participation }}
          </p>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'CandidateCard',
  props: {
    candidateInfo: {
      type: Object,
      required: true
    }
  },
  emits: ['delete', 'interview'],
  methods: {
    format,
    onDelete() {
      this.$emit('delete', this.candidateInfo)
    },
    onInterview() {
      this.$emit('interview', this.candidateInfo)
    },
    onSendLoginDetails() {
      this.$emit('send-login-details', this.candidateInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate-card {
  width: 100%;

  .block-heading {
    font-size: 24px;
    margin-bottom: 8px;
    margin-top: 30px;
    color: #20ACE2;
    font-weight: bold;
  }

  .row {
    margin-bottom: 16px;

    .column {
      .label {
        font-size: 18px;
        color: black;
        font-weight: 600;
      }

      .value {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .candidate-card {
    .block-heading {
      font-size: 20px;
    }

    .row {
      display: block;
      margin-bottom: 10px;

      .w-25, .w-33 {
        width: 100%;
      }

      .label {
        font-size: 16px;
        color: black;
      }

      .value {
        font-size: 18px;
      }

      .column {
        margin-bottom: 10px;
      }
    }

    .card-navbar {
      display: block;

      .name {
        display: block;
      }
    }
  }
}
</style>

<template>
  <ExpandCard
    class="divide-info-card"
    :expandable="hasContent"
  >
    <template #header>
      <div class="header-top flex items-center pb-1">
        <div class="card-title">
          Divide List
          <span>({{ divides.length }})</span>
        </div>
        <div class="grow flex justify-end">
          <el-button @click.stop="onAddDivide" type="success">
            <i class="vc-icon-plus-linear"></i>
          </el-button>
        </div>
      </div>
    </template>
    <div v-if="hasContent">
      <div class="divide-table-wrap w-full px-3">
        <el-table
          ref="divideTable"
          :data="divideList"
          empty-text="No Data!"
          class="w-full divide-list-table"
        >
          <el-table-column type="index" width="40" class-name="index-cell"/>
          <TableContent
            :columns="divideTableColumns"
          >
            <template #header="{col}">
              <table-header
                :column="col"
              />
            </template>
            <template #place_price_usd="{row}">
              <span class="semibold pl-3">{{ row.place_price_usd }}</span>
            </template>
            <template #action="{row}">
                <span class="px-2 text-blue pointer icon-base edit-action" @click="onDivideEdit(row)">
                  <i class="vc-icon-edit-pencil"></i>
                </span>
            </template>
          </TableContent>
        </el-table>
      </div>
    </div>
  </ExpandCard>
  <el-dialog
    v-model="divideDialog"
    title="Divide Upsert"
    width="650px"
    class="divide-upsert-dialog"
  >
    <div class="divide-form__wrap">
      <el-form
        v-loading="loading"
        ref="upsertDivideForm"
        :model="divideModel"
        label-position="top"
        class="divide-form"
      >
        <div class="field-row">
          <div class="form-field">
            <el-form-item
              prop="divide_type"
              label="Divide Type"
              class="w-full"
            >
              <el-select
                v-model="divideModel.divide_type"
                class="w-full"
              >
                <el-option
                  v-for="option in divideTypesList"
                  :key="option.id"
                  :value="option.id"
                  :label="`${option.name} (${option.name_en})`"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-field">
            <el-form-item
              prop="divide_place_id"
              label="Divide Place"
              class="w-full"
            >
              <el-select
                v-model="divideModel.divide_place_id"
                class="w-full"
              >
                <el-option
                  v-for="option in filteredPlaces"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="field-row">
          <div class="form-field">
            <el-form-item
              prop="time"
              label="Divide Time"
              class="w-full"
            >
              <el-date-picker
                v-model="divideModel.time"
                type="datetime"
                placeholder="Pick a day"
                value-format="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD HH:mm:ss"
              />
            </el-form-item>
          </div>
          <div class="form-field">
            <el-form-item
              prop="divide_insert_date"
              label="Divide Insert Time"
              class="w-full"
            >
              <el-date-picker
                v-model="divideModel.divide_insert_date"
                type="datetime"
                placeholder="Pick a day"
                value-format="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD HH:mm:ss"
              />
            </el-form-item>
          </div>
        </div>
        <div class="field-row">
          <div class="form-field">
            <el-form-item
              prop="place_price_usd"
              label="Place Price"
              class="w-full"
            >
              <el-input
                v-model="divideModel.place_price_usd"
                autocomplete="off"
                type="text"
              />
            </el-form-item>
          </div>
        </div>
        <div class="field-row">
          <div class="form-field">
            <el-form-item
              prop="remarks"
              label="Remarks"
              class="w-full"
            >
              <el-input
                v-model="divideModel.remarks"
                autocomplete="off"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </div>
        </div>
        <div class="field-row">
          <div class="form-field">
            <el-form-item
              prop="place_place_remarks"
              label="Place Remarks"
              class="w-full"
            >
              <el-input
                v-model="divideModel.place_place_remarks"
                autocomplete="off"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeDivideModal">
          <i class="vc-icon-cancel icon-base mr-2"></i>
          Cancel
        </el-button>
        <el-button
          :loading="loading"
          type="primary"
          @click="onSaveDivide"
        >
          <i class="vc-icon-Save icon-base mr-2"></i>
          Save
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import ExpandCard from "@/components/pages-components/admin/ExpandCard";
import Children from "controllers/Children";
import TableContent from "@/components/pages-components/common/Table/TableContent";
import TableHeader from "@/components/pages-components/common/Table/TableHeader";

const DIVIDE_MODEL = {
  divide_id: '',
  child_id: '',
  divide_type: '',
  divide_place_id: '',
  place_price_usd: '',
  time: '',
  divide_insert_date: '',
  remarks: '',
  place_place_remarks: '',
}

export default {
  name: "DivideInfoCard",
  props: {
    childId: {
      type: [String, Number],
      default: ''
    },
    divides: {
      type: Array,
      default: () => []
    },
    configs: {
      type: Object,
      default: () => ({})
    }
  },
  components: {TableHeader, TableContent, ExpandCard},
  emits: ['updated'],
  data: () => ({
    loading: false,
    divideDialog: false,
    divideEdit: null,
    divideModel: { ...DIVIDE_MODEL },
    divideTableColumns: [
      {
        prop: 'divide_type_name',
        label: 'Type',
        icon: 'vc-icon-type'
      },
      {
        prop: 'divide_place_name',
        label: 'Place',
        icon: 'vc-icon-street'
      },
      {
        prop: 'place_price_usd',
        label: 'Price',
        icon: 'vc-icon-billing'
      },
      {
        prop: 'time',
        label: 'Time',
        icon: 'vc-icon-schedule-calendar'
      },
      {
        prop: 'divide_insert_date',
        label: 'Insert Date',
        icon: 'vc-icon-schedule-calendar'
      },
      {
        prop: 'remarks',
        label: 'Remarks',
        icon: 'vc-icon-notes'
      },
      {
        prop: 'place_place_remarks',
        label: 'Place Remarks',
        icon: 'vc-icon-notes'
      },
      {
        prop: 'action',
        label: '',
        width: '80px'
      }
    ]
  }),
  computed: {
    divideList () {
      return this.divides.map(i => {
        return {
          ...i,
          divide_type_name: this.getDivideTypeName(i.divide_type_id)
        }
      })
    },
    hasContent () {
      return !!this.divides.length
    },
    divideTypesList () {
      return this.configs.DvideTypeList || []
    },
    dividePlaceList () {
      return this.configs.DvidePlaceList || []
    },
    filteredPlaces () {
      if (this.divideModel.divide_type) {
        return this.dividePlaceList.filter(i => i.type === this.divideModel.divide_type)
      }
      return this.dividePlaceList
    }
  },
  methods: {
    onDivideEdit(data) {
      this.divideModel = {
        ...data,

      }
      this.divideDialog = true
    },
    getDivideTypeName(id) {
      return this.divideTypesList.find(i => i.id === id)?.name
    },
    getDividePlaceName(place_id) {
      return this.dividePlaceList.find(i => i.id === place_id)?.name
    },
    closeDivideModal () {
      this.divideDialog = false
      this.divideEdit = null
      this.divideModel = { ...DIVIDE_MODEL }
    },
    async onSaveDivide () {
      try {
        this.loading = true
        const formData = {
          ...this.divideModel,
          child_id: this.childId,
          divide_type_name: '',
        }
        await Children.DivideUpsert(formData)
        this.$emit('updated', true)
        this.closeDivideModal()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onAddDivide () {
      this.divideEdit = null
      this.divideModel = { ...DIVIDE_MODEL }
      this.divideDialog = true
    }
  }
}
</script>

<style lang="scss">
.divide-upsert-dialog {
  max-width: 90vw;
  .divide-form {
    .field-row {
      display: flex; justify-content: center;
      .form-field {
        flex: 1 0 auto;
        padding: 0 12px;
      }
    }
    .el-form-item {
     margin-bottom: 10px;
    }
    .el-form-item__label {
      color: black;
      padding: 0;
      font-weight: 500;
    }
    .el-select, .el-input {
      width: 100%;
    }
  }
}
.divide-list-table {
  .cell {
    color: var(--black);
  }
  th .cell {
    color: #1c84ad
  }
  .edit-action {
    transition: all .3s ease;
    i {
      font-weight: 500;
    }
    &:hover {
      i {
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .divide-upsert-dialog {
    .divide-form {
      .field-row {
        flex-wrap: wrap;
        .form-field {
          flex: 1 0 100%;
        }
      }
    }
  }
}
</style>

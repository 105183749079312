<template>
  <nav class="navbar flex justify-between items-center">
    <div class="sidebar-logo flex-center">
      <NavLogoIcon class="logo-icon"/>
    </div>
    <el-link
      @click="logout"
      type="primary"
    >
      Logout
    </el-link>
  </nav>
</template>

<script>
import NavLogoIcon from "@/components/NavLogoIcon";
import authEnum from '@/util/authEnum'

export default {
  name: "Header",
  components: { NavLogoIcon },
  data () {
    return {
      command:{
        Logout: "logout",
        Settings: 'settings'
      }
    }
  },
  methods: {
    logout() {
      authEnum.deleteAccessToken()
      location.reload()
    }
  }
}
</script>

<style lang="scss">
  .navbar {
    height: 100%;
    .sidebar-logo {
      width: 100px;
      height: 56px;
      svg {
        max-width: 100%;
        max-height: 100%;
        width: 100px;
        height: 56px;
      }
    }
    .navbar-nav {
      .photo {
        margin: 0 8px;
        img {
          object-fit: contain;
          width: 45px;
          height: 45px;
        }
      }
    }
  }
</style>

<template>
  <el-form-item :label="getParameterPrettyName" :prop="getParameterKey">
    <el-input-number
      v-model.number="parameterValue"
      v-bind="+parameter.FormParameterData"
    />
  </el-form-item>
</template>

<script>
import parameterMixin from '@/mixins/parameterMixin';

export default {
  name: 'ParameterNumber',
  mixins: [parameterMixin]
}
</script>

<template>
  <el-dialog
    v-model="modalVisible"
    title="Create New Child"
    width="450px"
    class="newChildFormDialog"
  >
    <div class="new-child-form__wrap">
      <el-form
        v-loading="loading"
        ref="newChildForm"
        :model="newChildModel"
        label-position="top"
        class="new-child-form"
      >
        <el-form-item
          autocomplete="off"
          :rules="rules.fname"
          prop="fname_heb"
          label="First Name (heb)"
        >
          <el-input
            v-model="newChildModel.fname_heb"
            autocomplete="off"
            type="text"
          />
        </el-form-item>
        <el-form-item
          autocomplete="off"
          :rules="rules.email"
          prop="email"
          label="Email"
        >
          <el-input v-model="newChildModel.email"  autocomplete="off"/>
        </el-form-item>
        <el-form-item
          autocomplete="off"
          :rules="rules.cell"
          prop="cell"
          label="Phone"
        >
          <el-input
            v-model="newChildModel.cell"
            autocomplete="off"
            type="text"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeModal">
          <i class="vc-icon-cancel icon-base mr-2"></i>
          Cancel
        </el-button>
        <el-button
          :loading="loading"
          type="primary"
          @click="onSave"
        >
          <i class="vc-icon-Save icon-base mr-2"></i>
          Save
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {format} from "date-fns";

const MODEL_FIELDS = {
  fname_heb: '',
  email: '',
  cell: '',
  reg_date: ''
}

export default {
  name: "CreateChildForm",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'onSubmit'],
  data: () => ({
    newChildModel: {...MODEL_FIELDS},
    rules: {
      fname: [{required: true, message: 'Please input First Name'}],
      email: [
        {required: true, message: 'Please input email address', trigger: 'blur'},
        {type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change']}
      ],
      cell: [
        {required: true, message: 'Please input Phone Number', trigger: 'blur'}
      ]
    }
  }),
  computed: {
    modalVisible: {
      get() {
        return this.modelValue
      },
      set(val) {
        if(!val) {
          this.$refs.newChildForm.resetFields()
        }
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:modelValue', false)
    },
    onSave() {
      this.$refs.newChildForm.validate((valid) => {
        if (valid) {
          const date = new Date()
          this.$emit('onSubmit', {
            ...this.newChildModel,
            reg_date: format(date, 'yyyy-MM-dd HH:mm:ss')
          })
        }
      })
    },
    resetModel () {
      this.newChildModel = {...MODEL_FIELDS}
    }
  }
}
</script>

<style lang="scss">
.new-child-form {
  &__wrap {
    .el-form-item__label {
      font-weight: 600;
      font-size: 16px;
    }
  }
}
.newChildFormDialog {
  max-width: 98vw;
}
</style>

<template>
  <div v-loading.fullscreen.lock="loading" class="pdf-interviewers-wrap w-full py-3">
    <template v-for="(candidate, index) in candidates" :key="candidate.id">
      <StaffInterviewerCard
        :candidate="candidate"
        :order-number="index+1"
      />
    </template>
    <div
      v-if="!candidates.length && !loading"
      class="w-full h-full flex-center text-lg py-5"
    >
      No Records!
    </div>
  </div>
</template>

<script>
import pdfPreviewMixin from "@/mixins/pdfPreviewMixin";
import StaffInterviewerCard from "@/views/admin/Pdf/StaffInterviewerCard.vue";

export default {
  name: "StaffPdfInterviewers",
  components: {StaffInterviewerCard},
  mixins: [pdfPreviewMixin],
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@media print {
  .pdf-interviewers-wrap {
    padding: 0;
  }
}
@media print and (orientation: landscape) {
  .pdf-interviewers-wrap {
    padding: 0 12px;
  }
}
</style>

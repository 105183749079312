<template>
  <el-dialog
    :model-value="dialogVisible"
    append-to-body
    top="5vh"
    @close="onClose"
    title="Message"
    class="staffCustomMessagesModal"
  >
    <div class="content-block w-full">
      <div v-show="!recipientsTableVisible" class="form-wrapper w-full">
        <div class="email-template-form pb-8">
          <div class="form-row">
            <div class="form-item mb-2">
              <div class="form-label">
                Select Template:
              </div>
              <el-select
                v-model="template"
                filterable
                value-key="email_template_id"
              >
                <el-option
                  v-for="(template, i) in templateList"
                  :key="`t-email-${i}`"
                  :value="template"
                  :label="template.template_name || 'No Name'"
                ></el-option>
              </el-select>
            </div>
            <div class="form-item">
              <div class="form-label">
                Sender:
              </div>
              <el-input
                name="senderInput"
                v-model="sender"
                autocomplete="off"
              />
            </div>
            <div class="form-item">
              <div class="form-label">
                Sender Name:
              </div>
              <el-input
                name="senderInput"
                v-model="senderName"
                autocomplete="off"
              />
            </div>
          </div>
          <template v-if="isEmailType">
            <div class="form-row">
              <div class="form-item">
                <div class="form-label">
                  Subject:
                </div>
                <el-input
                  name="subjectInput"
                  v-model="subject"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-item">
                <div class="form-label">
                  Body
                </div>
                <div>
                  <html-editor
                    v-model="message"
                    :configs="{
                      language: {
                        ui: 'en',
                        content: 'he',
                        direction: 'rtl',
                      },
                      htmlSupport: {
                        allow: [
                          // Enables all HTML features, just copied from the docs
                          {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true,
                          },
                        ],
                      },
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-item">
                <div class="form-label">
                  Template Name:
                </div>
                <el-input
                    v-model="template_name"
                    autocomplete="off"
                />
              </div>
            </div>
            <div class="text-black flex items-center text-md" >
              <i class="vc-icon-info text-blue icon-lg mr-2"></i>
              <span v-html="textInfo"></span>
            </div>
          </template>
          <template v-else>
            <div class="form-row">
              <div class="form-item">
                <div class="form-label">
                  Message:
                </div>
                <el-input
                  name="smsBody"
                  type="textarea"
                  v-model="message"
                  autocomplete="off"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="w-full px-3 pb-6">
        <div class="actions-block">
          <el-button
            @click="recipientsTableVisible = !recipientsTableVisible"
            plain
            type="primary"
          >
            <i class="vc-icon-account-list mr-2 icon-lg"></i>
            {{ recipientsTableVisible ? 'Hide Recipients' : 'Show Recipients' }}
          </el-button>
          <el-button @click="openPreviewMessage(null)" type="success">
            <i class="vc-icon-template-list mr-2 icon-lg"></i>
            Show Preview
          </el-button>
          <el-button
              @click="saveTemplate"
              type="primary"
          >
            <i class="vc-icon-Save mr-2 icon-lg"></i>
            Save Template
          </el-button>
          <el-popconfirm
            title="Are you sure to delete this?"
            @confirm="deleteTemplate"
          >
            <template #reference>
              <el-button
                :disabled="!template"
                type="danger"
              >
                <i class="vc-icon-recycle-bin mr-2 icon-lg"></i>
                Delete Template
              </el-button>
            </template>
          </el-popconfirm>
          <el-button
            :disabled="isDisabledButton"
            @click="startSending"
            type="primary"
          >
            <i class="vc-icon-skip-arrow mr-2 icon-lg"></i>
            Send Messages
          </el-button>
        </div>
      </div>
      <RecipientsStaffTable
        :is-visible="recipientsTableVisible"
        :data-fields="dataFields"
        :process-data="sendingProcessData"
        :type-field="recipientField"
        @preview="openPreviewMessage"
      />
    </div>
    <SidebarList
      :fields="sidebarFields"
      class="right-sidebar"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="onClose"
        >
           <i class="vc-icon-cancel icon-base mr-2"></i>
          Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog
    v-model="previewVisible"
    append-to-body
    @closed="checkScroll"
  >
    <div v-show="isEmailType" class="w-full text-lg text-black px-5 pb-5">
      <span class="semibold">Subject:</span>
      <span>{{ subjectPreviewContent }}</span>
    </div>
    <div class="flex items-center h-full p-5 text-lg text-black">
      <div v-html="messagePreviewContent" />
    </div>
  </el-dialog>
</template>

<script>
import Messages from "controllers/Messages";
import HtmlEditor from "@/components/HtmlEditor";
import SidebarList from "@/components/pages-components/admin/Children/SidebarList";
import mustache from "mustache";
import RecipientsStaffTable from "@/components/pages-components/admin/Staff/RecipientsStaffTable";
import {ElMessage} from "element-plus";

const FORM_SYSTEM = 1
const EMAIL_TEMPLATE = 1
const SMS_TEMPLATE = 2

export default {
  name: 'SendEmailDialog',
  components: {RecipientsStaffTable, SidebarList, HtmlEditor},
  props: {
    messageType: {
      type: String,
      default: ''
    },
    dataFields: {
      type: Array,
      default: () => []
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['on-close', 'refresh'],
  data() {
    return {
      sender: '',
      senderName: '',
      subject: '',
      message: '',
      template_name: '',
      template: null,
      templates: [],
      recipientsTableVisible: false,
      previewModel: null,
      previewVisible: false,
      sendingProcessData: {}
    }
  },
  watch: {
    dialogVisible () {
      this.resetData()
    },
    template (val) {
      this.message = val?.body || ''
      this.subject = this.isEmailType ? val?.email_subject : ''
      this.template_name = val?.template_name || ''
      this.sender = val?.sender || ''
      this.senderName = val?.sender_name || ''
    }
  },
  computed: {
    textInfo () {
      return 'Put <b>{{field_name}}</b> to body or/and subject for dynamic replace'
    },
    recipientField () {
      return this.isSmsType ? 'cell' : 'email'
    },
    isEmailType () {
      return this.messageType === 'email'
    },
    isSmsType () {
      return this.messageType === 'sms'
    },
    messagePreviewContent () {
      if (!this.previewModel) {
        return ''
      }
      return this.getContentRender(this.message, this.previewModel)
    },
    subjectPreviewContent () {
      if (!this.previewModel) {
        return ''
      }
      return this.getContentRender(this.subject, this.previewModel)
    },
    sidebarFields () {
      if (this.dataFields?.length) {
        return Object.keys(this.dataFields[0]).sort()
      }
      return []
    },
    templateList () {
      if (this.isSmsType) {
        return this.templates.filter(i => i.template_type === SMS_TEMPLATE)
      } else if (this.isEmailType) {
        return this.templates.filter(i => i.template_type === EMAIL_TEMPLATE)
      }
      return []
    },
    isDisabledButton () {
      return !(this.message)
    }
  },
  methods: {
    async deleteTemplate () {
      if (!this.template) {
        return
      }
      try {
        const { email_template_id } = this.template
        await Messages.EmailTemplateDelete(email_template_id)
        ElMessage({
          showClose: true,
          message: 'Email Template was deleted!',
          type: 'success'
        })
        this.resetData()
        await this.getConfigs()
      } catch (e) {
        console.log(e.message)
      }
    },
    async saveTemplate () {
      const data = {
        body: this.message,
        form_system: FORM_SYSTEM,
        sender: this.sender,
        sender_email: this.senderName,
        sender_name: this.senderName,
        email_subject: this.subject,
        template_name: this.template_name || 'Template ' + new Date().getTime(),
        template_type: EMAIL_TEMPLATE
      }
      try {
        let message = ''
        if (this.template) {
          data.email_template_id = this.template.email_template_id
          await Messages.EmailTemplateUpdate(data)
          message = 'Email Template was updated!'
        } else {
          await Messages.EmailTemplateAdd(data)
          message = 'Email Template was added!'
        }
        ElMessage({
          showClose: true,
          message: message,
          type: 'success'
        })
        await this.getConfigs()
      } catch (e) {
        console.error(e.message)
      }
    },
    getContentRender (template, data) {
      if (!template) {
        return ''
      }
      return mustache.render(template, data)
    },
    startSending () {
      if (this.isEmailType) {
        this.sendingEmailMessage()
      } else if (this.isSmsType) {
        this.sendingSmsMessage()
      }
    },
    emailDataItem (staffData) {
      return {
        EmailSender: this.sender,
        EmailBody: this.getContentRender(this.message, staffData),
        EmailSubject: this.getContentRender(this.subject, staffData),
        EmailSenderName: this.senderName,
        EmailRecipientList: [
          staffData.email
        ]
      }
    },
    smsDataItem (staffData) {
      return {
        SmsSender: this.sender,
        SmsBody: this.getContentRender(this.message, staffData),
        SmsRecipientList: [
          staffData.cell
        ]
      }
    },
   async sendingEmailMessage () {
      this.recipientsTableVisible = true
      for (let i = 0; i < this.dataFields.length; i++) {
        const staffData = this.dataFields[i] || {}
        try {
          this.sendingProcessData[staffData.id] = { status: 'sending' }
          const sendData = this.emailDataItem(staffData)
          if (sendData.EmailRecipientList?.length) {
            await Messages.sendEmail(sendData)
            this.sendingProcessData[staffData.id] = { status: 'done' }
          } else {
            this.sendingProcessData[staffData.id] = { status: 'error' }
          }
        } catch (e) {
          this.sendingProcessData[staffData.id] = { status: 'error' }
          console.log(e)
        }
      }
    },
    async sendingSmsMessage () {
      this.recipientsTableVisible = true
      for (let i = 0; i < this.dataFields.length; i++) {
        const staffData = this.dataFields[i] || {}
        try {
          this.sendingProcessData[staffData.id] = { status: 'sending' }
          const sendData = this.smsDataItem(staffData)
          if (sendData.SmsRecipientList?.length) {
            await Messages.sendSMS(sendData)
            this.sendingProcessData[staffData.id] = { status: 'done' }
          } else {
            this.sendingProcessData[staffData.id] = { status: 'error' }
          }
        } catch (e) {
          this.sendingProcessData[staffData.id] = { status: 'error' }
          console.log(e)
        }
      }
    },
    onClose() {
      this.$emit('on-close')
    },
    async getConfigs () {
      const list = await Messages.getEmailTemplateList(FORM_SYSTEM)
      this.templates = list || []
    },
    openPreviewMessage (data = null) {
      if (!data) {
        this.previewModel = this.dataFields[0]
      } else {
        this.previewModel = data
      }
      this.previewVisible = true
    },
    resetData () {
      this.sender = ''
      this.senderName = ''
      this.subject = ''
      this.message = ''
      this.template = null
      this.recipientsTableVisible = false
      this.previewModel = null
    },
    checkScroll () {
      if (this.dialogVisible) {
        const body = document.body
        if (!body.classList.contains('el-popup-parent--hidden')) {
          body.classList.add('el-popup-parent--hidden')
          body.style.paddingRight = '4px'
        }
      }
    }
  },
  mounted() {
    this.getConfigs()
  }
}
</script>

<style lang="scss">
  @mixin formStyles {
  .form-label {
    font-size: 16px;
    color: black;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .form-row {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    flex-wrap: wrap;
    .form-item {
      flex: 1 0 20rem;
      padding: 0 8px;
      max-width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
  .staffCustomMessagesModal {
    width: 80vw;
    min-width: 680px;
    max-width: 1320px;
    display: flex;
    flex-direction: column;
    .el-dialog__body {
      flex-grow: 1;
      display: flex;
    }
    .ck-editor__editable {
      min-height: 180px;
      max-height: 420px;
      color: black;
      font-size: 16px;
    }
    .actions-block {
      display: flex;
      flex-wrap: wrap;
      button {
        margin-bottom: 8px;
      }
    }
    .form-wrapper {
      @include formStyles;
    }

    .content-block {
      margin: 0 auto;
      flex: 1 0 auto;
      max-width: calc(100% - 300px);
      padding-right: 12px;
    }

    .right-sidebar {
      flex: 0 0 300px;
      .fields-list {
        max-height: 612px;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .staffCustomMessagesModal {
      max-width: 98vw;
      width: 100%;
      min-width: 90vw;
      .right-sidebar {
        display: none;
      }
      .content-block {
        max-width: 100%;
      }
      .actions-block {
        display: flex;
        flex-direction: column;

        .el-button {
          width: 100%;
          margin: 0 0 12px;
        }
      }
      .recipients-staff-table-wrap {
        width: 100%;
        overflow: auto;
        .recipients-table {
          width: max-content;
        }
      }
    }
  }

</style>

import jsonpath from 'jsonpath';

export default {
  emits: ['set-value'],
  props: {
    model: {
      type: Object,
      required: true
    },
    parameter: {
      type: Object,
      required: true
    }
  },
  computed: {
    getParameterPrettyName() {
      return this.parameter.parameterPrettyName
    },
    getParameterKey() {
      return this.parameter.parameterKey
    },
    parameterValue: {
      get() {
        return jsonpath.value(this.model, this.parameter.parameterJPath)
      },
      set(val) {
        this.$emit('set-value', val)
      }
    }
  },
  created() {
    if (this.parameter.defaultValue) {
      this.parameterValue = this.parameter.defaultValue
    }
  }
}

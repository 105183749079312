<template>
  <div>
    <el-button
      color="#20ACE2"
      type="primary"
      size="large"
      @click="open = !open"
    >
      Filter
    </el-button>
    <el-card
      class="filter-wrapper"
      :class="{'open': open}"
    >
      <template #header>
        Filter
      </template>
      <el-form ref="formRef" :model="filter">
        <div class="flex">
          <div class="w-20">
            <el-form-item>
              <el-select v-model="filter.firstapplication_status_id">
                <el-option
                  v-for="([name, id]) in Object.entries(STATUS.FIRST_APPLICATION_STATUS)"
                  :key="id"
                  :value="id"
                  :label="name"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="w-20">
            <el-form-item>
              <el-select
                v-model="filter.skill_id"
                placeholder="Skills"
              >
                <el-option
                  v-for="skill in skillList"
                  :key="skill.skill_id"
                  :value="skill.skill_id"
                  :label="skill.skill_eng_name"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="w-20">
            <el-form-item>
              <el-input
                v-model="filter.email"
                placeholder="email"
              />
            </el-form-item>
          </div>
          <div class="w-20">
            <el-form-item>
              <el-input
                v-model="filter.last_name"
                placeholder="last_name"
              />
            </el-form-item>
          </div>
          <div class="w-20">
            <el-form-item>
              <el-input
                v-model="filter.cell_phone"
                placeholder="cell_phone"
              />
            </el-form-item>
          </div>
          <div class="w-20">
            <el-form-item>
              <el-input
                v-model="filter.first_name"
                placeholder="first_name"
              />
            </el-form-item>
          </div>
        </div>
        <div class="actions-wrapper flex-center">
          <el-button
            color="#E0DEDE"
            type="primary"
            size="large"
            @click="open = false"
          >
            Close
          </el-button>
          <el-button
            color="#20ACE2"
            type="primary"
            size="large"
            @click="submit"
          >
            Apply
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import STATUS from '@/enum/status'
import SkillController from 'controllers/Skill'

export default {
  name: 'Filter',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      STATUS,
      open: false,
      filter: {},
      skillList: []
    }
  },
  async created() {
    const data = await SkillController.ConfData()

    this.skillList = data.skill_category_list.map(skillCat => {
      return skillCat.skill_list.map(skill => skill)
    }).flat()
  },
  watch: {
    open() {
      this.filter = {...this.modelValue}
    }
  },
  methods: {
    submit() {
      this.$emit('submit', this.filter)
      this.open = false
    }
  },
  mounted() {
    this.filter = this.modelValue
  }
}
</script>

<style lang="scss" scoped>
.filter-wrapper {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 8px;

  .el-select {
    width: 100%;
  }

  .el-input, .el-select {
    padding: 0 8px;
  }

  &.open {
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .filter-wrapper {
    .w-20 {
      width: 100%;
    }
    .flex {
      display: block;
    }
  }
}
</style>

let adminRoute = 'admin.camp.co.il'

try {
  let host = new URL(process.env.VUE_APP_BASE_URL).host

  if (host) adminRoute = host
} catch (e) {
  console.log(e)
}

const BY_DOMAIN_ROUTES = {
  ADMIN: adminRoute,
  STAFF_MY: 'mystaff.camp.co.il'
}

export default BY_DOMAIN_ROUTES

<template>
  <div v-loading.lock="loading" class="candidate-profile-wrapper">
    <div v-if="finished" class="congrats-block">
      <p class="title">
        Thank You!
      </p>
      <p class="subtitle">
        for updating your information
      </p>
      <img class="image" src="/images/finish_wizard_congrats.svg" alt="Congrats Image">
      <div class="link">
        <el-link
          @click="finished = false"
          type="primary"
        >
          Go Back
        </el-link>
      </div>
    </div>
    <el-tabs v-else
             type="border-card"
             v-model="currentFormKey">
      <el-tab-pane v-for="form in candidateForms"
                   :key="form.key"
                   :label="form.prettyName"
                   :name="form.key"
      >
        <Wizard
          :steps="form.StepList"
          :ref="`wizard-${form.key}`"
          :validate="() => validateForms(`form-${form.key}-${currentStep}`)"
          :step-index="currentStep"
          @step-change="currentStep = $event"
          @finish="onFinish"
        >
          <template
            v-for="(step, stepIndex) in form.StepList"
            :key="stepIndex"
            v-slot:[step.key]>
            <div class="cards-wrapper">
              <div class="card"
                   v-for="(category, categoryIndex) in step.CategoriesList"
                   :key="categoryIndex"
              >
                <p class="card-title">
                  {{ category.FormCategoryName }}
                </p>
                <el-form
                  :ref="`form-${form.key}-${stepIndex}`"
                  :model="candidateModel"
                  :rules="categoryValidationRules(category)"
                  label-position="top"
                  class="form"
                >
                  <component
                    v-for="(parameter, index) in category.PrameterList"
                    @set-value="setParameterValue(parameter, $event)"
                    :ref="parameter.parameterKey"
                    :model="candidateModel"
                    :key="index"
                    :is="parameter.FormParameterCompanentTag"
                    :parameter="parameter"
                  />
                </el-form>
              </div>
            </div>
          </template>
        </Wizard>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import jsonpath from 'jsonpath'

import { ElMessage } from 'element-plus'

import StaffMyController from 'controllers/StaffMy'
import FormsController from 'controllers/Forms'

import authEnum from '@/util/authEnum'
import candidateFormsDefault, {mapForms} from '@/util/getCandidateFormsParsed'

import Wizard from '@/components/pages-components/common/Wizard/Wizard'
import ParameterText from '@/components/pages-components/common/parameters/ParameterText'
import ParameterNumber from '@/components/pages-components/common/parameters/ParameterNumber'
import ParameterSelectbox from '@/components/pages-components/common/parameters/ParameterSelectbox'
import ParameterYesNo from '@/components/pages-components/common/parameters/ParameterYesNo'
import ParameterDate from '@/components/pages-components/common/parameters/ParameterDate'
import ParameterImage from '@/components/pages-components/common/parameters/ParameterImage'
import ParameterDoc from '@/components/pages-components/common/parameters/ParameterDoc'

export default {
  name: 'CandidateProfile',
  components: {
    Wizard,
    ParameterText,
    ParameterNumber,
    ParameterSelectbox,
    ParameterYesNo,
    ParameterDate,
    ParameterImage,
    ParameterDoc
  },
  data() {
    return {
      candidateForms: [],
      loading: false,
      candidateModel: {},
      currentFormKey: null,
      currentStep: 0,
      finished: false
    }
  },
  watch: {
    currentFormKey(newValue, oldValue) {
      if (oldValue !== null) {
        this.currentStep = 0
        StaffMyController.Update(this.candidateModel)
      }
    },
    currentStep() {
      window.scrollTo(0, 0)
    }
  },
  async created() {
    await this.getCandidateInfo()
    await this.getForms()
    await this.setInitialForm()
  },
  mounted() {
    this.validationReset()
  },
  methods: {
    setInitialForm() {
      this.currentFormKey = this.candidateForms[0].key
    },
    scrollToElement(element) {
      const headerOffset = document.querySelector('header')?.offsetHeight || 100

      const y = element.getBoundingClientRect().top + window.pageYOffset - headerOffset

      window.scrollTo({top: y, behavior: 'smooth'});
    },
    async validateForms(formRefSelector) {
      return new Promise((resolve, reject) => {
        const validationInfoArray = []

        new Promise((resolve) => {
          this.$refs[formRefSelector].forEach((form, index, array) => {
            form
              .validate()
              .catch(err => {
                validationInfoArray.push(err)
              })
              .finally(() => {
                if (index === array.length - 1) {
                  resolve()
                }
              })
          })
        }).then(() => {
          if (!validationInfoArray.length) {
            resolve()
          } else {
            ElMessage({
              showClose: true,
              message: 'Please, fill all the required fields',
              type: 'error'
            })

            try {
              this.scrollToElement(this.$refs[Object.keys(validationInfoArray[0])[0]][0].$el)
            } catch (e) {
              console.log(e)
            }

            reject()
          }
        })
      })
    },
    async validationReset() {
      await this.$nextTick()

      const formRefsSelector = Object.keys(this.$refs).filter(key => key.includes('form-'))

      formRefsSelector.forEach(formRefSelector => {
        setTimeout(() => {
          this.$refs[formRefSelector].forEach(form => {
            form.clearValidate()
          })
        }, 700)
      })
    },
    async getCandidateInfo() {
      this.loading = true

      try {
        const {EntityId} = authEnum.getDecodedToken()

        if (!EntityId) {
          return
        }

        const {Data} = await StaffMyController.Info(EntityId)

        this.candidateModel = Data
        this.candidateModel.id = EntityId
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getForms() {
      const candidateForms = await FormsController.ParametersList()

      if (candidateForms.length > 0) {
        this.candidateForms = mapForms(candidateForms)
      } else {
        this.candidateForms = candidateFormsDefault
      }
    },
    setParameterValue(parameter, value) {
      jsonpath.value(this.candidateModel, parameter.parameterJPath, value)
    },
    categoryValidationRules(category) {
      const rules = {}

      category.PrameterList.forEach(parameter => {
        if (parameter.parameterValidationRules.length) {
          rules[parameter.parameterKey] = parameter.parameterValidationRules
        }
      })

      return rules
    },
    async onFinish() {
      this.loading = true

      try {
        const currentTabIndex = this.candidateForms.findIndex(form => form.key === this.currentFormKey)
        const FormOrder = this.candidateForms[currentTabIndex].FormOrder
        const payload = {
          ...this.candidateModel,
          FormOrder
        }

        await StaffMyController.Update(payload)
        await this.getCandidateInfo()

        if (currentTabIndex + 1 < this.candidateForms.length) {
          this.currentFormKey = this.candidateForms[currentTabIndex + 1].key
          this.currentStep = 0
          await this.validationReset()
        } else {
          this.finished = true
          this.setInitialForm()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cards-wrapper {
  margin: 0 100px;

  .card {
    margin: 20px 0;

    .form {
      padding: 20px 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
      overflow: auto;
    }
  }
}

.congrats-block {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  padding: 50px 0;
  background: #F2F7FF;
  border: 2px solid #20ACE2;
  border-radius: 8px;

  .title {
    font-weight: bold;
    font-size: 48px;
    line-height: 145%;
    text-align: center;
    color: #20ACE2;
  }

  .subtitle {
    font-weight: 500;
    font-size: 36px;
    line-height: 145%;
    text-align: center;
    color: #20ACE2;
  }

  .image {
    display: block;
    margin: 50px auto 0;
  }

  .link {
    margin-top: 20px;
    text-align: center;
  }
}

@media screen and (max-width: 960px) {
  .cards-wrapper {
    margin: 0;

    .card {
      margin: 20px 0;

      .form {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .congrats-block {
    width: 95%;
    padding: 10px 0;

    .title {
      font-size: 36px;
    }

    .subtitle {
      font-size: 26px;
    }

    .image {
      margin: 20px auto 0;
    }

    .link {
      margin-top: 15px;
    }
  }
}
</style>

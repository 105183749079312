<template>
  <el-dialog
      v-model="dialogVisible"
      :title="editMode ? `Edit Extra by ID: ${extraEditId}` : 'Add New Extra'"
      width="460px"
      @close="onCloseModal"
  >
    <div class="billing-extra-form__wrap">
      <el-form
          v-loading="loading"
          ref="extraDataForm"
          :model="extraDataModel"
          class="billing-extra-form"
      >
        <div class="flex w-full wrap">
          <div class="form-field">
            <el-form-item
                prop="children_extra_type_id"
                label="Extra Type"
                label-width="130px"
                class="w-full"
            >
              <el-select
                  v-model="extraDataModel.children_extra_type_id"
                  class="w-full"
                  @change="onExtraCategoryChange"
              >
                <el-option
                    v-for="option in extraCategories"
                    :key="option.children_extra_category_id"
                    :value="option.children_extra_category_id"
                    :label="option.children_extra_category_name"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-field">
            <el-form-item
                prop="children_extra_price"
                label="Extra Price"
                label-width="130px"
                class="w-full"
            >
              <el-input
                  v-model="extraDataModel.children_extra_price"
                  autocomplete="off"
                  class="w-full"
              />
            </el-form-item>
          </div>
          <div class="form-field">
            <el-form-item
                prop="children_extra_remarks"
                label="Extra Remarks"
                label-width="130px"
                class="w-full"
            >
              <el-input
                  v-model="extraDataModel.children_extra_remarks"
                  autocomplete="off"
                  class="w-full"
              />
            </el-form-item>
          </div>
          <div v-if="editMode" class="form-field">
            <el-form-item
                prop="children_extra_date"
                label="Extra Date"
                label-width="130px"
                class="w-full"
            >
              <el-date-picker
                  v-model="extraDataModel.children_extra_date"
                  type="date"
                  placeholder="Pick a day"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  format="YYYY-MM-DD"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">
          <i class="vc-icon-cancel icon-base mr-2"></i>
          Cancel
        </el-button>
        <el-button
            :loading="loading"
            type="primary"
            @click="onSave"
        >
          <i class="vc-icon-Save icon-base mr-2"></i>
          Save
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>

import {format} from "date-fns";
import {EXTRA_MODEL, DATE_FORMAT} from "@/enum/bilingTableColumns";
export default {
  name: "ChildrenExtraBillingModal",
  props: {
      loading: {
          type: Boolean,
          default: false
      },
      configs: {
          type: Object,
          default: () => ({})
      }
  },
  emits: ['close', 'save'],
  data() {
    return {
      editMode: false,
      extraEditId: null,
      extraDataModel: { ...EXTRA_MODEL },
      dialogVisible: false
    }
  },
    computed: {
        extraCategories () {
            const list = this.configs.ExtraCategoryList || []
            return list.filter(i => +i.children_extra_category_is_show)
        },
        extraList () {
            const list = this.billingData.ExtraList || []
            return list.map(i => {
                return {
                    ...i,
                    children_extra_date: format(new Date(i.children_extra_date), DATE_FORMAT)
                }
            })
        }
    },
  methods: {
      onExtraCategoryChange (category_id) {
          const _data = this.extraCategories.find(i => i.children_extra_category_id === category_id)
          if (_data) {
              this.extraDataModel.children_extra_price = _data.children_extra_category_defualt_price
          }
      },
    onCloseModal() {
      this.$emit('close')
    },
    open(data) {
      this.editMode = !!data.children_extra_id
      this.extraEditId = data.children_extra_id
      this.extraDataModel = {...data}
      this.dialogVisible = true
    },
      onSave () {
        this.$emit('save', this.extraDataModel)
      },
      close () {
        this.dialogVisible = false
      }
  }
}
</script>


<template>
  <div v-loading.fullscreen.lock="loading" class="pdf-page-wrap w-full py-3">
    <template v-for="(candidate, index) in candidates" :key="candidate.id">
      <div class="sticker-card">
        <div class="sticker-card-content flex items-center relative">
          <div class="number-block">
            #{{ index + 1 }}
          </div>
          <div class="avatar-wrap">
            <el-avatar
              :src="candidate.staff_pic_url"
              :size="180"
              shape="square"
            />
          </div>
          <div class="sticker-card-info px-5">
            <div class="flex items-center card-name">
              <div class="semibold user-name">
                {{ getCandidateName(candidate) }}
              </div>
            </div>
            <div class="card-info">
              <div class="info-item">
                <div class="info-label">Interviewer:</div>
                <div class="info-value">{{ candidate.interview2_data?.interviewer_name }}</div>
              </div>
              <div class="info-item">
                <div class="info-label">Cell:</div>
                <div class="info-value">{{ candidate.cell }}</div>
              </div>
              <div class="info-item">
                <div class="info-label">Date/Time:</div>
                <div class="info-value">{{ getInterviewDate(candidate) }}</div>
              </div>
              <div class="info-item">
                <div class="info-label">Birthday:</div>
                <div class="info-value">{{ `${candidate.dob}/${candidate.mob}/${candidate.yob}` }}</div>
              </div>
              <div class="info-item">
                <div class="info-label">Position:</div>
                <div class="info-value">{{ getPosition(candidate) }}</div>
              </div>
              <div class="info-item">
                <div class="info-label">Gender:</div>
                <div class="info-value">{{ candidate.sex }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="!candidates.length && !loading"
      class="w-full h-full flex-center text-lg py-5"
    >
      No Records!
    </div>
  </div>
</template>

<script>
import pdfPreviewMixin from "@/mixins/pdfPreviewMixin";

export default {
  name: "StaffPdfStickers",
  mixins: [pdfPreviewMixin],
  methods: {
    getPosition(candidate) {
      const pos1 = `${this.get(candidate, 'pos1_data.camp_name')}_${this.get(candidate, 'pos1_data.pos')}`
      const pos2 = `${this.get(candidate, 'pos2_data.camp_name')}_${this.get(candidate, 'pos2_data.pos')}`
      const pos3 = `${this.get(candidate, 'pos3_data.camp_name')}_${this.get(candidate, 'pos3_data.pos')}`
      return `${pos1}${pos2 !== '_' ? ' / ' + pos2 : ''}${pos3 !== '_' ? ' / ' + pos3 : ''}`
    },
    getCandidateName (candidate) {
      if (candidate.ename) {
        return `${candidate.ename} ${candidate.ename2}`
      }
      return `${candidate.fname} ${candidate.lname}`
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
  .sticker-card {
    border-top: 1px solid #BEC2C9;
    border-bottom: 1px solid #BEC2C9;
    padding: 16px 0;
    font-size: 16px;

    &:not(:first-child) {
      margin-top: 24px;
    }
    .user-name {
      font-size: 20px;
    }
    .sticker-card-info {
      page-break-inside: avoid;
      .card-info {
        padding-top: 4px;
      }
      .info-item {
        display: flex;
        align-items: center;
        margin-bottom: 3px;
      }
      .info-label {
        color: black;
        width: 100px;
        font-weight: 500;
        flex-shrink: 0;
      }
    }
    .number-block {
      position: absolute;
      right: 12px;
      top: 4px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  @media print {
    .sticker-card {
      page-break-inside: avoid;
    }
  }
</style>

import { format } from 'date-fns'

import Staff from "@/api/controllers/Staff";

const USER_TYPES = {
  admin: 'Admin',
  manager: 'CampManager',
  other: 'Unknown'
}

const STATUSES_KEY = 'staff_status'
const POSITIONS_KEY = 'staff_pos'
const USER_TYPE_KEY = 'EntityType'
const CAMP_FILTER_LK = 'camp_name'
const CAMP_FILTER_VK = 'camp_id'
const DEFAULT_USER_TYPE = 'Unknown'

export const staff = {
  state: () => ({
    isData: false,
    userType: '',
    statusesList: [],
    positionsList: [],
    interviewList: [],
    campList: []
  }),

  mutations: {
    SET_STAFF_STATUSES_ALL(state, payload) {
      state.statusesList = payload
    },
    SET_STAFF_POSITIONS_ALL(state, payload) {
      state.positionsList = payload
    },
    SET_STAFF_INTERVIEWS_ALL(state, payload) {
      state.interviewList = payload
    },
    SET_USER_TYPE(state, val) {
      state.userType = val
    },
    SET_IS_DATA(state, val) {
      state.isData = val
    },
    SET_CAMP_ALL(state, val) {
      state.campList = val
    }
  },

  actions: {
    async fetchStaffConfigs({ commit }) {
      try {
        const res = await Staff.ConfData()
        const statuses = res[STATUSES_KEY] ? [...res[STATUSES_KEY]] : []
        const positions = res[POSITIONS_KEY] ? [...res[POSITIONS_KEY]] : []
        const mapStatuses = statuses.map(i => ({...i, label: i.status_name, value: i.id}))
        const mapPositions = positions.map(i => ({...i, label: i.pos, value: i.pos}))
        const mapCamp = positions.reduce((acc, item) => {
          if (acc.findIndex((i) => i[CAMP_FILTER_VK] === item[CAMP_FILTER_VK]) === -1) {
            return [
              ...acc,
              {
                [CAMP_FILTER_VK]: item[CAMP_FILTER_VK],
                value: item[CAMP_FILTER_VK],
                label: item[CAMP_FILTER_LK]
              }
            ]
          } else {
            return acc
          }
        }, [])
        const userType = res[USER_TYPE_KEY] || DEFAULT_USER_TYPE
        commit('SET_USER_TYPE', userType)
        commit('SET_STAFF_STATUSES_ALL', mapStatuses)
        commit('SET_STAFF_POSITIONS_ALL', mapPositions)
        commit('SET_CAMP_ALL', mapCamp)
        commit('SET_IS_DATA', true)
      } catch (e) {
        console.log('fetchStaffConfigs', e)
        throw e
      }
    },
    async fetchStaffInterviewList({ commit }) {
      const {data} = await Staff.InterviewList()

      const interviews = data.Data || []
      const mapInterviews = interviews.map(i => {
        return {
          ...i,
          label: `${i.interviewer_name} ${i.interview_time ? format(new Date(i.interview_time), 'dd MMM yyyy hh:mm a') : ''} (${i.counter})`,
          value: i.interview_id
        }
      }).sort((a,b) => {
        const aTime = a.interview_time ? new Date(a.interview_time).getTime() : new Date(1970).getTime()
        const bTime = b.interview_time ? new Date(b.interview_time).getTime() : new Date(1970).getTime()

        return aTime - bTime
      })

      commit('SET_STAFF_INTERVIEWS_ALL', mapInterviews)
    }
  },

  getters: {
    getStaffStatuses: (state) => state.statusesList,
    getStaffPositions: (state) => state.positionsList,
    getStaffInterviews: (state) => state.interviewList,
    getStaffCamps: (state) => state.campList,
    isUserAdmin: (state) => state.userType === USER_TYPES['admin'],
    isUserManager: (state) =>  state.userType === USER_TYPES['manager'],
    getUserType: (state) =>  state.userType,
    isFetchConfigData: (state) => state.isData
  }
}

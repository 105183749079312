import { createStore } from 'vuex'
import { staff } from './staff'


export const store = createStore({
  modules: {
    staff
  },
  state: {
    globalOptions: null
  },
  mutations: {
    SET_GLOBAL_OPTIONS(state, value) {
      state.globalOptions = value
    }
  },
  actions: {
    addGlobalOption({commit, getters}, option) {
      const options = getters.getGlobalOptions
      commit('SET_GLOBAL_OPTIONS', {...options, ...option})
    }
  },
  getters: {
    getGlobalOptions: (state) => state.globalOptions
  }
})

import axios from 'axios'
import { ElNotification } from 'element-plus'

import authEnum from '@/util/authEnum'

const apiURL = process.env.VUE_APP_API_URL || 'https://api.camp.co.il/v1/'

const instanceCookie = axios.create({
  baseURL: apiURL,
  withCredentials: true
})

const instance = axios.create({
  baseURL: apiURL
})

function responseErrorNotification(err) {
  const title = err.response?.data?.Message || 'Error'
  const text = err.response?.data?.Description || 'Generic error'
  ElNotification({
    title,
    message: text,
    type: 'error',
  })

  return Promise.reject(err)
}

instanceCookie.interceptors.response.use(res => res, err => {
  if (err.response.status === authEnum.INVALID_TOKEN_CODE) {
    window.location.href = err.response.data.loginRedirect + `?redirect=${window.location.href}`

    return
  }

  return responseErrorNotification(err)
})

instance.interceptors.response.use(res => res, responseErrorNotification)

instance.interceptors.request.use((config) => {
  return authEnum.getDefaultRequestConfig(config)
})

export {
  instance,
  instanceCookie
}

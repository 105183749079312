<template>
  <div class="CandidatesList py-3 px-2" v-loading.fullscreen.lock="loading">
    <div class="actions flex justify-end">
      <DropdownPdfButton
        @on-click="onPdfAction"
      />
    </div>
    <el-tabs
      v-model="activeTab"
      @tab-click="onTabClick"
      @tab-remove="removeTab"
    >
      <el-tab-pane name="list" class="tab-item">
        <template #label>
          <div class="w-max">
            <div class="flex items-center border-box">
              <i class="vc-icon-logs mx-1 icon-lg"></i>
              <div class="" style="padding-right: 8px">
                Main
              </div>
            </div>
          </div>
        </template>
        <div :class="{'is-visible': staffSelected}" class="send-actions-block">
          <div>
            <div class="py-5">
              <div class="label bold pb-3">Send Custom:</div>
              <div class="flex justify-between send-actions">
                <el-button
                  type="primary"
                  :disabled="!staffSelected"
                  :loading="loading"
                  @click="openCustomMessageModal('sms')"
                >
                  <i class="icon-base vc-icon-sms mr-2"/>
                  Send SMS
                </el-button>
                <el-button
                  type="success"
                  :disabled="!staffSelected"
                  :loading="loading"
                  @click="openCustomMessageModal('email')"
                >
                  <i class="icon-base vc-icon-mail mr-2"/>
                  Send Email
                </el-button>
              </div>
            </div>
            <div class="py-5">
              <div class="label bold pb-3">Send Interview Details:</div>
              <el-radio-group v-model="selectedInterview">
                <el-radio label="interview1" border :disabled="!staffSelected">First Interview</el-radio>
                <el-radio label="interview2" border :disabled="!staffSelected">Second Interview</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="flex justify-between send-actions">
            <el-button
              type="primary"
              :disabled="!staffSelected"
              :loading="loading"
              @click="sendBulkSmsStaff"
            >
              <i class="icon-base vc-icon-sms mr-2"/>
              Send SMS
            </el-button>
            <el-button
              type="success"
              :disabled="!staffSelected"
              :loading="loading"
              @click="sendBulkEmailStaff"
            >
              <i class="icon-base vc-icon-mail mr-2"/>
              Send Email
            </el-button>
          </div>
        </div>
        <div style="margin-bottom: 100px">
          <el-table
            ref="baseTable"
            :data="visibleTableData"
            empty-text="No Data!"
            style="width: 100%; margin-bottom: 20px"
            :indent="20"
            :max-height="720"
            class="candidates-table mt-3 mb-3r"
            v-loading="tableLoading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="36"/>
            <el-table-column type="index" width="40" class-name="index-cell"/>
            <template v-for="(column, i) in columns" :key="`col-${i}`">
              <el-table-column
                :min-width="column.minWidth || '150px'"
                :width="column.width"
                :column-key="column.prop"
                :type="column.type"
                :sortable="column.sortable"
                :prop="column.prop"
                :sort-method="column.sortMethod"
              >
                <template #header>
                  <div v-if="column.searchable" class="search-wrap" @click.stop>
                    <el-input v-model="searchModel[column.prop]" autocomplete="off" :name="`filter-by-${column.prop}`"
                              clearable @input="debounceSearchData(column.prop, $event)"/>
                  </div>
                  <div v-else-if="column.filter" class="search-wrap" @click.stop>
                    <el-select
                      v-model="searchModel[column.filterKey || column.prop]"
                      :multiple="column.multiple"
                      :collapse-tags="column.multiple"
                      :collapse-tags-tooltip="column.multiple"
                      :filterable="!column.multiple"
                      placeholder="All"
                      clearable
                      @change="debounceSearchData(column.filterKey || column.prop, $event)"
                    >
                      <el-option v-if="!column.multiple" label="All" value=""></el-option>
                      <el-option
                        v-for="option in column.filter"
                        :key="`option-${option.value}`"
                        :label="option.label"
                        :value="option.value"
                      />
                    </el-select>
                  </div>
                  <div v-else-if="column.datetime" class="search-wrap" @click.stop>
                    <el-date-picker
                      class="date-picker"
                      v-model="searchModel[column.prop]"
                      @change="debounceSearchData(column.prop, $event)"
                      value-format="DD MMM YYYY"
                    />
                  </div>
                  <div class="inline-flex items-center uppercase pr-1 base-cell min-w-max relative"
                       style="min-height: 20px">
                    <i v-if="column.icon" :class="column.icon" class="icon-md mr-1"/>
                    {{ column.label }}
                  </div>
                </template>
                <template v-if="column.prop === 'first_name'" #default="{ row }">
                  <div class="user-info-cell flex items-center pointer" @click="openInTab(row)">
                    <el-avatar
                      :src="row.staff_pic_url"
                      shape="square"
                      :size="64"
                      class="mr-2"
                    />
                    <div class="underline text-blue">
                      {{ `${row.full_name} (${row.id})` }}
                    </div>
                  </div>
                </template>
                <template v-else-if="column.prop === 'cell'"  #default="{ row }">
                  <a :href="`tel:${row.cell}`" class="text-black">
                    {{ row.cell }}
                  </a>
                </template>
                <template v-else-if="column.prop === 'status'" #default="{ row }">
                  <div class="flex w-full justify-between status-col">
                  <span class="mr-4">
                  {{ row.status_name }}
                </span>
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="'Last form update: ' + getFormLastSubmitDate(row)"
                      placement="top"
                    >
                    <span
                      :class="['user-status']"
                      :style="{ backgroundColor: getColorByFormProgress(row)}"
                    />
                    </el-tooltip>
                  </div>
                </template>
                <template
                  v-else-if="column.prop === 'interview1_id'"
                  #default="{ row }"
                >
                <span
                  v-if="row && row.interview1_data && Object.keys(row.interview1_data) && row.interview1_data.interview_time"
                  class="mr-4">
                  {{ row.interview1_data.interviewer_name }} {{ row.first_interview_date }}
                </span>
                </template>
                <template
                  v-else-if="column.prop === 'interview2_id'"
                  #default="{ row }"
                >
                <span
                  v-if="row && row.interview2_data && Object.keys(row.interview2_data) && row.interview2_data.interview_time"
                  class="mr-4">
                  {{ row.interview2_data.interviewer_name }} {{ row.second_interview_date }}
                </span>
                </template>
              </el-table-column>
            </template>
            <template #append>
              <div
                ref="loadMoreTrigger"
                v-show="!tableLoading && !loading"
                class="py-1"
              />
            </template>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-for="(tab, i) in tabs"
        :key="`tab-${i}`"
        :name="tab.tabKey"
        class="tab-editable"
        closable
      >
        <template #label>
          <div class="w-max inline-flex">
            <div class="flex items-center border-box">
              <i class="vc-icon-edit-pencil mx-1 icon-base"></i>
              <div class="" style="padding-right: 8px">
                {{ tab.tabName }}
              </div>
            </div>
          </div>
        </template>
        <CandidateInfoTab
          :params="candidateForms"
          :tab-data="tab"
          @updated="onRefreshList"
        />
      </el-tab-pane>
    </el-tabs>
    <SendEmailDialog
      :dialog-visible="emailDialogVisible"
      :message-type="messageType"
      :data-fields="selectionsStaff"
      @on-close="onConfirmCustomEmail"
    />
  </div>
</template>

<script>
import EmailTemplateFirstInterview from '@/enum/EmailTemplateFirstInterview.json'
import EmailTemplateSecondInterview from '@/enum/EmailTemplateSecondInterview.json'
import SmsTemplate from '@/enum/SmsTemplate.json'
import mustache from 'mustache'

import CandidateInfoTab from "@/views/admin/CandidateInfoTab";
import Staff from "controllers/Staff";

import get from 'lodash/get'
import {format} from 'date-fns'
import {getFormLastSubmitDate, getColorByFormProgress} from '@/enum/formOrderHelper'
import {ElMessage} from "element-plus";
import DropdownPdfButton from "@/components/pages-components/admin/Staff/DropdownPdfButton";
import SendEmailDialog from "@/components/pages-components/admin/Staff/SendEmailDialog";
import listTabsMixin from "@/mixins/listTabsMixin";
import CandidatesListMixin from "@/mixins/CandidatesListMixin";
import Messages from "controllers/Messages";

const DATE_FORMAT = 'dd MMM yyyy hh:mm a'
const DEFAULT_STATUS_COLOR = '#309A47'
const FIRST_INTERVIEW_TEMPLATE_ID = process.env.VUE_APP_FIRST_INTERVIEW_EMAIL_TEMPLATE_ID
const SECOND_INTERVIEW_TEMPLATE_ID = process.env.VUE_APP_SECOND_INTERVIEW_EMAIL_TEMPLATE_ID
const FIRST_INTERVIEW_SMS_TEMPLATE_ID = process.env.VUE_APP_FIRST_INTERVIEW_SMS_TEMPLATE_ID
const SECOND_INTERVIEW_SMS_TEMPLATE_ID = process.env.VUE_APP_SECOND_INTERVIEW_SMS_TEMPLATE_ID
const FORM_SYSTEM = 1
const DEFAULT_STATUSES = [1,2,5]

const INTERVIEW_TYPES = {
  'interview1': 'interview_1',
  'interview2': 'interview_2'
}

export default {
  name: "CandidatesList",
  components: {DropdownPdfButton, CandidateInfoTab, SendEmailDialog},
  mixins: [ listTabsMixin, CandidatesListMixin ],
  data() {
    return {
      searchModel: {
        phone: '',
        email: '',
        id: '',
        interview1_id: '',
        interview2_id: '',
        camp_id: '',
        position: '',
        status: [...DEFAULT_STATUSES],
        first_name: ''
      },
      setDefault: true,
      defaultFilterValues: {
        status: [...DEFAULT_STATUSES]
      },
      selectionsStaff: [],
      selectedInterview: 'interview1',
      emailDialogVisible: false,
      messageType: '',
      emailTemplates: {
        first: EmailTemplateFirstInterview,
        second: EmailTemplateSecondInterview
      },
      smsTemplates: {
        first: SmsTemplate,
        second: SmsTemplate
      }
    }
  },
  computed: {
    firstInterviewList() {
      const interviewList = this.$store.getters.getStaffInterviews || []

      return interviewList.filter(interview => interview.interview_type_id === 1)
    },
    secondInterviewList() {
      const interviewList = this.$store.getters.getStaffInterviews || []

      return interviewList.filter(interview => interview.interview_type_id === 2)
    },
    columns() {
      return [
        {
          prop: 'first_name',
          label: 'Name',
          icon: 'vc-icon-users',
          minWidth: '260px',
          sortable: true,
          searchable: true
        },
        {
          prop: 'cell',
          label: 'Phone',
          icon: 'vc-icon-phone',
          sortable: false,
          searchable: true
        },
        {
          prop: 'email',
          label: 'E-Mail',
          icon: 'vc-icon-mail',
          minWidth: '190px',
          sortable: false,
          searchable: true
        },
        {
          prop: 'interview1_id',
          label: '1 interview',
          icon: 'vc-icon-schedule-calendar',
          sortable: true,
          filter: this.firstInterviewList,
          sortMethod: (a, b) => {
            const aTime = a.first_interview_date ? new Date(a.first_interview_date) : new Date(1970)
            const bTime = b.first_interview_date ? new Date(b.first_interview_date) : new Date(1970)

            return bTime - aTime;
          }
        },
        {
          prop: 'interview2_id',
          label: '2 interview',
          icon: 'vc-icon-schedule-calendar',
          sortable: true,
          filter: this.secondInterviewList,
          sortMethod: (a, b) => {
            const aTime = a.second_interview_date ? new Date(a.second_interview_date) : new Date(1970)
            const bTime = b.second_interview_date ? new Date(b.second_interview_date) : new Date(1970)

            return aTime - bTime;
          }
        },
        {
          prop: 'camp_name',
          label: 'Camp Name',
          icon: 'vc-icon-minimap',
          filterKey: 'camp_id',
          sortable: true,
          filter: this.campFilterList
        },
        {
          prop: 'position',
          label: 'Position',
          icon: 'vc-icon-customer-experience',
          sortable: true,
          searchable: false,
          filter: this.positions
        },
        {
          prop: 'status',
          label: 'Status',
          icon: 'vc-icon-login-status',
          sortable: true,
          searchable: false,
          filter: this.statuses,
          multiple: true
        }
      ]
    },
    staffSelected() {
      return this.selectionsStaff.length
    }
  },
  methods: {
    getFormLastSubmitDate,
    getColorByFormProgress,
    openCustomMessageModal (type) {
      this.messageType = type
      this.emailDialogVisible = true
    },
    onPdfAction(actionData) {
      let route = {href: '/'};
      switch (actionData.action) {
        case 'full-info':
          route = this.$router.resolve({name: 'PdfPreviewStaffFull'})
          break;
        case 'folder-stickers':
          route = this.$router.resolve({name: 'PdfPreviewStaffStickers'})
          break;
        case 'interviewers':
          route = this.$router.resolve({name: 'PdfPreviewStaffInterviewers'})
          break;
      }
      window.open(route.href, '_blank');
    },
    onConfirmCustomEmail () {
      this.emailDialogVisible = false
    },
    async sendBulkSmsStaff(customData = null) {
      const list = this.selectionsStaff.filter(i => i[`${this.selectedInterview}_id`])
      if (!list.length) {
        return
      }

      try {
        this.loading = true
        const type = INTERVIEW_TYPES[this.selectedInterview]
        const promises = list.map(async item => {
          const interview_date = item[`${this.selectedInterview}_date`]
          const toRender = {
            ...item,
            interview_date: format(new Date(interview_date), 'dd MMM yyyy hh:mm a')
          }

          let SmsTemplate = this.smsTemplates.first

          if (type === 'interview_2') {
            SmsTemplate = this.smsTemplates.second
          }

          const body = {
            message: customData?.body ? customData.body : mustache.render(SmsTemplate.body, toRender),
            sender: mustache.render(SmsTemplate.sender_name, toRender),
            staff_id_list: [item.id],
            type
          }
          await this.sleep(1000)
          return await Staff.BulkSendSMS(body)
        })

        await Promise.all(promises)
        ElMessage({
          showClose: true,
          message: 'SMS List successfully sent',
          type: 'success',
        })
        await this.onRefreshList()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async sendBulkEmailStaff(customData = null) {
      const list = this.selectionsStaff.filter(i => i[`${this.selectedInterview}_id`])
      if (!list.length) {
        return
      }

      try {
        this.loading = true
        const type = INTERVIEW_TYPES[this.selectedInterview]
        const promises = list.map(async item => {
          const interview_date = item[`${this.selectedInterview}_date`]

          const toRender = {
            ...item,
            interview_date: format(new Date(interview_date), 'dd MMM yyyy hh:mm a')
          }

          let EmailTemplate = this.emailTemplates.first

          if (type === 'interview_2') {
            EmailTemplate = this.emailTemplates.second
          }

          const message = customData?.body || mustache.render(EmailTemplate.body, toRender)

          const body = {
            message,
            text: message,
            subject: customData?.subject || mustache.render(EmailTemplate.email_subject, toRender),
            staff_id_list: [item.id],
            sender: EmailTemplate.sender,
            type
          }
          await this.sleep(1000)
          return await Staff.BulkSendEmail(body)
        })

        await Promise.all(promises)
        ElMessage({
          showClose: true,
          message: 'Email List successfully sent',
          type: 'success',
        })
        await this.onRefreshList()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleSelectionChange(val) {
      this.selectionsStaff = val.map(i => ({
        ...i,
        fname: i.ename || i.fname,
        lname: i.ename2 || i.lname,
        interview1_date: get(i, 'interview1_data.interview_time', ''),
        interview2_date: get(i, 'interview2_data.interview_time', '')
      }))
    },
    getFormatDate(row, prop) {
      const field = get(row, prop)
      if (!field) {
        return ''
      }
      return format(new Date(field), DATE_FORMAT)
    },
    mapResponse (i) {
      const firstInterviewDate = this.getFormatDate(i, 'interview1_data.interview_time')
      const secondInterviewDate = this.getFormatDate(i, 'interview2_data.interview_time')
      const statusData = [...this.statuses].find(st => st.id === +i.status)
      const pos1 = `${get(i, 'pos1_data.camp_name')}_${get(i, 'pos1_data.pos')}`
      const pos2 = `${get(i, 'pos2_data.camp_name')}_${get(i, 'pos2_data.pos')}`
      const pos3 = `${get(i, 'pos3_data.camp_name')}_${get(i, 'pos3_data.pos')}`
      const campName = i?.pos_camp_data?.camp_name || ''
      const campId = i?.pos_camp_data?.camp_id
      const posName = i?.pos_camp_data?.pos || ''

      return {
        ...i,
        full_name: `${i.ename || i.fname} ${i.ename2 || i.lname}`,
        first_interview_date: firstInterviewDate,
        second_interview_date: secondInterviewDate,
        status_name: statusData ? statusData.status_name : '',
        status_color: statusData ? `#${statusData.color}` : DEFAULT_STATUS_COLOR,
        camp_name: campName,
        camp_id: campId,
        position_name: posName,
        position: posName,
        positions: `${pos1}${pos2 !== '_' ? ' / ' + pos2 : ''}${pos3 !== '_' ? ' / ' + pos3 : ''}`
      }
    },
    async fetchInterviewList() {
      try {
        await this.$store.dispatch('fetchStaffInterviewList')
      } catch (e) {
        console.log(e)
      }
    },
    onRefreshList() {
      this.resetFilter()
      const filterDefault = this.getFilterData('status', DEFAULT_STATUSES)
      this.selectionsStaff = []
      this.selectedInterview = 'interview1'
      this.getList(filterDefault)
    },
    resetFilter() {
      this.searchModel = {
        phone: '',
        email: '',
        id: '',
        interview1_id: '',
        interview2_id: '',
        camp_id: '',
        position: '',
        status: [...DEFAULT_STATUSES],
        first_name: ''
      }
    },
    checkTab() {
      const param = this.$route.params.candidate
      if (param) {
        const userData = this.tableDataInit.find(i => +i.id === +this.$route.params.candidate)
        if (userData) {
          this.openInTab(userData)
        }
      }
    },
    loadInterviewTemplates () {
      return Messages.getEmailTemplateList(FORM_SYSTEM)
        .then(res => {
          res.forEach((template) => {
            switch (String(template.email_template_id)) {
              case FIRST_INTERVIEW_TEMPLATE_ID:
                this.emailTemplates.first = template
                break
              case SECOND_INTERVIEW_TEMPLATE_ID:
                this.emailTemplates.second = template
                break
              case FIRST_INTERVIEW_SMS_TEMPLATE_ID:
                this.smsTemplates.first = template
                break
              case SECOND_INTERVIEW_SMS_TEMPLATE_ID:
                this.smsTemplates.second = template
            }
          })
        })
    }
  },
  async created() {
    this.loading = true
    await this.loadInterviewTemplates()
    await this.getConfData()
    await this.getForms()
    const filterDefault = this.getFilterData('status', DEFAULT_STATUSES)
    await this.getList(filterDefault)
    this.checkTab()
    await this.fetchInterviewList()
  }
}
</script>

<style lang="scss">
@import '@/styles/mixins';
.candidates-table {
  @extend %tableBaseStyle;
}

.send-actions-block {
  position: fixed;
  right: -120%;
  top: 120px;
  background: white;
  padding: 32px;
  z-index: 6;
  min-width: 336px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  opacity: 0;
  transition: all .5s ease;

  .send-actions {
    padding-top: 20px;
    border-top: 1px solid #E0E2E6;;
  }

  &.is-visible {
    right: 20px;
    opacity: 1;
  }
}

@media only screen and (max-width: 680px) {
  .send-actions-block {
    max-width: 80vw;
    min-width: 65vw;
    &.is-visible {
      right: 4px !important;
    }
    .el-radio-group {
      flex-direction: column;
      display: flex;
      .el-radio {
        margin: 0 0 12px;
        width: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="candidate-template h-full">
    <el-container class="container-page">
      <el-header height="68px" class="candidate-nav-header">
        <Header />
      </el-header>
      <el-container>
        <el-main class="main-content">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from '@/components/layout-components/candidate/Header'

export default {
  name: 'CandidateLayout',
  components: {
    Header
  }
}
</script>

<style lang="scss" scoped>
.candidate-template {
  .container-page {
    height: 100%;
  }
  .candidate-nav-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: white;
    padding: 4px 90px;
    box-sizing: border-box;
    border-bottom: 1px solid #E0E2E6;
    z-index: var(--z-navbar);
  }
  .main-content {
    --el-main-padding: 5px;
    margin-top: var(--el-header-height);
    background: #F7FAFC;
    transition: all .3s linear;
  }
}

@media only screen and (max-width: 780px) {
  .candidate-template {
    .main-content {
      padding-left: 12px;
    }
  }
}
</style>

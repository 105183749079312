<template>
  <ExpandCard class="billing-info-card">
    <template #header>
      <div class="header-top flex items-center pb-1">
        <div class="card-title">
          Billing
        </div>
        <div class="grow flex justify-end header-5 semibold">
          <span class="mr-2">
            Balance:
          </span>
          <span
            :class="billingData.Balance === 0 ? 'text-active' : 'text-destroy'"
            class="space-nowrap"
          >
            {{ `${balanceUsd}$ | ${balanceNis}₪` }}
          </span>
        </div>
      </div>
      <div class="header-bottom w-full pt-2">
        <div class="flex w-full">
          <template v-for="item in headerFields" :key="item.prop">
            <div class="billing-info-item pr-2">
              <div class="mb-1">
                {{ item.title }}
              </div>
              <div class="text-md semibold px-1">
                {{ billingData[item.prop] }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="billing-info-tables">
      <div class="billing-receipt-block">
        <div class="w-full flex justify-between pt-2 pb-5">
          <div class="semibold pr-2">
            Receipts:
          </div>
          <div class="pl-3">
            <el-button @click="openReceiptDialog" type="success">
              <i class="vc-icon-plus-linear icon-lg mr-2"></i>
              Add Receipt
            </el-button>
          </div>
        </div>
        <div class="w-full px-3">
          <el-table
            ref="receiptTable"
            :data="receiptData"
            empty-text="No Data!"
            class="w-full receipt-list-table"
          >
            <el-table-column type="index" width="40" class-name="index-cell"/>
            <TableContent
              :columns="columns"
            >
              <template #header="{col}">
                <table-header
                  :column="col"
                />
              </template>
              <template #children_receipt_price_nis="{row}">
                <span class="semibold">
                  {{ row.children_receipt_price_nis }}
                </span>
              </template>
              <template #children_receipt_description="{row}">
                <span class="extra-small-text word-keep linear-none">
                  {{ row.children_receipt_description }}
                </span>
              </template>
              <template #receipt="{row}">
                <div class="w-full flex px-2">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Receipt File"
                  >
                    <a :href="row.children_receipt_url || '#'" target="_blank" class="mx-1">
                      <i class="vc-icon-download icon-xl text-blue px-1"></i>
                    </a>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="Receipt File Copy"
                  >
                    <a :href="row.children_receipt_url_copy || '#'" target="_blank" class="mx-1">
                      <i class="vc-icon-download icon-xl text-blue px-1"></i>
                    </a>
                  </el-tooltip>
                </div>
              </template>
            </TableContent>
          </el-table>
        </div>
      </div>
      <div class="billing-extra-block">
        <div class="w-full flex justify-between pt-2 pb-5">
          <div class="semibold pr-2">
            Extra List:
          </div>
          <div class="pl-3">
            <el-button @click="openNewExtraDialog" type="success">
              <i class="vc-icon-plus-linear icon-lg mr-2"></i>
              Add Extra
            </el-button>
          </div>
        </div>
        <div class="w-full px-3">
          <el-table
            ref="extraTable"
            :data="extraList"
            empty-text="No Data!"
            class="w-full extra-list-table"
          >
            <el-table-column type="index" width="40" class-name="index-cell"/>
            <TableContent
              :columns="extraColumns"
            >
              <template #header="{col}">
                <table-header
                  :column="col"
                />
              </template>
              <template #children_extra_price="{row}">
                <span class="semibold px-2">
                  {{ row.children_extra_price }}
                </span>
              </template>
              <template #action="{row}">
                <span class="px-2 text-blue pointer icon-base edit-action" @click="onExtraEdit(row)">
                  <i class="vc-icon-edit-pencil"></i>
                </span>
              </template>
            </TableContent>
          </el-table>
        </div>
      </div>
    </div>
    <NewReceiptModal
      ref="newReceiptModalRef"
      v-model="receiptDialog"
      :loading="loading"
      :configs="configs"
      :info-data="info"
      @save="onSaveReceipt"
      @close="closeReceiptModal"
    />
      <ChildrenExtraBillingModal
        ref="childrenExtraBillingModalRef"
        :configs="configs"
        :loading="loading"
        @save="onSaveExtra"
      />
  </ExpandCard>
</template>

<script>
import {format} from 'date-fns'
import ExpandCard from "@/components/pages-components/admin/ExpandCard";
import TableContent from "@/components/pages-components/common/Table/TableContent";
import TableHeader from "@/components/pages-components/common/Table/TableHeader";
import Children from "controllers/Children";
import NewReceiptModal from "@/components/pages-components/admin/Children/Modal/NewReceiptModal.vue";
import ChildrenExtraBillingModal
    from "@/components/pages-components/admin/Children/Modal/ChildrenExtraBillingModal.vue";
import {
    billingExtraTableColumns,
    billingReceiptsTableColumns,
    billingTableColumns,
    EXTRA_MODEL,
    DATE_FORMAT
} from "@/enum/bilingTableColumns";

export default {
  name: "BillingInfoCard",
  components: {ChildrenExtraBillingModal, NewReceiptModal, TableHeader, TableContent, ExpandCard},
  props: {
    childId: {
      type: [String, Number],
      default: ''
    },
    billingData: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    },
    configs: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['updated'],
  data: () => ({
    receiptDialog: false,
    loading: false,
    headerFields: [...billingTableColumns],
    columns: [...billingReceiptsTableColumns],
    extraColumns: [...billingExtraTableColumns]
  }),
  computed: {
    balanceUsd () {
      return this.billingData?.Balance ?  parseFloat(this.billingData?.Balance).toFixed(3) : 0
    },
    balanceNis () {
      return this.billingData?.BalanceNIS ?  parseFloat(this.billingData?.BalanceNIS).toFixed(3) : 0
    },
    receiptData () {
      const list = this.billingData.ReceiptList || []
      return list.map(i => {
        return {
          ...i,
          children_receipt_date: format(new Date(i.children_receipt_date), DATE_FORMAT),
          insert_date: format(new Date(i.insert_date), DATE_FORMAT)
        }
      })
    },
    extraList () {
      const list = this.billingData.ExtraList || []
      return list.map(i => {
        return {
          ...i,
          children_extra_date: format(new Date(i.children_extra_date), DATE_FORMAT)
        }
      })
    }
  },
  methods: {
    openNewExtraDialog () {
      this.openExtraDialog({
          ...EXTRA_MODEL
      })
    },
    openExtraDialog(data) {
      this.$refs.childrenExtraBillingModalRef?.open(data)
    },
    openReceiptDialog() {
      this.receiptDialog = true
    },
    closeReceiptModal () {
      this.receiptDialog = false
      this.$refs.newReceiptModalRef?.resetReceiptData()
    },
    closeExtraModal () {
      this.$refs.childrenExtraBillingModalRef?.close()
    },
    async onSaveExtra (extraModel) {
      try {
        this.loading = true
        const formData = {
          ...extraModel,
          children_extra_child_id: this.childId
        }
        await Children.ExtraUpsert(formData)
        this.$emit('updated', true)
        this.closeExtraModal()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onExtraEdit (extraData) {
      const data = {
        children_extra_date: extraData.children_extra_date,
        children_extra_remarks: extraData.children_extra_remarks,
        children_extra_type_id: extraData.children_extra_category_id,
        children_extra_price: extraData.children_extra_price,
        children_extra_id: extraData.children_extra_id
      }
      this.openExtraDialog(data)
    },
    async onSaveReceipt (newReceiptData) {
      try {
        const formData = {
          ...newReceiptData,
          children_receipt_child_id: this.childId
        }
        await Children.ReceiptAdd(formData)
        this.$emit('updated', true)
        this.closeReceiptModal()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.billing-info-card {
  .header-top {
    border-bottom: 1px solid var(--gray-500);
  }
  .billing-info-item {
    flex: 1 0 auto;
  }
  .cell {
    color: var(--black);
  }
  th .cell {
    color: #1c84ad
  }
  .edit-action {
    transition: all .3s ease;
    i {
      font-weight: 500;
    }
    &:hover {
      i {
        font-weight: 600;
      }
    }
  }
  .billing-extra-block {
    padding-top: 20px;
    padding-bottom: 24px;
  }

  @media only screen and (max-width: 680px) {
    .header-content {
      max-width: calc(100% - 24px);
    }
    .header-top {
      flex-wrap: wrap;
      max-width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    .header-bottom {
      .flex {
        flex-wrap: wrap;
      }
      .billing-info-item {
        margin-bottom: 6px;
      }
    }
  }
}
.new-receipt-form, .billing-extra-form {
  .el-form-item__label {
    color: black;
    padding: 0;
    font-weight: 500;
  }
  .form-field {
    padding: 0 10px;
    flex: 1 0 250px;
    .el-select, .el-input {
      width: 100%;
    }
  }
  .payment-data-block{
    background: #e9eef8;
    padding: 6px 0 8px;
    border-radius: 4px;
  }
}
.billing-extra-form {
  .el-form-item__label {
    padding-right: 20px;
  }
}
</style>

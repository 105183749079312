<template>
  <el-card
    :style="{backgroundColor}"
    shadow="never"
    class="interview-card no-shrink"
  >
    <div class="card-content flex flex-center grow py-3">
      <div class="form-item">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="card-actions flex justify-center py-5">
      <slot name="actions"></slot>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "InterviewCard",
  props: {
    backgroundColor: {
      type: String,
      default: '#F7FAFC'
    }
  }
}
</script>

<style lang="scss" scoped>
.interview-card {
  min-height: 224px;
  width:100%;
  height: 100%;

  :deep(.el-card__body) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>

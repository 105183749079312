<template>
  <el-card v-loading="loading" :class="{'editable': isEdit}" class="form-item candidateInfoCard no-border"
           shadow="never">
    <div class="flex justify-end">
      <div v-if="!isEdit">
        <el-button
          type="primary"
          @click="isEdit = true"
        >
          <span class="icon-base vc-icon-edit-pencil px-2"></span>
        </el-button>
      </div>
      <div v-else>
        <el-button
          @click="onCancel"
        >
          <span class="icon-base vc-icon-close px-2 mx-3"></span>
        </el-button>
        <el-button
          type="success"
          @click="onSaveData"
        >
          <span class="icon-base vc-icon-Save px-2 mx-3"></span>
        </el-button>
      </div>
    </div>
    <template v-for="group in tabs" :key="group.key">
      <div class="form-item-groups px-3">
        <div
          v-for="(category, iCategory) in group.CategoriesList"
          :key="`category-${iCategory}`"
          class="form-item-category">
          <div class="card-title text-blue">
            {{ category.FormCategoryName }}
          </div>
          <div
            v-for="(field, iField) in category.PrameterList"
            :key="`field-${iCategory}-${iField}`"
            class="form-item-field"
          >
            <template v-if="!isEdit">
              <div class="item-label">
                {{ field.parameterPrettyName }}
              </div>
              <div v-if="field.FormParameterTypeName === 'Image'">
                <el-avatar
                  shape="square"
                  :size="64"
                  :src="getParameterValue(field)"
                  class="mr-2 no-shrink"
                />
              </div>
              <div v-else-if="field.FormParameterTypeName === 'Doc'">
                <a
                  :href="replaceUrlStorageDomain(getParameterValue(field))"
                  target="_blank"
                >
                  {{ getLastUrlParam(getParameterValue(field)) }}
                </a>
              </div>
              <div v-else-if="field.FormParameterTypeName === 'SelectBox'" class="text-black item-value">
                {{ getParameterSelectValue(field) }}
              </div>
              <div v-else class="text-black item-value">
                {{ getParameterValue(field) }}
              </div>
            </template>
            <template v-else>
              <el-form
                :model="candidateModel"
                label-position="top"
                class="form w-full"
              >
                <component
                  @set-value="setParameterValue(field, $event)"
                  :ref="field.parameterKey"
                  :model="candidateModel"
                  :key="iField"
                  :is="field.FormParameterCompanentTag"
                  :parameter="field"
                />
              </el-form>
            </template>
          </div>
        </div>
      </div>
    </template>
  </el-card>
</template>

<script>
import jsonpath from "jsonpath";

import ParameterText from '@/components/pages-components/common/parameters/ParameterText'
import ParameterNumber from '@/components/pages-components/common/parameters/ParameterNumber'
import ParameterSelectbox from '@/components/pages-components/common/parameters/ParameterSelectbox'
import ParameterYesNo from '@/components/pages-components/common/parameters/ParameterYesNo'
import ParameterDate from '@/components/pages-components/common/parameters/ParameterDate'
import ParameterImage from '@/components/pages-components/common/parameters/ParameterImage'
import ParameterDoc from '@/components/pages-components/common/parameters/ParameterDoc'
import cloneDeep from 'lodash/cloneDeep'
import Children from "controllers/Children";

const STORAGE_DOMAIN = 'storage.cloud.google.com'
export default {
  name: "ChildInfoCard",
  components: {
    ParameterText,
    ParameterNumber,
    ParameterSelectbox,
    ParameterYesNo,
    ParameterDate,
    ParameterImage,
    ParameterDoc
  },
  props: {
    tabData: {
      type: Object,
      default: () => ({})
    },
    candidate: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isEdit: false,
      activeTab: '',
      candidateModel: {},
      loading: false
    }
  },
  watch: {
    candidate: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.candidateModel = cloneDeep(newVal)
      }
    }
  },
  computed: {
    tabs() {
      return this.tabData.StepList || []
    }
  },
  methods: {
    getParameterSelectValue (field) {
      const formParameterData = field?.FormParameterData || {}
      if (formParameterData.confDataKey) {
        const options = this.$store.getters.getGlobalOptions || {}
        const optionsData = options[formParameterData.confDataKey] || []
        const key = formParameterData.valueKey
        const label = formParameterData.labelKey
        const modelValue = this.getParameterValue(field)
        const option = optionsData.find(i => i[key] === modelValue) || {}
        return option[label] || this.getParameterValue(field)
    } else {
        const options = formParameterData.options
        const option = options.find(i => i.value === this.candidateModel[formParameterData.valueKey])
        return option?.label || this.getParameterValue(field)
      }
    },
    replaceUrlStorageDomain(url) {
      if (!url) return ''

      url = new URL(url)
      url.host = STORAGE_DOMAIN
      return url.toString()
    },
    setParameterValue(parameter, value) {
      jsonpath.value(this.candidateModel, parameter.parameterJPath, value)
    },
    getParameterValue(parameter) {
      return jsonpath.value(this.candidateModel, parameter.parameterJPath)
    },
    onCancel() {
      this.candidateModel = cloneDeep(this.candidate)
      this.isEdit = false
    },
    getLastUrlParam(url) {
      if (!url) return ''

      return url.split('/')[url.split('/').length - 1]
    },
    async onSaveData() {
      try {
        this.loading = true
        const formData = {...this.candidateModel}
        await Children.Upsert(formData)
        this.$emit('refresh-data')
        this.isEdit = false
      } catch (e) {
        console.log('onSaveData', e)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.activeTab = this.tabs[0].StepTag
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";
.candidateInfoCard {
  @extend %infoCardStyles;
}
</style>

<template>
  <el-form-item :label="getParameterPrettyName" :prop="getParameterKey">
    <el-radio
      v-model="parameterValue"
      :label="yesValue"
      v-bind="parameter.FormParameterData"
    >
      {{ yesLabel }}
    </el-radio>
    <el-radio
      v-model="parameterValue"
      :label="noValue"
    >
      {{ noLabel }}
    </el-radio>
  </el-form-item>
</template>

<script>
import parameterMixin from '@/mixins/parameterMixin';

export default {
  name: 'ParameterYesNo',
  mixins: [parameterMixin],
  computed: {
    yesLabel() {
      return this.parameter.FormParameterData.options.yes.name
    },
    noLabel() {
      return this.parameter.FormParameterData.options.no.name
    },
    yesValue() {
      return this.parameter.FormParameterData.options.yes.value
    },
    noValue() {
      return this.parameter.FormParameterData.options.no.value
    }
  }
}
</script>

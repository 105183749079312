<template>
  <div v-if="isVisible" class="recipients-staff-table-wrap">
    <table class="recipients-table">
      <thead>
      <tr>
        <th class="table-cell">#</th>
        <th class="table-cell">Name</th>
        <th class="table-cell">{{ typeField }}</th>
        <th class="table-cell">Preview</th>
        <th class="table-cell">Status</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, i) in dataFields" :key="i">
        <td class="table-cell">{{i + 1}}</td>
        <td class="table-cell">{{ item.full_name }}</td>
        <td class="table-cell">{{ item[typeField] }}</td>
        <td class="table-cell text-center">
          <el-button size="small" type="primary" plain @click="openPreviewMessage(item)">
            <i class="vc-icon-eye"></i>
          </el-button>
        </td>
        <td v-loading="hasLoading(item)" class="table-cell text-center">
          <i v-if="hasSuccess(item)" class="vc-icon-checkmark text-active"></i>
          <i v-else class="vc-icon-cross text-destroy"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "RecipientsStaffTable",
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    dataFields: {
      type: Array,
      default: () => []
    },
    processData: {
      type: Object,
      default: () => ({})
    },
    typeField: {
      type: String,
      default: 'email' // cell or email
    }
  },
  emits: ['preview'],
  methods: {
    openPreviewMessage (data) {
      this.$emit('preview', data)
    },
    hasLoading (item) {
      return (this.processData[item.id]?.status === 'sending')
    },
    hasSuccess (item) {
      return (this.processData[item.id]?.status === 'done')
    }
  }
}
</script>

<style lang="scss" scoped>
  .recipients-staff-table-wrap {
    max-height: 600px;
    overflow-y: auto;
    .recipients-table {
      margin-top: 12px;
      border-collapse: collapse;
      color: black;
      font-size: 16px;
      th {
        font-size: 18px;
        font-weight: 600;
      }
      .table-cell {
        padding: 8px 24px;
        border-bottom: 1px solid #BEC2C9;
      }
    }
  }
</style>

<template>
  <div class="children-summary-page py-3 px-2" v-loading.fullscreen.lock="loading">
    <h6 class="pb-5">Children Summary Page</h6>
    <div class="w-full">
      <template v-for="(round, i) in summaryList"  :key="`round-${i}`">
        <el-card class="card-round mb-8">
          <template #header>
            <span>{{ `Round ${round.round_id} (Total: ${round.total_count})` }}</span>
          </template>
          <div class="card-round-body">
            <el-tree
              :data="getThreeData(round)"
              :props="{children: 'children', label: 'label', class: customNodeClass}"
            ></el-tree>
          </div>
        </el-card>
      </template>
    </div>
  </div>
</template>

<script>
import Children from "controllers/Children";

export default {
  name: "ChildrenSummary",
  data: () => ({
    summaryList: [],
    loading: false
  }),
  methods: {
    customNodeClass (data) {
      if (data.isChild) {
        return 'is-child-item'
      }
      return null
    },
    async getSummary () {
      const { List } = await Children.Summary()
      this.summaryList = List || []
    },
    getThreeData (round) {
      if(!round) {
        return []
      }
      return round.camp_list.map((camp, campIndex) => {
        return {
          id: `camp-${campIndex}`,
          label: `${camp.camp_name} (Total: ${camp.total_count})`,
          children: camp.division_list.map(division => ({
            id: `division-${division.division_id}`,
            label: `${division.division_name} (Total: ${division.total_count})`,
            children: division.status_list.map(status => ({
              id: `status-${status.status_id}`,
              label: `${status.status_name} (Total: ${status.total_count})`,
              children: status.children_list.map(child => ({
                label: ` - ${child.fname_eng} ${child.lname_eng} (${child.fname_heb} ${child.fname_eng})`,
                isChild: true
              }))
            }))
          }))
        }
      })
    }
  },
  mounted() {
    this.getSummary()
  }
}
</script>

<style lang="scss" scoped>
  .children-summary-page {
    .card-round {
      ::v-deep(.el-card__header) {
        font-weight: 600;
      }
      ::v-deep(.el-tree-node__content) {
        height: 40px;
        margin-bottom: 6px;
      }
      ::v-deep(.el-tree-node__label) {
        font-size: 20px;
        font-weight: 500;
        color: black;
      }
      ::v-deep(.el-tree-node__expand-icon:not(.is-leaf)) {
        font-size: 16px;
        color: #1d9bcb;
      }
      ::v-deep(.is-child-item) {
       .el-tree-node__label {
          font-size: 18px;
          font-weight: 500;
          color: var(--dark);
        }
      }
    }
  }
</style>

<template>
  <el-form-item :label="getParameterPrettyName" :prop="getParameterKey">
    <el-tooltip class="item"
                effect="light"
                :disabled="!fileName"
                :content="fileName"
                placement="top">
      <el-upload
        action="#"
        v-loading="loading"
        class="file-uploader"
        v-bind="parameter.FormParameterData"
        :class="{'uploaded': fileName}"
        :show-file-list="false"
        :before-upload="beforeUpload"
      >
        <div
          v-if="fileName"
          class="file-preview flex-center h-full"
        >

          <i class="vc-icon-checkmark text-blue icon-2xl"></i>
        </div>
        <div v-else class="file-uploader-placeholder">
          <i class="vc-icon-upload file-uploader-icon"></i>
          browse file
        </div>
      </el-upload>
    </el-tooltip>
    <span v-if="fileName" class="ml-3 remove-action">
      <i class="vc-icon-recycle-bin text-destroy icon-2xl pointer" @click.stop="onRemove"></i>
    </span>
  </el-form-item>
</template>

<script>
import parameterMixin from '@/mixins/parameterMixin';
import UploadController from 'controllers/Upload'

export default {
  name: 'ParameterDoc',
  mixins: [parameterMixin],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    fileName() {
      if (!this.parameterValue) {
        return null
      }

      const filePathSplit = this.parameterValue.split('/')

      return filePathSplit[filePathSplit.length - 1]
    },
    uploadURL() {
      return this.parameter.FormParameterData.Endpoint
    }
  },
  methods: {
    onRemove () {
      this.parameterValue = ''
    },
    async beforeUpload(file) {
      this.loading = true

      try {
        if (file) {
          this.parameterValue = await UploadController.UploadDoc(file, this.uploadURL)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.file-uploader {
  ::v-deep(.el-upload) {
    background: rgba(212, 227, 255, 0.3);
    border: 2px dashed #20ACE2;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 186px;
    height: 124px;

    &:hover {
      background: rgba(212, 227, 255, 0.8);
    }
  }

  &.uploaded {
    ::v-deep(.el-upload) {
      box-shadow: 0 0 5px #E0E2E5;
      background: white;
      border: none;
    }
  }

  .file-uploader-placeholder {
    color: #20ACE2;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .file-uploader-icon {
      font-size: 40px;
    }
  }

  .file-preview {
    .image {
      width: 136px;
      height: 68px;
      margin: 12px auto;
      display: block;
    }

    .vc-icon-attachment {
      color: #20ACE2;
      font-size: 16px;
      line-height: 20px;
      margin-right: 5px;
    }

    .file-name {
      color: #263148;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      word-break: break-all;
    }
  }
}
.test-style {
  color: aquamarine;
}
</style>

<template>
  <section class="w-full h-full report-pdf-table__wrap pdf-item">
    <div class="flex justify-between items-center" style="margin-bottom: 8px; padding-bottom: 2px; background-color: white; border-bottom: 1px solid #E0E2E6">
      <NavLogoIcon style="height: 48px; width: 80px" />
      <div>
        <span class="semibold">Type:</span> <span class="text-small">{{ category.report_category_name }}</span>
        <span class="semibold">Report:</span> <span class="text-small">{{ report.report_remarks }}</span>
      </div>
    </div>
    <div class="table-wrap">
      <table id="report-pdf-table" class="pdf-item">
        <thead>
        <tr>
          <th
            v-for="(column, i) in tableColumns"
            :key="`col-${i}`"
          >
            {{ column.label }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(row, i) in options"
          :key="`row-${i}`"
          class=""
        >
          <td
            v-for="(column, index) in tableColumns"
            :key="`col-${i}-${index}`"
          >
            {{ row[column.prop] || '---' }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import NavLogoIcon from "@/components/NavLogoIcon.vue";

export default {
  name: "ReportsPdf",
  components: {NavLogoIcon},
  props: {
    tableColumns: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    category: {
      type: Object,
      default: () => ({})
    },
    report: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
.report-pdf-table__wrap {
  -webkit-print-color-adjust: exact !important;
  background-color: white;
  padding: 6px 8px 32px;
  font-size: 14px;
  .text-small {
    font-size: 12px;
    margin-right: 12px;
  }
  .table-wrap {
    padding: 12px 4px 20px;
  }
  thead {
    display: table-row-group;
  }
  table, tr, td, th, tbody, thead  {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
  }
}
#report-pdf-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td, th {
    border: 1px solid #ddd;
    text-align: center;
  }
  tr:nth-child(even){background-color: #f2f2f2;}

  th {
    padding-top: 4px;
    padding-bottom: 2px;
    background-color: #20ACE2;
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
  td {
    font-size: 10px;
    padding: 2px;
  }
}
@media print {
  @page  {
    size: auto; /* auto is the initial value */
    margin: 2mm 4mm 0 0;
  }
  thead
  {
    display: table-header-group;
  }
}
</style>

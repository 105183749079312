const STATUS = {
  FIRST_APPLICATION_STATUS: {
    'Enabled': 1,
    'Interview': 2,
    'Irrelevant': 3,
    'Erased': 4
  },
  STAFF_STATUS: {
    hold: 1,
    ok: 2,
    no: 3,
  }
}

export default STATUS

import {createApp, h} from "vue";
import ReportsGroupedWord from "@/views/admin/Pdf/ReportsGroupedWord.vue";

export const getReportsWordContent = (category, reportInfo, columns, options) => {
  const tempApp = createApp({
    render() {
      return h(ReportsGroupedWord, {
        category, reportInfo, tableColumns: columns, options
      })
    }
  })

  const el = document.createElement('div');
  const mountedApp = tempApp.mount(el)

  return mountedApp.$el.outerHTML
}

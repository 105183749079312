<template>
  <div :class="{'is-visible': visible}" class="send-actions-block">
    <div class="w-full">
      <div class="py-5">
        <div class="label bold pb-3">
          {{ titleBlock }} ({{ selectedItems }})
        </div>
        <div class="flex justify-between send-actions">
          <el-button
            type="primary"
            :disabled="!visible"
            :loading="loading"
            @click="onSendSms"
          >
            <i class="icon-base vc-icon-sms mr-2"/>
            Send SMS
          </el-button>
          <el-button
            type="success"
            :disabled="!visible"
            :loading="loading"
            @click="onSendEmail"
          >
            <i class="icon-base vc-icon-mail mr-2"/>
            Send Email
          </el-button>
        </div>
      </div>
      <div>

      </div>
      <div v-if="$slots['additionalActions']" class="py-5">
        <slot name="additionalActions"></slot>
      </div>
    </div>
    <el-dialog
      v-model="modalEmailsVisible"
      append-to-body
      fullscreen
      class="ChildrenEmailModal"
    >
      <div class="form-wrapper w-full">
        <div
          v-show="!recipientsTableVisible"
          class="email-template-form py-5"
        >
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Select Template:
              </div>
              <el-select
                v-model="template"
                filterable
                value-key="email_template_id"
              >
                <el-option
                  v-for="(template, i) in emailTemplates"
                  :key="`t-email-${i}`"
                  :value="template"
                  :label="template.template_name || `Template ID:${template.email_template_id}`"
                ></el-option>
              </el-select>
            </div>
            <div class="form-item recipients-actions-block">
              <div class="form-label">
                Recipient:
              </div>
              <el-checkbox-group
                v-model="recipients"
              >
                <el-checkbox label="Mother" border />
                <el-checkbox label="Father" border />
                <el-checkbox label="Child" border />
              </el-checkbox-group>
            </div>
          </div>
          <div class="form-row">
            <div class="form-item mb-2">
              <div class="form-label">
                Sender:
              </div>
              <el-input
                v-model="sender"
                autocomplete="off"
              />
            </div>
            <div class="form-item mb-2">
              <div class="form-label">
                Sender Name:
              </div>
              <el-input
                v-model="senderName"
                autocomplete="off"
              />
            </div>
            <div class="form-item mb-2">
              <div class="form-label">
                Subject:
              </div>
              <el-input
                v-model="subject"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Body
              </div>
              <div>
                <html-editor
                  v-model="contentMessage"
                  :configs="{
                    language: {
                      ui: 'en',
                      content: 'he',
                      direction: 'rtl',
                    },
                    htmlSupport: {
                      allow: [
                        // Enables all HTML features, just copied from the docs
                        {
                          name: /.*/,
                          attributes: true,
                          classes: true,
                          styles: true,
                        },
                      ],
                    },
                  }"
                />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Template Name:
              </div>
              <el-input
                v-model="template_name"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="text-black flex items-center text-md" >
            <i class="vc-icon-info text-blue icon-lg mr-2"></i>
            <span v-html="textInfo"></span>
          </div>
        </div>
        <div class="w-full">
          <div class="py-2 actions-block">
            <el-button
              @click="recipientsTableVisible = !recipientsTableVisible"
              plain
              type="primary"
            >
              <i class="vc-icon-account-list mr-2 icon-lg"></i>
              {{ recipientsTableVisible ? 'Hide Recipients' : 'Show Recipients' }}
            </el-button>
            <el-button @click="openPreviewEmail(null)" type="success">
              <i class="vc-icon-template-list mr-2 icon-lg"></i>
              Show Preview
            </el-button>
            <el-button
              @click="saveTemplate"
              type="primary"
            >
              <i class="vc-icon-Save mr-2 icon-lg"></i>
              Save Template
            </el-button>
            <el-popconfirm
              title="Are you sure to delete this?"
              @confirm="deleteTemplate"
            >
              <template #reference>
                <el-button
                  :disabled="!template"
                  type="danger"
                >
                  <i class="vc-icon-recycle-bin mr-2 icon-lg"></i>
                  Delete Template
                </el-button>
              </template>
            </el-popconfirm>
            <el-button
              :disabled="!recipients.length"
              @click="startEmailSending"
              type="primary"
            >
              <i class="vc-icon-skip-arrow mr-2 icon-lg"></i>
              Send Emails
            </el-button>
          </div>
          <div class="w-full flex justify-center">
            <RecipientsTable
              :is-visible="recipientsTableVisible"
              :recipients="recipients"
              :data-fields="dataFields"
              :recipient-fields="EMAIL_FIELDS"
              :process-data="sendingEmailsData"
              name-key="full_name"
              @preview="openPreviewEmail"
            />
          </div>
        </div>
      </div>
      <SidebarList
        :fields="fields"
        class="email-sidebar-fields"
      />
      <template #footer>
        <div class="dialog-footer pb-3 px-5">
          <el-button @click="closeModal">
            <i class="vc-icon-cancel icon-base mr-2"></i>
            Cancel
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="modalSMSVisible"
      append-to-body
      top="2vh"
      class="ChildrenSMSModal"
    >
      <div class="form-wrapper">
        <div
          v-show="!recipientsSMSTableVisible"
          class="sms-template-form pb-8"
        >
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Recipient:
              </div>
              <el-checkbox-group
                v-model="smsRecipientList"
              >
                <el-checkbox label="Mother" border />
                <el-checkbox label="Father" border />
                <el-checkbox label="Child" border />
              </el-checkbox-group>
            </div>
          </div>
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Sender:
              </div>
              <el-input
                name="smsSender"
                v-model="smsSender"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-item">
              <div class="form-label">
                Message:
              </div>
              <el-input
                name="smsBody"
                type="textarea"
                v-model="smsBody"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
        <div class="flex pb-6 px-3 sms-block-actions">
          <el-button
            @click="recipientsSMSTableVisible = !recipientsSMSTableVisible"
            plain
            type="primary"
          >
            <i class="vc-icon-account-list mr-2 icon-lg"></i>
            {{ recipientsSMSTableVisible ? 'Hide Recipients' : 'Show Recipients' }}
          </el-button>
          <el-button @click="openPreviewSms(null)" type="success">
            <i class="vc-icon-template-list mr-2 icon-lg"></i>
            Show Preview
          </el-button>
          <el-button
            :disabled="!smsRecipientList.length"
            @click="startSmsSending"
            type="primary"
          >
            <i class="vc-icon-skip-arrow mr-2 icon-lg"></i>
            Send SMS
          </el-button>
        </div>
        <div class="w-full flex justify-center">
          <RecipientsTable
            :is-visible="recipientsSMSTableVisible"
            :recipients="smsRecipientList"
            :data-fields="dataFields"
            :recipient-fields="SMS_FIELDS"
            :process-data="sendingSmsData"
            name-key="full_name"
            @preview="openPreviewSms"
          />
        </div>
      </div>
      <SidebarList
        :fields="fields"
        class="sms-sidebar"
      />
      <template #footer>
        <div class="dialog-footer pb-3 px-5">
          <el-button @click="closeSMSModal">
            <i class="vc-icon-cancel icon-base mr-2"></i>
            Cancel
          </el-button>
        </div>
      </template>
    </el-dialog>
    <PreviewEmailModal
      v-model="previewVisible"
      :subject="subject"
      :message="contentMessage"
      :object-data="previewModel"
    />
    <el-dialog
      v-model="previewSmsVisible"
      append-to-body
      @closed="checkScroll"
    >
      <div class="flex justify-center items-center h-full p-5 text-lg text-black">
        <div v-html="getSmsContent()" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mustache from 'mustache'
import Messages from "controllers/Messages";
import HtmlEditor from "@/components/HtmlEditor";
import PreviewEmailModal from "@/components/pages-components/admin/Children/PreviewEmailModal";
import SidebarList from "@/components/pages-components/admin/Children/SidebarList";
import RecipientsTable from "@/components/pages-components/admin/Children/RecipientsTable";
import { ElMessage } from 'element-plus'

const DEFAULT_TYPE = 'sms'
const SMS_TYPE = 'sms'
const EMAIL_TYPE = 'email'

const DEFAULT_SENDER = 'office@camp.co.il'

const FORM_SYSTEM = 2
const EMAIL_TEMPLATE = 1
const SMS_TEMPLATE = 2

const EMAIL_FIELDS = {
  Mother: 'email_mother',
  Father: 'email_father',
  Child: 'email'
}
const SMS_FIELDS = {
  Mother: 'cell_mother',
  Father: 'cell_father',
  Child: 'cell'
}

export default {
  name: "SendActionsBlock",
  components: {
    RecipientsTable,
    SidebarList,
    PreviewEmailModal,
    HtmlEditor
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    titleBlock: {
      type: String,
      default: 'Send Actions:'
    },
    dataFields: {
      type: Array,
      default: () => []
    },
    selectedItems: {
      type: Number,
      default: 0
    }
  },
  emits: ['sendSms', 'sendEmail'],
  data: () => ({
    type: DEFAULT_TYPE,
    previewVisible: false,
    previewSmsVisible: false,
    recipientsTableVisible: false,
    recipientsSMSTableVisible: false,
    modalEmailsVisible: false,
    modalSMSVisible: false,
    previewModel: null,
    previewSmsModel: null,
    subject: '',
    sender: DEFAULT_SENDER,
    senderName: '',
    contentMessage: '',
    template: null,
    recipients: [
      'Mother'
    ],
    templateList: [],
    EMAIL_FIELDS,
    SMS_FIELDS,
    sendingEmailsData: {},
    sendingSmsData: {},
    smsBody: '',
    smsSender: '',
    smsRecipientList: [],
    template_name: '',
  }),
  watch: {
    template (val) {
      if (val) {
        this.contentMessage = val.body || '<div>Content</div>'
        this.subject = val.email_subject || ''
        this.template_name = val.template_name
        this.senderName = val.sender_name || ''
        this.sender = val?.sender || DEFAULT_SENDER
      }
    },
    modalEmailsVisible (val) {
      if (!val) {
        this.resetData()
      }
    },
    modalSMSVisible (val) {
      if (!val) {
        this.resetSMSData()
      }
    },
    previewVisible (val) {
      if (!val) {
        this.checkScroll()
      }
    }
  },
  computed: {
    textInfo () {
      return 'Put <b>{{field_name}}</b> to body or/and subject for dynamic replace'
    },
    emailTemplates () {
      return this.templateList.filter(i => i.template_type === EMAIL_TEMPLATE)
    },
    smsTemplates () {
      return this.templateList.filter(i => i.template_type === SMS_TEMPLATE)
    },
    fields () {
      if (this.dataFields.length) {
        return Object.keys(this.dataFields[0]).sort()
      }

      return []
    }
  },
  methods: {
    async deleteTemplate () {
      if (!this.template) {
        return
      }
      try {
        const { email_template_id } = this.template
        await Messages.EmailTemplateDelete(email_template_id)
        ElMessage({
          showClose: true,
          message: 'Email Template was deleted!',
          type: 'success'
        })
        this.resetData()
        await this.getConfigs()
      } catch (e) {
        console.log(e.message)
      }
    },
    async saveTemplate () {
      const data = {
        body: this.contentMessage,
        form_system: FORM_SYSTEM,
        sender: this.sender,
        sender_email: this.senderName,
        sender_name: this.senderName,
        email_subject: this.subject,
        template_name: this.template_name || 'Template ' + new Date().getTime(),
        template_type: EMAIL_TEMPLATE
      }
      try {
        let message = ''
        if (this.template) {
          data.email_template_id = this.template.email_template_id
          await Messages.EmailTemplateUpdate(data)
          message = 'Email Template was updated!'
        } else {
          await Messages.EmailTemplateAdd(data)
          message = 'Email Template was added!'
        }
        ElMessage({
          showClose: true,
          message: message,
          type: 'success'
        })
        await this.getConfigs()
      } catch (e) {
        console.error(e.message)
      }
    },
    resetSMSData () {
      this.type = DEFAULT_TYPE
      this.sendingSmsData =  {}
      this.smsBody =  ''
      this.smsSender =  ''
      this.smsRecipientList =  []
      this.recipientsSMSTableVisible = false
    },
    resetData () {
      this.type = DEFAULT_TYPE
      this.subject = ''
      this.contentMessage = ''
      this.sender = DEFAULT_SENDER
      this.senderName = ''
      this.template = null
      this.recipients = [
        'Mother'
      ]
      this.recipientsTableVisible = false
      this.sendingEmailsData = {}
    },
    getContentRender (template, data) {
      return mustache.render(template, data)
    },
    getRecipients (childData) {
      return this.recipients.map(item => childData[EMAIL_FIELDS[item]].toString().trim()).filter(i => i)
    },
    getSmsRecipients (childData) {
      return this.smsRecipientList.map(item => childData[SMS_FIELDS[item]].toString().trim()).filter(i => i)
    },
    getSmsContent () {
      if (!this.smsBody || !this.previewSmsModel) {
        return ''
      }
      return this.getContentRender(this.smsBody, this.previewSmsModel)
    },
    emailDataItem (childData) {
      const recipients = this.getRecipients(childData)
      return {
        EmailBody: this.getContentRender(this.contentMessage, childData).replaceAll('"', '\''),
        EmailSubject: this.getContentRender(this.subject, childData),
        EmailSender: this.sender,
        EmailSenderName: this.senderName,
        EmailRecipientList: recipients
      }
    },
    smsDataItem (childData) {
      const recipients = this.getSmsRecipients(childData)
      return {
        SmsBody: this.getContentRender(this.smsBody, childData),
        SmsSender: this.smsSender,
        SmsRecipientList: recipients
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async startEmailSending () {
      this.recipientsTableVisible = true
      for (let i = 0; i < this.dataFields.length; i++) {
        const childData = this.dataFields[i] || {}
        try {
          this.sendingEmailsData[childData.id] = { status: 'sending' }
          const sendData = this.emailDataItem(childData)
          if (sendData.EmailRecipientList?.length) {
            await Messages.sendEmail(sendData)
            await this.sleep(1000)
            this.sendingEmailsData[childData.id] = { status: 'done' }
          } else {
            this.sendingEmailsData[childData.id] = { status: 'error' }
          }
        } catch (e) {
          this.sendingEmailsData[childData.id] = { status: 'error' }
          console.log(e)
        }
      }
    },
    async startSmsSending () {
      this.recipientsSMSTableVisible = true
      for (let i = 0; i < this.dataFields.length; i++) {
        const childData = this.dataFields[i] || {}
        try {
          this.sendingSmsData[childData.id] = { status: 'sending' }
          const sendData = this.smsDataItem(childData)
          if (sendData.SmsRecipientList?.length) {
            await Messages.sendSMS(sendData)
            await this.sleep(1000)
            this.sendingSmsData[childData.id] = { status: 'done' }
          } else {
            this.sendingSmsData[childData.id] = { status: 'error' }
          }
        } catch (e) {
          this.sendingSmsData[childData.id] = { status: 'error' }
          console.log(e)
        }
      }
    },
    getEmailList (data) {
      if (!this.recipients.length) {
        return ''
      }
      let field = ''
      this.recipients.forEach((item) => {
        field += ` ${item}: ${data[EMAIL_FIELDS[item]] || '---'}`
      })

      return field
    },
    onSendEmail () {
      this.type = EMAIL_TYPE
      this.modalEmailsVisible = true
    },
    onSendSms () {
      this.type = SMS_TYPE
      this.modalSMSVisible = true
    },
    closeModal () {
      this.modalEmailsVisible = false
    },
    closeSMSModal () {
      this.modalSMSVisible = false
    },
    async getConfigs () {
      const list = await Messages.getEmailTemplateList(FORM_SYSTEM)
      this.templateList = list || []
    },
    openPreviewSms (data = null) {
      if (!data) {
        this.previewSmsModel = this.dataFields[0]
      } else {
        this.previewSmsModel = data
      }
      this.previewSmsVisible = true
    },
    openPreviewEmail (data = null) {
      if (!data) {
        this.previewModel = this.dataFields[0]
      } else {
        this.previewModel = data
      }
      this.previewVisible = true
    },
    checkScroll () {
      if (this.modalEmailsVisible || this.modalSMSVisible) {
        const body = document.body
        if (!body.classList.contains('el-popup-parent--hidden')) {
          body.classList.add('el-popup-parent--hidden')
          body.style.paddingRight = '4px'
        }
      }
    }
  },
  mounted () {
    this.getConfigs()
  }
}
</script>

<style lang="scss">
@mixin formStyles {
  .form-label {
    font-size: 16px;
    color: black;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .form-row {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    flex-wrap: wrap;
    .form-item {
      flex: 1 0 20rem;
      padding: 0 8px;
      max-width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
  .send-actions-block {
    position: fixed;
    right: -120%;
    top: 120px;
    background: white;
    padding: 32px;
    z-index: 6;
    min-width: 336px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    opacity: 0;
    transition: all .5s ease;

    .send-actions {
      padding-top: 20px;
      border-top: 1px solid #E0E2E6;;
    }

    &.is-visible {
      right: 20px;
      opacity: 1;
    }
  }

  .ChildrenEmailModal {
    display: flex;
    flex-direction: column;
    //width: 90vw;
    //max-width: 1300px;
    .el-dialog__body {
      flex-grow: 1;
      display: flex;
    }
    .email-template-form, .actions-block {
      max-width: 960px;
      margin: 0 auto;
    }
    .actions-block {
      display: flex;
      gap: 12px 0;
      flex-wrap: wrap;
    }
    .form-wrapper {
      margin: 0 auto;
      flex: 1 0 auto;
      max-width: calc(100% - 360px);
      padding-right: 12px;
    }
    @include formStyles;
    .ck-editor__editable {
      min-height: 220px;
      max-height: 420px;
      color: black;
      font-size: 16px;
    }
  }
  .ChildrenSMSModal {
    display: flex;
    flex-direction: column;
    width: 90vw;
    max-width: 1300px;
    min-width: 680px;
    .el-dialog__body {
      flex-grow: 1;
      display: flex;
    }
    .form-wrapper {
      margin: 0 auto;
      flex: 1 0 auto;
      max-width: calc(100% - 300px);
      padding-right: 12px;
      @include formStyles;
    }
    .sms-sidebar {
      flex: 0 0 300px;
      .fields-list {
        max-height: 612px;
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .send-actions-block {
      min-width: 200px;
      padding: 16px;
      .send-actions {
        flex-direction: column;
        .el-button {
          margin: 0 0 12px;
        }
      }
    }

    .ChildrenSMSModal {
      min-width: auto;
      max-width: 98vw;
      .sms-sidebar {
        display: none;
      }
      .form-wrapper {
        max-width: 100%;
        padding: 0;
        flex: 1 0 100%;
      }
      .el-checkbox-group {
        flex-direction: column;
        display: flex;
      }
      .el-checkbox {
        margin: 0 0 16px;
      }
      .sms-block-actions {
        flex-direction: column;
        .el-button {
          margin: 0 0 12px;
        }
      }
    }

    .ChildrenEmailModal {
      width: 98vw;
      max-width: 98vw;
      .form-wrapper {
        max-width: 100%;
      }
      .recipients-actions-block {
        margin-top: 12px;
        .el-checkbox-group {
          display: flex;
          flex-direction: column;
          .el-checkbox {
            margin: 0 0 12px;
          }
        }
      }
      .actions-block {
        flex-direction: column;
        .el-button {
          width: 100%;
          margin: 0 0 8px;
        }
      }
      .email-sidebar-fields {
        display: none;
      }
    }
  }
</style>

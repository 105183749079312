export const billingTableColumns = [
    { title: 'CampBasePrice', prop: 'CampBasePrice' },
    { title: 'DivisionExtraPrice', prop: 'DivisionExtraPrice' },
    { title: 'ExtraSum', prop: 'ExtraSum' },
    { title: 'ReceiptSum', prop: 'ReceiptSum' }
]

export const billingReceiptsTableColumns = [
    {
        prop: 'children_receipt_id',
        label: 'ID',
        icon: 'vc-icon-ID_number',
        width: '120px'
    },
    {
        prop: 'children_receipt_date',
        label: 'Receipt Date',
        icon: 'vc-icon-schedule-calendar'
    },
    {
        prop: 'insert_date',
        label: 'Insert Date',
        icon: 'vc-icon-schedule-calendar'
    },
    {
        prop: 'children_receipt_price_nis',
        label: 'Price Nis',
        icon: 'vc-icon-billing'
    },
    {
        prop: 'children_receipt_email',
        label: 'Email',
        icon: 'vc-icon-mail'
    },
    {
        prop: 'children_receipt_type_name',
        label: 'Type',
        icon: 'vc-icon-name'
    },
    {
        prop: 'children_receipt_remarks',
        label: 'Remarks',
        icon: 'vc-icon-notes'
    },
    {
        prop: 'children_receipt_description',
        label: 'Description',
        icon: 'vc-icon-notes'
    },
    {
        prop: 'receipt',
        label: 'Receipt',
        icon: 'vc-icon-attachment'
    }
]

export const billingExtraTableColumns = [
    {
        prop: 'children_extra_category_name',
        label: 'Category',
        icon: 'vc-icon-number-group'
    },
    {
        prop: 'children_extra_date',
        label: 'Date',
        icon: 'vc-icon-schedule-calendar'
    },
    {
        prop: 'children_extra_price',
        label: 'Price',
        icon: 'vc-icon-billing'
    },
    {
        prop: 'children_extra_remarks',
        label: 'Remarks',
        icon: 'vc-icon-notes'
    },
    {
        prop: 'action',
        label: '',
        width: '80px'
    }
]

export const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const CURRENCY_USD = 1
export const CURRENCY_ILS = 2
export const EXTRA_MODEL = {
    children_extra_child_id: '', // current child id
    children_extra_date: '', // for update
    children_extra_remarks: '',
    children_extra_type_id: '',
    children_extra_price: ''
}

export const RECEIPT_MODEL = {
    children_receipt_remarks: '',
    children_receipt_description: '',
    children_receipt_type_id: 1,
    children_receipt_price_nis: '',
    children_receipt_date: '',
    children_receipt_worker_id: 0,
    children_receipt_payment_type_id: '',
    payment_cardType: '',
    payment_cardNum: '',
    payment_transactionId: '',
    payment_bankName: '',
    payment_bankBranch: '',
    payment_bankAccount: '',
    payment_chequeNum: '',
    children_receipt_currency_id: CURRENCY_ILS
}
export const NEGATIVE_RECIPE_TYPE_LIST = [ 2 ]

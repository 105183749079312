export default {
  data: () => ({
    activeTab: 'list',
    tabs: [],
    routeListName: 'CandidatesList',
    routeTabName: 'CandidateInfoTab'
  }),
  watch: {
    async activeTab (newVal) {
      if(newVal === 'list') {
        await this.$router.push({name: this.routeListName})
      } else {
        const id = newVal.split('-')[1]
        if (id) {
          await this.$router.replace({name: this.routeTabName, params: {candidate: id}})
        }
      }
    }
  },
  methods: {
    onTabClick (data) {
      if (+data.index === 0) {
        // this.onRefreshList()
      }
    },
    removeTab (tabName) {
      const currentIndex = this.tabs.findIndex(i => i.tabKey === tabName)
      if (currentIndex >= 1) {
        this.activeTab = this.tabs[currentIndex - 1].tabKey
      } else {
        this.activeTab = 'list'
        // this.onRefreshList()
      }

      this.tabs = this.tabs.filter(i => i.tabKey !== tabName)
    },
    openInTab(data) {
      const tabName = `${data.full_name}`
      const tabKey = `tab-${data.id}`

      if (!this.tabs.find(i => i.tabName === tabName)) {
        this.tabs.push({
          tabName,
          tabKey,
          staffId: data.id
        })

        this.$nextTick(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
          this.activeTab = tabKey
        })
      } else {
        this.activeTab = tabKey
      }
    },
    onRefreshList () {}
  }
}

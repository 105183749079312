import get from 'lodash/get'
import {format} from 'date-fns'
import Staff from "controllers/Staff";
const DATE_FORMAT = 'dd MMM yyyy hh:mm a'

export default {
  data: () => ({
    loading: true,
    candidates: []
  }),
  methods: {
    get,
    getInterviewDate(data) {
      const key = 'interview2_data.interview_time'
      const time = get(data, key, '')
      if (!time) {
        return '---'
      }
      return format(new Date(time), DATE_FORMAT)
    },
    async getList() {
      try {
        const filter = {isPdf: 1}
        const {staff} = await Staff.List(filter)
        this.candidates = staff
          ? staff.sort(function (a, b) {
            const key = 'interview2_data.interview_time'
            const fieldA = get(a, key);
            const fieldB = get(b, key);
            if(fieldA && fieldB) {
              return new Date(fieldA).getTime() - new Date(fieldB).getTime()
            }
            return 1
          })
          : []
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}

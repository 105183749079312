<template>
  <div class="children-info-tab px1 pb-8">
    <div class="w-full mb-6">
      <div class="w-full flex-center mb-5">
        <div class="header-3 bold text-center grow">
          {{ `${childInfo.fname_heb} ${childInfo.lname_heb}` }}
        </div>
        <div>
          <el-button
            type="success"
            :loading="loading"
            @click="onOpenEmail"
          >
            <i class="icon-base vc-icon-mail mr-2"/>
            Send Email
          </el-button>
        </div>
      </div>
      <div class="top-cards-wrap">
        <el-card class="main-info-card">
          <template #header>
            <div class="card-header flex justify-between">
              <div class="text-black card-title pr-5">
                Main Info
              </div>
              <div class="flex items-center">
                <div class="mr-2">Status:</div>
                <div class="card-title">
                  {{ childInfo.status_info?.name }}
                </div>
              </div>
            </div>
          </template>
          <div class="w-full card-content">
            <table class="parents-info-table mb-5">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td data-label="" class="td-main">Mother</td>
                <td data-label="Name">
                  {{ childInfo.mother_name_heb }}
                </td>
                <td data-label="Email">
                  {{ childInfo.email_mother }}
                </td>
                <td data-label="Phone">
                  <a :href="`tel:${childInfo.cell_mother}`" class="text-black">
                    {{ childInfo.cell_mother }}
                  </a>
                </td>
              </tr>
              <tr>
                <td data-label="" class="td-main">
                  Father
                </td>
                <td data-label="Name">
                  {{ childInfo.father_name_heb }}
                </td>
                <td data-label="Email">
                  {{ childInfo.email_father }}
                </td>
                <td data-label="Phone">
                  <a :href="`tel:${childInfo.cell_father}`" class="text-black">
                    {{ childInfo.cell_father }}
                  </a>
                </td>
              </tr>
              <tr>
                <td data-label="" class="td-main">
                  <span>Child</span>
                </td>
                <td data-label="">
                  {{ childInfo.fname_heb }}
                </td>
                <td data-label="Email">
                  {{ childInfo.email }}
                </td>
                <td data-label="Phone">
                  <a :href="`tel:${childInfo.cell}`" class="text-black">
                    {{ childInfo.cell }}
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="field-info-item mt-3">
              <div class="label">Passport:</div>
              <div class="pl-3 mb-1" v-if="!childInfo.passport">
                <i class="vc-icon-cross text-destroy icon-xl"></i>
              </div>
              <div class="pl-3 mb-1" v-else>
                <el-image
                  style="width: 35px; height: 35px; display: inline-flex"
                  src="/images/eye.svg"
                  :preview-src-list="[childInfo.passport]"
                  fit="cover"
                />
              </div>
            </div>
          </div>
        </el-card>
        <div class="top-configs-card">
          <el-card v-loading="loading">
            <template #header>
              <div class="card-header flex justify-end">
                <div v-if="!isTopFormEdit" class="">
                  <el-button type="primary" @click="isTopFormEdit = true">
                    <i class="vc-icon-edit-pencil"></i>
                  </el-button>
                </div>
                <div v-else class="">
                  <el-button type="default" @click="cancelTopForm">
                    <i class="vc-icon-close"></i>
                  </el-button>
                  <el-button type="success" @click="onSubmit">
                    <i class="vc-icon-Save"></i>
                  </el-button>
                </div>
              </div>
            </template>
            <div class="top-form">
              <el-form v-model="topForm" label-width="150px" label-position="top" :inline="true" class="top-form" :disabled="!isTopFormEdit">
                <el-form-item label="Status" prop="status_id">
                  <el-select v-model="topForm.status_id">
                    <el-option
                      v-for="option in statuses"
                      :key="option.id"
                      :value="option.id"
                      :label="option.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Grade" prop="grade_id">
                  <el-select v-model="topForm.grade_id">
                    <el-option
                      v-for="option in gradeList"
                      :key="option.id"
                      :value="option.id"
                      :label="option.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Gender" prop="gender">
                  <el-select v-model="topForm.gender">
                    <el-option
                      v-for="option in genders"
                      :key="option.id"
                      :value="option.key"
                      :label="option.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Division" prop="division_id">
                  <el-select v-model="topForm.division_id">
                    <el-option
                      v-for="option in divisions"
                      :key="option.id"
                      :value="option.id"
                      :label="option.name"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Round" prop="round_id">
                  <el-select v-model="topForm.round_id">
                    <el-option
                      v-for="option in rounds"
                      :key="option.id"
                      :value="option.id"
                      :label="option.name || option.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Bank Num" prop="bank_num">
                  <el-input
                    v-model.number="topForm.bank_num"
                    :maxlength="5"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <el-card v-loading="rLoading">
            <template #header>
              <div class="flex items-center justify-between">
                <div class="card-title">
                  Remarks:
                </div>
                <div class="flex grow justify-end">
                  <div v-if="!isRemarksEdit" class="">
                    <el-button type="primary" @click="isRemarksEdit = true">
                      <i class="vc-icon-edit-pencil"></i>
                    </el-button>
                  </div>
                  <div v-else class="">
                    <el-button type="default" @click="cancelRemarks">
                      <i class="vc-icon-close"></i>
                    </el-button>
                    <el-button type="success" @click="onSaveRemarks">
                      <i class="vc-icon-Save"></i>
                    </el-button>
                  </div>
                </div>
              </div>
            </template>
            <div class="w-full">
              <div v-if="!isRemarksEdit">
                {{ childInfo.remarks || '---' }}
              </div>
              <div v-else>
                <div class="form-field">
                  <el-input
                    v-model="remarks"
                    autocomplete="off"
                    type="textarea"
                    :rows="2"
                  />
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <div class="w-full mb-5">
    <div class="w-full mb-5">
      <BillingInfoCard
        :child-id="childInfo.id"
        :billing-data="childInfo.billing_info"
        :info="{
          fullName: childInfo.full_name,
          round: childInfo.round,
          campName: childInfo.camp
        }"
        :configs="{
          ReceiptTypeList: configs.ReceiptTypeList,
          PaymentTypeList: configs.PaymentTypeList,
          PaymentCardTypeList: configs.PaymentCardTypeList,
          ExtraCategoryList: configs.ExtraCategoryList,
          NISInfo: configs.NISInfo || {}
        }"
        @updated="refreshData"
      />
    </div>
    <div class="w-full">
      <DivideInfoCard
        :child-id="childInfo.id"
        :divides="childInfo.divide_list"
        :configs="{
          DvideTypeList: configs.DvideTypeList,
          DvidePlaceList: configs.DvidePlaceList
        }"
        @updated="refreshData"
      />
    </div>
    </div>
    <div class="w-full">
      <div class="forms-info-wrapper">
        <el-tabs v-model="activeTab" type="border-card">
          <el-tab-pane
            v-for="(form, i) in formParams"
            :key="`form-${i}`"
            :name="i"
            :label="form.FormName"
          >
            <ChildInfoCard
              :tab-data="form"
              :candidate="childInfo"
              @refresh-data="refreshData"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <child-email-dialog
      v-model="emailModal"
      :template-list="templateList"
      :data-fields="dataFields"
      :child-data="childInfo"
      @update:template="getEmailConfigs"
    />
  </div>
</template>

<script>
import {format} from 'date-fns'
import Children from "controllers/Children";
import ChildInfoCard from "@/components/pages-components/admin/Children/ChildInfoCard";
import BillingInfoCard from "@/components/pages-components/admin/Children/BillingInfoCard";
import DivideInfoCard from "@/components/pages-components/admin/Children/DivideInfoCard";
import ChildEmailDialog from "@/components/pages-components/admin/Children/ChildEmailDialog";
import Messages from "controllers/Messages";

const DATE_FORMAT = 'dd MMM yyyy hh:mm a'
const ORDER_STATUS_FIELD = 'status_order'

export default {
  name: "ChildTabComponent",
  components: {ChildEmailDialog, DivideInfoCard, BillingInfoCard, ChildInfoCard},
  props: {
    tabData: {
      type: Object,
      default: () => ({})
    },
    childData: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: [Array, Object],
      default: () => ({})
    },
    configs: {
      type: [Array, Object],
      default: () => ({})
    }
  },
  emits: ['list-refresh'],
  data: () => ({
    loading: false,
    rLoading: false,
    candidateInfoData: {},
    activeTab: 0,
    topForm: {
      status_id: '',
      grade_id: '',
      gender: '',
      division_id: '',
      round_id: '',
      bank_num: ''
    },
    isTopFormEdit: false,
    isRemarksEdit: false,
    genders: [
      {key: 'm', label: 'M'},
      {key: 'f', label: 'F'}
    ],
    remarks: '',
    emailModal: false,
    templateList: []
  }),
  watch: {
    childData() {
      this.setTopForm()
    },
    'topForm.grade_id'() {
      this.setDefaultDivision()
    },
    'topForm.gender'() {
      this.setDefaultDivision()
    },
  },
  computed: {
    rounds () {
      return this.configs?.rounds
    },
    childStatus () {
      return this.childInfo?.status_info || {}
    },
    formParams () {
      const formIds = this.childStatus.FormList || []
      return this.params.filter(i => formIds.includes(i.FormID))
    },
    childInfo () {
      return this.childData || {}
    },
    statuses () {
      const statuses = this.configs?.StatusList || []
      return statuses.filter(this.filterStatuses).sort(this.sortStatuses)
    },
    gradeList () {
      return this.configs.GradeList
    },
    divisions () {
      return this.configs.DvisionList
    },
    dataFields () {
      return Object.keys(this.childInfo).sort()
    }
  },
  methods: {
    onOpenEmail () {
      this.emailModal = true
    },
    cancelRemarks () {
      this.remarks = this.childInfo.remarks
      this.isRemarksEdit = false
    },
    async onSaveRemarks () {
      try {
        this.rLoading = true
        const formData = {id: this.childInfo.id, remarks: this.remarks}
        await Children.Upsert(formData)
        this.isRemarksEdit = false
        this.refreshData()
      } finally {
        this.rLoading = false
      }
    },
    filterStatuses (item) {
      return !!(+item.is_show && +item.status_isprint)
    },
    setDefaultDivision () {
      if (!this.topForm.division_id && this.topForm.gender && this.topForm.grade_id) {
        const divisions = this.configs.DvisionList || []
        const division = divisions.find(i => i.gender === this.topForm.gender && i.grades.includes(this.topForm.grade_id))
        this.topForm.division_id = division?.id || 0
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const formData = {...this.topForm}
        await Children.Upsert(formData)
        this.refreshData()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    cancelTopForm () {
      this.isTopFormEdit = false
      this.setTopForm()
    },
    sortStatuses(a,b) {
      return a[ORDER_STATUS_FIELD] - b[ORDER_STATUS_FIELD]
    },
    async fetchInfo() {
      try {
        const response = await Children.Info(this.tabData.staffId)
        this.candidateInfoData = response || {}
      } catch (e) {
        console.log(e)
      }
    },
    getFormatDate(field) {
      if (!field) {
        return ''
      }
      return format(new Date(field), DATE_FORMAT)
    },
    refreshData() {
      this.isTopFormEdit = false
      this.$emit('list-refresh', this.tabData.staffId)
    },
    setTopForm() {
      const { id, status_id, grade_id, gender, division_id, round_id, remarks, bank_num  } = this.childInfo
     this.topForm = {
       id,
       status_id,
       grade_id,
       gender,
       division_id,
       round_id,
       bank_num
     }
     this.remarks = remarks
    },
    async getEmailConfigs () {
      const formSystem = 2
      const list = await Messages.getEmailTemplateList(formSystem)
      this.templateList = list || []
    }
  },
  mounted() {
    this.setTopForm()
    this.getEmailConfigs()
  }
}
</script>

<style lang="scss" scoped>
  .children-info-tab {
    .top-cards-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;
    }
    .main-info-card, .top-configs-card {
      flex: 1 0 30rem;
      max-width: 100%;
    }
    .main-info-card {
      flex-grow: 1;
      .card-header {
        flex-wrap: wrap;
        gap: 12px;
      }
      .card-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }
      .field-info-item {
        margin-bottom: 16px;
      }
      .label {
        font-weight: 600;
        margin-bottom: 8px;
        font-size: 18px;
      }
    }
    .top-configs-card {
      flex-grow: 2;
    }
    .top-form {
      .el-select {
        --el-disabled-text-color: black;
        --el-select-input-font-size: 16px;
        font-weight: 500;
      }
      flex-wrap: wrap;
      ::v-deep(.el-form-item__label) {
        font-size: 16px;
        font-weight: 500;
        color: var(--black)
      }
      .el-form-item {
        flex: 0 0 18rem;
        ::v-deep(.el-select) {
          width: 100%;
        }
      }
      ::v-deep(.el-input__inner) {
        color: black;
        font-weight: 500;
        font-size: 16px;
      }
      ::v-deep(.el-input.is-disabled .el-input__inner) {
        background-color: #fbfbfb;
      }
    }

    .forms-info-wrapper {
      ::v-deep(.el-tabs--border-card) {
        &  > .el-tabs__header .el-tabs__item {
          color: var(--dark);
          &:hover {
            color: var(--blue);
          }

          &.is-active {
            font-weight: 600;
            color: var(--blue);
          }
        }
      }
    }
    .parents-info-table {
      width: 100%;
      table-layout: auto;
      border-collapse: separate;

      td {
        padding: 8px 4px;
        border-bottom: 1px dashed #BEC2C9;
        text-align: center;
        color: black;
        font-size: 1rem;
        min-width: 80px;
        &.td-main {
          font-weight: 600;
          text-align: start;
        }
        &:first-child {
          width: 100px;
        }
        .hidden {
          display: none;
        }
      }
      th {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 8px;
        &:first-child {
          width: 100px;
        }
      }
    }
  }

  @media only screen and (max-width: 680px) {
    .children-info-tab {
      .parents-info-table {
        thead {
          display: none;
        }
        tr {
          display: block;
          border: 1px solid #BEC2C9;
          padding: 12px;

          & + tr {
            margin-top: 12px;
          }
        }

        td {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: 1px solid #BEC2C9;
          font-size: 16px;

          &:before {
            content: attr(data-label);
            font-size: .90em;
            text-align: left;
            font-weight: bold;
            text-transform: uppercase;
            max-width: 45%;
            color: #545454;
          }

          & + td {
            margin-top: .8em;
          }

          &:last-child {
            border-bottom: 0;
          }
          &.td-main {
            width: auto;
            justify-content: center;
          }
          .hidden {
            display: block;
          }
        }
      }
      .top-form {
        .el-form-item {
          flex: 1 0 100%
        }
      }
    }
  }
</style>

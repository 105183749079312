<template>
  <div class="login-page flex">
    <div class="form-wrap" v-loading="loading">
      <div class="logo flex-center">
        <img class="logo-img" src="/images/icon.svg" alt="nav_logo" width="122" height="76">
      </div>
      <div class="info-text header-5 text-center">
        <template v-if="resetPasswordView">
          Enter the email address associated with your account and we'll send you a new password
        </template>
        <template v-else>
          Enter your log and pass data that you receive in SMS
        </template>
      </div>
      <div class="form-card">
        <el-form
          v-if="resetPasswordView"
          ref="resetFormRef"
          :model="resetModel"
          :rules="resetRules"
        >
          <el-form-item prop="email">
            <el-input
              v-model="resetModel.email"
              type="email"
              placeholder="Email"
              @keyup.enter="onSendReset"
            />
          </el-form-item>
          <el-form-item>
            <div class="flex justify-center w-full">
              <el-link
                @click="resetPasswordView = false"
                type="primary"
              >
                Log In
              </el-link>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              color="#EC444D"
              type="primary"
              size="large"
              @click="onSendReset"
              style="width: 100%"
            >
              Send
            </el-button>
          </el-form-item>
        </el-form>
        <el-form
          v-else
          ref="formRef"
          :model="model"
          :rules="rules"
        >
          <el-form-item prop="user">
            <el-input
              v-model="model.user"
              type="email"
              placeholder="Login"
              @keyup.enter="onSubmit"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="model.password"
              type="password"
              placeholder="Password"
              @keyup.enter="onSubmit"
            />
          </el-form-item>
          <el-form-item>
            <div class="flex justify-between w-full">
              <el-checkbox
                v-model="model.remember"
                label="Remember Me"
              />
              <el-link
                @click="resetPasswordView = true"
                type="primary"
              >
                Forgot Password
              </el-link>
            </div>
          </el-form-item>
          <el-form-item v-if="!resetPasswordView">
            <el-button color="#EC444D" type="primary" size="large" @click="onSubmit" style="width: 100%">Login</el-button>
          </el-form-item>
          <el-form-item v-else>
            <el-button color="#EC444D" type="primary" size="large" @click="onSendReset" style="width: 100%">Send</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bg-right-wrap">
      <img src="/images/login-image.png" alt="login-group" width="812" height="580" class="login-group-image">
    </div>
  </div>
</template>

<script>
import StaffMyController from 'controllers/StaffMy'
import authEnum from '@/util/authEnum'
import { ElMessage } from 'element-plus'

export default {
  name: "LoginPage",
  data () {
    return {
      model: {
        user: '',
        password: '',
        remember: false
      },
      resetModel: {
        email: ''
      },
      rules: {
        user: [
          {
            type: 'email',
            required: true,
            message: 'Field is required',
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            message: 'Field is required',
            trigger: 'change'
          }
        ]
      },
      resetRules: {
        email: [
          {
            required: true,
            message: 'Field is required',
            trigger: 'change'
          },
          {
            type: 'email',
            message: 'Not an email',
            trigger: 'change'
          }
        ]
      },
      resetPasswordView: false,
      loading: false
    }
  },
  watch: {
    resetPasswordView(newVal) {
      if (newVal) {
        this.resetModel.email = this.model.user
      }
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.formRef.validate()

      if (!isValid) {
        return
      }

      this.loading = true

      try {
        const {Data} = await StaffMyController.Login(this.model)

        authEnum.setAccessToken(Data.Token)

        await this.$router.push('/candidate/')
      } finally {
        this.loading = false
      }
    },
    async onSendReset() {
      const isValid = await this.$refs.resetFormRef.validate()

      if (!isValid) {
        return
      }

      this.loading = true

      try {
        await StaffMyController.ResetPassword(this.resetModel.email)
        ElMessage({
          showClose: true,
          message: 'Reset password successfully sent',
          type: 'success',
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-page {
    background: #F7FAFC;
    width: 100%;
    height: 100vh;
    position: relative;
    .logo {
      margin-bottom: 120px;
    }
    .info-text {
      margin-bottom: 60px;
    }
    .form-wrap {
      width: 572px;
      height: 100%;
      background: #F5F6FA;
      flex-shrink: 0;
      margin-left: 160px;
      padding: 24px 80px;
    }
    .bg-right-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      background-image: url("/images/vector-2.svg"), url("/images/vector-1.svg");
      background-repeat: no-repeat;
      background-position: top right, bottom right;
      background-size: 65%, 65%;
      display: flex;
      align-items: center;
      padding-left: 86px;
      .login-group-image {
        width: 812px;
        height: auto;
        max-width: calc(100% - 86px);
      }
    }

    @media only screen and (max-width: 1500px) {
      .form-wrap {
        margin-left: 0;
      }
      .bg-right-wrap {
        .login-group-image {
          max-width: calc(80% - 86px);
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      .logo {
        margin-bottom: 60px;
      }
      .form-wrap {
        margin: 0 auto;
      }
      .bg-right-wrap {
        display: none;
      }
    }
    @media only screen and (max-width: 600px) {
      .form-wrap {
        max-width: 100%;
        padding: 24px 32px;
      }
    }
  }
</style>

<template>
  <el-form-item :label="getParameterPrettyName" :prop="getParameterKey">
    <el-upload
      action="#"
      class="image-uploader"
      v-bind="parameter.FormParameterData"
      :class="{'uploaded': imagePreview}"
      :show-file-list="false"
      :before-upload="beforeUpload"
    >
      <div
        v-if="imagePreview"
        class="image-preview"
      >
        <img
          :src="imagePreview"
          alt="image"
          class="image"
        >
        <i class="vc-icon-attachment"></i>
        <span class="image-name">
          {{ imageName }}
        </span>
      </div>
      <div v-else class="image-uploader-placeholder">
        <i class="vc-icon-upload image-uploader-icon"></i>
        browse file
      </div>
    </el-upload>
  </el-form-item>
</template>

<script>
import parameterMixin from '@/mixins/parameterMixin';
import UploadController from 'controllers/Upload'

export default {
  name: 'ParameterImage',
  mixins: [parameterMixin],
  data() {
    return {
      imageName: ''
    }
  },
  computed: {
    imagePreview() {
      return this.parameterValue || null
    }
  },
  methods: {
    async beforeUpload(file) {
      this.loading = true

      try {
        if (file && file.type.includes('image')) {
          this.imageName = file.name

          this.parameterValue = await UploadController.UploadPic(file)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-uploader {
  :deep(.el-upload) {
    background: rgba(212, 227, 255, 0.3);
    border: 2px dashed #20ACE2;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 186px;
    height: 124px;

    &:hover {
      background: rgba(212, 227, 255, 0.8);
    }
  }

  &.uploaded {
    :deep(.el-upload) {
      box-shadow: 0 0 5px #E0E2E5;
      background: white;
      border: none;
    }
  }

  .image-uploader-placeholder {
    color: #20ACE2;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .image-uploader-icon {
      font-size: 40px;
    }
  }

  .image-preview {
    .image {
      width: 136px;
      height: 68px;
      margin: 12px auto;
      display: block;
    }

    .vc-icon-attachment {
      color: #20ACE2;
      font-size: 16px;
      line-height: 20px;
      margin-right: 5px;
    }

    .image-name {
      color: #263148;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
</style>

import {instanceCookie} from '@/api/axios'
import removeUnneededStaffParams from "@/util/removeUnneededStaffParams";

const PREFIX = '/Children'
const PREFIX_BILLING = '/ChildrenBilling'
const PREFIX_EXTRA = '/ChildrenExtra'
const PREFIX_DIVIDE = '/ChildrenDivide'

export default {
  async List(filter = {}) {
    const { data } = await instanceCookie.post(`${PREFIX}/List/`, filter)

    return data.Data || {}
  },

  async Upsert(formData) {
    const { data } = await instanceCookie.post(`${PREFIX}/Upsert/`, removeUnneededStaffParams(formData))
    return data
  },

  async Info(childId) {
    const { data } = await instanceCookie.get(`${PREFIX}/Info/${childId}`)
    return data.Data || {}
  },

  async Summary() {
    const { data } = await instanceCookie.get(`${PREFIX}/Summary/`)
    return data.Data || {}
  },

  BulkSendSMS(data) {
    return instanceCookie.post(`${PREFIX}/BulkSendSMS/`, data)
  },

  BulkSendEmail(data) {
    return instanceCookie.post(`${PREFIX}/BulkSendEmail/`, data)
  },

  async GetConfData() {
    const { data } = await instanceCookie.get(`${PREFIX}/ConfData/`)
    return data.Data || {}
  },

  async ReceiptAdd(formData) {
    const { data } = await instanceCookie.post(`${PREFIX_BILLING}/ReceiptAdd/`, removeUnneededStaffParams(formData, true))
    return data
  },

  async ExtraUpsert(formData) {
    const { data } = await instanceCookie.post(`${PREFIX_EXTRA}/Upsert/`, removeUnneededStaffParams(formData))
    return data
  },

  async DivideUpsert(formData) {
    const { data } = await instanceCookie.post(`${PREFIX_DIVIDE}/Upsert/`, removeUnneededStaffParams(formData))
    return data
  }
}

<template>
  <div v-loading.fullscreen.lock="loading" class="pdf-page-wrap w-full">
    <template v-for="(candidate, index) in candidates" :key="candidate.id">
      <table class="staff-info-table w-full">
        <thead>
        <tr>
          <th colspan="2">
            <div class="top-head-block">
              <div class="top-head-container flex justify-between bg-white">
                <div class="header-info flex">
                  <div>
                    #{{ index + 1 }}
                  </div>
                  <div class="card-title">
                    {{ getCandidateName(candidate) }}
                  </div>
                  <div class="">
                    ({{ `ID: ${candidate.id}` }})
                  </div>
                </div>
                <div class="sidebar-logo flex-center">
                  <router-link to="/admin">
                    <NavLogoIcon class="logo-icon" style="height: 52px; width: 80px"/>
                  </router-link>
                </div>
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="interview-info">
          <td colspan="2">
            <div class="interview-info-block">
              <div class="interview-info-block-content flex items-center">
                <div class="avatar-block">
                  <el-avatar :src="candidate.staff_pic_url" :size="120" shape="square"/>
                </div>
                <div class="interview-info px-5">
                  <table class="interview-table">
                    <tbody>
                    <tr>
                      <td>Interview Time:</td>
                      <td class="td-val">
                        {{ getInterviewDate(candidate) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Interviewer:</td>
                      <td class="td-val">
                        {{ candidate.interview2_data?.interviewer_name || emptyField }}
                      </td>
                    </tr>
                    <tr>
                      <td>First Position:</td>
                      <td class="td-val">
                        {{ candidate.pos1_data?.pos || emptyField }}
                      </td>
                    </tr>
                    <tr>
                      <td> Second Position:</td>
                      <td class="td-val">
                        {{ candidate.pos2_data?.pos || emptyField }}
                      </td>
                    </tr>
                    <tr>
                      <td>Third Position:</td>
                      <td class="td-val">
                        {{ candidate.pos3_data?.pos || emptyField }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
        <tbody class="section-details">
        <template v-for="form in candidateForms" :key="form.FormID">
          <tr class="divider" v-if="form.FormName !== 'General'">
            <td colspan="2">{{ `${form.FormName}:` }}</td>
          </tr>
          <template v-for="(field, i) in form.Fields" :key="`${field.ParameterID}-${i}`">
            <tr>
              <td class="label">
                {{ `${field.parameterPrettyName}:` }}
              </td>
              <td class="info-value">
                {{ candidate[field.parameterKey] }}
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </template>
    <div
      v-if="!candidates.length && !loading"
      class="w-full h-full flex-center text-lg py-5"
    >
      No Records!
    </div>
  </div>
</template>

<script>
import NavLogoIcon from "@/components/NavLogoIcon";
import pdfPreviewMixin from "@/mixins/pdfPreviewMixin";

import FormsController from 'controllers/Forms'
import {mapForms} from "@/util/getCandidateFormsParsed";
import Staff from "controllers/Staff";

const EMPTY_FIELD = '---'

const FILTER_FIELDS = [
  'staff_pic_url',
  'id',
  'lname',
  'fname'
]

const FILTER_FORMS = [
  'Not Relevant',
  'Flight',
  'Insurance',
  'DS2019'
]

export default {
  name: "StaffFullInfo",
  components: {NavLogoIcon},
  mixins: [pdfPreviewMixin],
  data() {
    return {
      emptyField: EMPTY_FIELD,
      candidateForms: []
    }
  },
  methods: {
    getCandidateName (candidate) {
      if (candidate.ename) {
        return `${candidate.ename} ${candidate.ename2}`
      }
      return `${candidate.fname} ${candidate.lname}`
    },
  async getParams () {
    try {
      const candidateForms = await FormsController.ParametersList(3)

      if (candidateForms.length > 0) {
        this.candidateForms = mapForms(candidateForms).map(i => {
          const fields = []
          i.StepList.forEach((j) => {
            j.CategoriesList.forEach(cat => {
              fields.push([...cat.PrameterList])
            })
          })
          return {
            FormID: i.FormID,
            FormName: i.FormName,
            Fields: fields.flat().filter(f => !FILTER_FIELDS.includes(f.parameterKey))
          }
        }).filter(form => !FILTER_FORMS.includes(form.FormName))
      }
    } catch (e) {
      console.error(e)
    }
  },
    async getOneStaff() {
      const id = this.$route.query.StaffID
      try {
        const { info, interview2_data, pos1_data, pos2_data, pos3_data } = await Staff.Info(id)
        info.interview2_data = interview2_data
        info.pos1_data = pos1_data
        info.pos2_data = pos2_data
        info.pos3_data = pos3_data
        this.candidates = [info]
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getParams()
    if(this.$route.query.StaffID) {
      this.getOneStaff()
    } else {
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-page-wrap {
  padding: 0;

  .top-head-block {
    width: 100%;
    background: white;
    padding: 20px 0;
  }

  .header-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  table.interview-table {
    width: 100%;

    td {
      padding: 2px;
      color: black;
      font-weight: 500;
    }

    td:not(.td-val) {
      width: auto;
    }

    td.td-val {
      width: max-content;
      max-width: 70%;
      font-weight: 600;
      color: black;
      padding-left: 8px;
    }
  }

  table.staff-info-table {
    border-collapse: collapse;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #263148;
    page-break-after: always;

    tr.interview-info {
      background: #F5F6FA;
      border-bottom: 1px solid #BEC2C9;
    }

    thead {
      border-bottom: 1px solid #BEC2C9;
    }

    td {
      padding: 4px 12px;
      width: 50%;
      font-size: 14px;
      line-height: 130%;

      &.label {
        color: black;
        font-weight: 500;
      }
      &.info-value {
        color: black;
        font-weight: 500;
      }
    }

    tbody.section-details {
      tr:first-child {
        td {
          padding-top: 20px;
        }
      }

      tr:not(.divider):nth-of-type(even) {
        background: #F5F6FA;
      }
    }

    tr.divider {
      background: #F5F6FA;
      border-top: 1px solid #BEC2C9;
      border-bottom: 1px solid #BEC2C9;

      td {
        padding: 12px 10px;
        font-weight: 600;
      }
    }

    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}

@media print {
  .pdf-page-wrap {
    -webkit-print-color-adjust: exact !important;

    table.staff-info-table {
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}
</style>

import {format} from 'date-fns'

const formOrderEnum = {
  "0": "red",
  "1": "yellow",
  "2": "yellow",
  "3": "green",
  enum: {
    FORM_ORDER_KEY: 'form_order',
    FORM_LAST_SUBMIT_KEY: 'form_last_submit'
  }
}

export function getColorByFormProgress (staff) {
  return formOrderEnum[staff[formOrderEnum.enum.FORM_ORDER_KEY]] || 'red'
}

export function getFormLastSubmitDate (staff) {
  let date

  try {
    if (staff[formOrderEnum.enum.FORM_LAST_SUBMIT_KEY]) {
      date = format(new Date(`${staff[formOrderEnum.enum.FORM_LAST_SUBMIT_KEY]}Z`), 'dd MMM yyyy hh:mm a')
    }
  } catch (e) {
    console.log(e)
  }

  return date || ''
}

export default formOrderEnum

<template>
  <div class="pdf-preview-template">
    <el-main class="pdf-main-wrap">
      <el-container>
        <router-view />
      </el-container>
    </el-main>
  </div>
</template>

<script>
export default {
  name: "PdfPreviewLayout"
}
</script>

<style lang="scss">
  .pdf-preview-template {
    max-width: 1200px;
    margin: 0 auto;
    .el-main {
      padding: 0;
    }
  }
  @page {
    margin: 20px 16px 32px;
  }

  @media print {
    .pdf-preview-template {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact;
    }
  }
</style>
